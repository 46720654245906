import {
  TextField as MUITextField, Select as MUISelect,
  FormControlLabel as MUIFormControlLabel, Checkbox as MUICheckbox,
} from '@mui/material';
import { MuiColorInput } from 'mui-color-input';

export function ControlledTextField({
  formik, name, label, ...rest
}) {
  return (
    <MUITextField
      label={label}
      name={name}
      value={formik.values[name]}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      autoComplete="on"
      onBlur={formik.handleBlur}
      {...rest}
    />
  );
}

export function ControlledSelect({
  formik, name, label, children, ...rest
}) {
  return (
    <MUISelect
      label={label}
      name={name}
      value={formik.values[name]}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      {...rest}
    >
      {children}
    </MUISelect>
  );
}

export function ControlledCheckbox({
  formik, name, label, ...rest
}) {
  return (
    <MUIFormControlLabel
      control={(
        <MUICheckbox
          checked={formik.values[name]}
          onChange={formik.handleChange}
          name={name}
          color="primary"
        />
      )}
      label={label}
      {...rest}
    />
  );
}

export function ControlledTextarea({
  formik, name, label, ...rest
}) {
  return (
    <MUITextField
      multiline
      minRows={2}
      maxRows={4}
      label={label}
      name={name}
      value={formik.values[name]}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      autoComplete="on"
      onBlur={formik.handleBlur}
      {...rest}
    />
  );
}

export function ControlledColorInput({
  formik, name, label, ...rest
}) {
  return (
    <MuiColorInput
      label={label}
      name={name}
      value={formik.values[name]}
      onChange={(value) => formik.setFieldValue(name, value)}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      {...rest}
    />
  );
}
