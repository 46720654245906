import {
  IconButton, ListItemIcon, MenuItem, Tooltip, Badge,
} from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import io from 'socket.io-client';
import { useSelector, useDispatch } from 'react-redux';
import { incrementMentionsCounter, resetMentionsCounter } from 'redux/slicers';
import { MentionsSnackBar } from 'components';

export function MentionsBtn({ isMenuItem = false }) {
  const [open, setOpen] = useState({ status: false, link: '', mention: '' });
  const timeoutIdRef = useRef(null);
  const { user } = useSelector((state) => state.user);
  const { counter } = useSelector((state) => state.mentionsCounter);
  const { id } = useParams();
  const username = user?.username;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleIncrement = () => {
    dispatch(incrementMentionsCounter());
  };

  const handleReset = () => {
    dispatch(resetMentionsCounter());
  };

  const handleOpen = (mention) => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    setOpen({ status: true, link: `/forum/pan/topic/${mention.topicId}/${mention.topicId}/page/${mention.page}`, mention: mention.replyAuthor.username });
    timeoutIdRef.current = setTimeout(() => {
      setOpen({ status: false, link: '', mention: '' });
    }, 4500);
  };

  const handleClose = () => {
    setOpen((prev) => ({ ...prev, status: false }));
  };

  useEffect(() => () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
  }, []);

  useEffect(
    () => {
      if (!username) return undefined;
      const currentId = +id || undefined;

      const socket = io(process.env.REACT_APP_API_URL, { transports: ['websocket'] });
      socket.on('connect', () => {
        socket.emit('join', { room: `mention-${username}` });
      });
      socket.on('newMention', (mention) => {
        if (mention.topicId !== currentId) {
          handleOpen(mention);
          handleIncrement();
        }
      });
      return () => {
        if (socket && socket.connected) {
          socket.emit('leave', { room: `mention-${username}` });
          socket.disconnect();
        }
      };
    },
    [username, id],
  );

  return (
    <>
      { isMenuItem ? (
        <MenuItem
          onClick={() => { navigate('/mentions'); handleReset(); }}
        >
          <ListItemIcon>
            <Badge badgeContent={counter} color="error">
              <AlternateEmailIcon />
            </Badge>
          </ListItemIcon>
          Menções
        </MenuItem>
      ) : (
        <Tooltip arrow title="Menções">
          <IconButton
            color="primary"
            aria-label="mention messages"
            component="label"
            onClick={() => { navigate('/mentions'); handleReset(); }}
          >
            <Badge badgeContent={counter} color="error">
              <AlternateEmailIcon />
            </Badge>
          </IconButton>
        </Tooltip>
      )}
      <MentionsSnackBar handleReset={handleReset} mention={open.mention} open={open.status} close={handleClose} link={open.link} />
    </>
  );
}
