import {
  ListItemText, Divider, Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CategoryCard } from 'components/Cards';
import { useSelector } from 'react-redux';
import * as S from './styles';

export function ForumTopicCard({ topic, groupSlug, isFeatured = false }) {
  const LINK_TO_TOPIC = (details) => `/forum/${groupSlug}/topic/${details.slug}/${details.id}`;
  const LINK_TO_LAST_REPLY = (details) => `/forum/${groupSlug}/topic/${details.slug}/${details.id}${details.currentLastPage === 1 ? '/' : '/page/last/'}`;
  const navigate = useNavigate();
  const isModerator = useSelector((state) => state.forumDetails?.userCredentials?.isModerator);
  const currentUrl = window.location.href;
  const isUserPage = currentUrl.includes('/user/');

  return (
    <>
      <S.ForumTopicCardContainer>
        <S.TopicCardAvatar topic={topic} navigate={navigate} />
        <ListItemText
          sx={{ mt: 0.5 }}
          primary={(
            <Box sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'flex-start', sm: 'center' },
              gap: { xs: 0, sm: 1 },
            }}
            >
              <S.TopicCardHeader
                to={LINK_TO_TOPIC(topic)}
                topic={topic}
                isFeatured={isFeatured}
              />
              {isModerator && !isUserPage && (
                <CategoryCard
                  category={topic.category}
                  groupSlug={groupSlug}
                />
              )}
            </Box>
          )}
          secondary={(
            <S.TopicInfoContainer>
              <S.TopicPostQty topic={topic} />
              <S.TopicCardLastReplyLink
                to={LINK_TO_LAST_REPLY(topic)}
                navigate={navigate}
                topic={topic}
              />
            </S.TopicInfoContainer>
          )}
        />
      </S.ForumTopicCardContainer>
      <Divider component="li" variant="middle" />
    </>
  );
}
