import { Divider } from '@mui/material';
import { GifOptions } from 'components/Menus';
import { CardContainer } from 'common/CardContainer';
import { calculateTimeDifference } from 'utils/timeDifference';
import { useHtmlParse } from 'hooks';
import * as S from '../Reply/styles';

export function ReplyLogCard({ reply }) {
  const AVATAR_URL = `${process.env.REACT_APP_IMAGE_URL}${reply.replyAuthor?.avatar}`;
  const TIME_SINCE = reply?.updatedAt ? calculateTimeDifference(reply?.updatedAt) : '';

  const { parsedContent, showGifOptions, setShowGifOptions } = useHtmlParse(reply.content);

  return (
    <>
      <GifOptions
        showGifOptions={showGifOptions}
        setShowGifOptions={setShowGifOptions}
      />
      <CardContainer
        sx={{
          minWidth: { xs: '300px', sm: '500px' },
          overflow: 'auto',
          maxHeight: { xs: '60vh', sm: '80vh' },
        }}
      >
        <S.CardHeaderContainer
          avatar={
            <S.CardHeaderAvatar src={AVATAR_URL} user={reply.replyAuthor} />
          }
          title={(
            <S.CardHeaderTitle>
              {reply.replyAuthor?.name}
            </S.CardHeaderTitle>
          )}
          subheader={
            <S.CardHeaderSubheader>{TIME_SINCE}</S.CardHeaderSubheader>
          }
        />
        <S.CardContentContainer>
          <S.CardPostContent id={`post-${reply.id}`}>
            {parsedContent}
          </S.CardPostContent>
        </S.CardContentContainer>
        <Divider variant="middle" />
      </CardContainer>
    </>
  );
}
