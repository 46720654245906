import { Header, ForumBansList } from 'components';
import * as S from './styles';

export function ForumBans() {
  return (
    <>
      <Header />
      <S.ForumBansContainer>
        <ForumBansList />
      </S.ForumBansContainer>
    </>
  );
}
