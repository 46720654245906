import { Google, Twitter } from '@mui/icons-material';
import {
  Box, IconButton, Tooltip, Typography,
} from '@mui/material';
import { Container } from 'common/Container';

export function AccountBindingContainer({ children }) {
  return (
    <Box
      width="100%"
      margin="0 auto"
      mt={{ xs: 2, sm: 6 }}
      p={{ xs: 2, sm: 4 }}
      bgcolor="background.level2"
      borderRadius="5px"
      boxShadow={(theme) => theme.shadows[1]}
    >
      {children}
    </Box>
  );
}

export function AccountProvidersContainer({ children }) {
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Container
        maxWidth={600}
        gap={2}
        display="flex"
        flexDirection="column"
      >
        {children}
      </Container>
    </Box>
  );
}

export function AccountProviders({ children }) {
  return (
    <Box
      display="flex"
      gap={1}
    >
      {children}
    </Box>
  );
}

export function Title({ children }) {
  return (
    <Box>
      <Typography variant="h6" component="h2">
        {children}
      </Typography>
    </Box>
  );
}

export function GoogleButton({ onClick }) {
  return (
    <Tooltip arrow title="Vincular conta com Google">
      <IconButton
        onClick={onClick}
        color="primary"
        aria-label="google"
      >
        <Google
          fontSize="large"
        />
      </IconButton>
    </Tooltip>
  );
}

export function TwitterButton({ onClick }) {
  return (
    <Tooltip arrow title="Vincular conta com Twitter">
      <IconButton
        onClick={onClick}
        color="primary"
        aria-label="twitter"
      >
        <Twitter
          fontSize="large"
        />
      </IconButton>
    </Tooltip>
  );
}
