import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import * as S from './styles';

export function ConfirmationModal({
  ModalTrigger,
  onConfirm,
  onCancel,
  message,
  title = 'Confirmar ação',
  confirmText = 'Confirmar',
  cancelText = 'Cancelar',
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <S.Modal
        open={open}
        handleClose={() => setOpen(false)}
        title={title}
      >
        <Box>
          <Typography variant="h6">
            {message}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
          >
            {confirmText}
          </Button>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => {
              setOpen(false);
              onCancel?.();
            }}
          >
            {cancelText}
          </Button>
        </Box>
      </S.Modal>
      {ModalTrigger && (
      <ModalTrigger
        onClick={() => setOpen(true)}
      />
      )}
    </>
  );
}
