import { linkWithPopup } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/slicers';
import { auth, providers } from 'services/firebase';
import * as S from './styles';

export function AccountBinding() {
  const dispatch = useDispatch();

  const bindAccount = async (provider) => {
    try {
      await linkWithPopup(
        auth.currentUser,
        provider,
      );

      dispatch(setSnackbar({
        message: 'Conta vinculada com sucesso!',
        status: 'success',
      }));
    } catch (error) {
      dispatch(setSnackbar({
        message: 'No momento, esta conta não pode ser vinculada.',
        status: 'error',
      }));
    }
  };

  return (
    <S.AccountBindingContainer>
      <S.AccountProvidersContainer>
        <S.Title>
          Vincular contas
        </S.Title>
        <S.AccountProviders>
          <S.GoogleButton onClick={() => bindAccount(providers.google)} />
          <S.TwitterButton onClick={() => bindAccount(providers.twitter)} />
        </S.AccountProviders>
      </S.AccountProvidersContainer>
    </S.AccountBindingContainer>
  );
}
