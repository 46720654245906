/* eslint-disable prefer-destructuring */
import {
  FacebookEmbed,
  InstagramEmbed as InstagramEmbedComponent,
  PinterestEmbed,
  TikTokEmbed,
  TwitterEmbed,
} from 'react-social-media-embed';
import SpotifyEmbed from 'react-spotify-embed';
import { useMediaQuery } from '@mui/material';

function YouTubeEmbed({ url }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const video = {
    code: '',
  };

  const getVideoId = () => {
    const patterns = {
      'youtu.be/': () => url.split('youtu.be/')[1],
      'watch?v=': () => url.split('watch?v=')[1],
      '/embed/': () => url.split('embed/')[1],
      '/v/': () => url.split('v/')[1],
      '/live/': () => url.split('live/')[1],
      '/shorts/': () => url.split('shorts/')[1],
    };

    const matchingPattern = Object.keys(patterns).find((pattern) => url.includes(pattern));

    if (matchingPattern) {
      return patterns[matchingPattern]();
    }

    return null;
  };

  video.code = getVideoId();

  return (
    <iframe
      width="100%"
      height={isMobile ? '200px' : '500px'}
      src={`https://www.youtube.com/embed/${video.code}`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
}

function XVideosEmbed({ url }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const videoId = url.split('/video')[1].split('/')[0];

  return (
    <iframe
      title="XVideos video player"
      src={`https://www.xvideos.com/embedframe/${videoId}`}
      height={isMobile ? '200px' : '500px'}
      width="100%"
      allowFullScreen
    />
  );
}

function PornHubEmbed({ url }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const videoId = url.split('viewkey=')[1];

  return (
    <iframe
      title="PornHub video player"
      src={`https://www.pornhub.com/embed/${videoId}`}
      height={isMobile ? '200px' : '500px'}
      width="100%"
      allowFullScreen
    />
  );
}

function XHamsterEmbed({ url }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const removeString = url.split('?')[0];
  const videoId = removeString.substring(removeString.length - 7);

  return (
    <iframe
      title="XHamster video player"
      src={`https://xhamster.com/embed/${videoId}`}
      height={isMobile ? '200px' : '500px'}
      width="100%"
      allowFullScreen
    />
  );
}

function VimeoEmbed({ url }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const videoId = url.split('vimeo.com/')[1];

  return (
    <iframe
      title="Vimeo video player"
      src={`https://player.vimeo.com/video/${videoId}`}
      height={isMobile ? '200px' : '500px'}
      width="100%"
      allowFullScreen
    />
  );
}

function SpotifyEmbedComponent({ url }) {
  const modifiedUrl = url.replace('/intl-pt/', '/');
  return (
    <SpotifyEmbed
      link={modifiedUrl}
      wide
    />
  );
}

function SoundCloudEmbed({ url }) {
  return (
    <iframe
      title="SoundCloud music Player"
      width="100%"
      src={`https://w.soundcloud.com/player/?url=${url}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`}
    />
  );
}

function VocarooEmbed({ url }) {
  const audioId = { code: '' };

  const getAudioId = () => {
    const patterns = {
      'vocaroo.com/': () => url.split('vocaroo.com/')[1],
      'voca.ro/': () => url.split('voca.ro/')[1],
    };
    const matchingPattern = Object.keys(patterns).find((pattern) => url.includes(pattern));
    if (matchingPattern) {
      return patterns[matchingPattern]();
    }
    return null;
  };

  audioId.code = getAudioId();

  if (!audioId.code) {
    return (
      <a href="https://vocaroo.com/" target="_blank" rel="noreferrer">
        https://vocaroo.com/
      </a>
    );
  }

  return (
    <iframe
      title="Vocaroo audio player"
      src={`https://vocaroo.com/embed/${audioId.code}`}
      height="60px"
      width="100%"
      allowFullScreen
    />
  );
}

function AppleMusicEmbed({ url }) {
  const removeHttps = url.replace('https://', '');
  const isSong = removeHttps.includes('?');
  return (
    <iframe
      title="Apple Music player"
      allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
      frameBorder="0"
      height={isSong ? '175' : '450'}
      style={{
        width: '100%', maxWidth: '900px', overflow: 'hidden', background: 'transparent',
      }}
      sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
      src={`https://embed.${removeHttps}`}
    />
  );
}

function InstagramEmbed({ url }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const videoId = { code: '' };

  const getVideoId = () => {
    const patterns = {
      'instagram.com/p/': () => url.split('instagram.com/p/')[1].split('/')[0],
      'instagram.com/reel/': () => url.split('instagram.com/reel/')[1].split('/')[0],
      'instagram.com/tv/': () => url.split('instagram.com/tv/')[1].split('/')[0],
    };
    const matchingPattern = Object.keys(patterns).find((pattern) => url.includes(pattern));
    if (matchingPattern) {
      return patterns[matchingPattern]();
    }
    return null;
  };

  videoId.code = getVideoId();

  return (
    <InstagramEmbedComponent
      url={`https://www.instagram.com/p/${videoId.code}/`}
      width={isMobile ? 320 : 500}
    />
  );
}

const embedComponents = {
  facebook: FacebookEmbed,
  youtu: YouTubeEmbed,
  instagram: InstagramEmbed,
  twitter: TwitterEmbed,
  x: TwitterEmbed,
  tiktok: TikTokEmbed,
  pinterest: PinterestEmbed,
  spotify: SpotifyEmbedComponent,
  soundcloud: SoundCloudEmbed,
  xvideos: XVideosEmbed,
  pornhub: PornHubEmbed,
  xhamster: XHamsterEmbed,
  vimeo: VimeoEmbed,
  voca: VocarooEmbed,
  apple: AppleMusicEmbed,
};

const prefixes = {
  youtu: ['youtu.be', 'youtube.com/watch', 'youtube.com/embed', 'youtube.com/v', 'youtube.com/live', 'youtube.com/shorts'],
  twitter: ['status'],
  x: ['status'],
  instagram: ['instagram.com/p', 'instagram.com/reel'],
  facebook: ['fb.watch', 'facebook.com/photo', 'posts'],
  tiktok: ['tiktok', 'video/'],
  pinterest: ['pin'],
  spotify: ['open.spotify.com'],
  soundcloud: ['soundcloud.com'],
  xvideos: ['xvideos.com/video'],
  pornhub: ['pornhub.com/view_video'],
  xhamster: ['xhamster.com/videos'],
  voca: ['vocaroo.com', 'voca.ro'],
  apple: ['music.apple.com'],
};

const excludePrefixes = {
  youtu: ['youtube.com/playlist'],
  twitter: ['twitter.com/i/lists'],
  x: ['twitter.com/i/lists'],
  instagram: ['instagram.com/stories'],
  facebook: ['groups'],
  tiktok: ['vm.tiktok'],
  pinterest: [],
  spotify: [],
  soundcloud: [],
  xvideos: [],
  pornhub: [],
  xhamster: [],
  vocaroo: [],
  apple: [],
};

function isEmbeddable(url) {
  if (!url) return false;
  return Object.keys(prefixes).find((platform) => (
    url.includes(platform) && prefixes[platform]?.some((p) => url.includes(p))
    && !excludePrefixes[platform]?.some((p) => url.includes(p))
  )) || false;
}

function checkTextUrl(textWithUrl) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const splitText = textWithUrl.split(' ');
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  if (splitText.length > 1) {
    const textsWithUrls = splitText.filter((text) => urlRegex.test(text));
    const textsWithoutUrls = splitText.filter((text) => !urlRegex.test(text));

    const embeds = textsWithUrls.map((text) => {
      const url = text.match(urlRegex)[0];
      const platform = isEmbeddable(url);
      const EmbedComponent = embedComponents[platform];
      const isTwitter = platform === 'twitter' || platform === 'x';

      return platform ? (
        <>
          <EmbedComponent
            width={isMobile ? '100%' : '500px'}
            url={url}
          />
          {isTwitter && (
            <p key={Math.random()}>
              <a target="_blank" rel="nofollow noreferrer" href={url}>{url}</a>
            </p>
          )}
        </>
      ) : <a target="_blank" rel="nofollow noreferrer" href={url}>{url}</a>;
    });

    const texts = textsWithoutUrls.join(' ');

    return [...embeds.slice(1), <p key={Math.random()}>{texts}</p>];
  }

  const platform = isEmbeddable(textWithUrl);

  const EmbedComponent = embedComponents[platform];

  const isTwitter = platform === 'twitter' || platform === 'x';

  return platform
    ? (
      <>
        <EmbedComponent
          width={isMobile ? '100%' : '500px'}
          url={textWithUrl}
        />
        {isTwitter && (
          <p key={Math.random()}>
            <a target="_blank" rel="nofollow noreferrer" href={textWithUrl}>{textWithUrl}</a>
          </p>
        )}
      </>
    )
    : <a target="_blank" rel="nofollow noreferrer" href={textWithUrl}>{textWithUrl}</a>;
}

function Embed({ url }) {
  return checkTextUrl(url);
}

export default Embed;
