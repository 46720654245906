import { setIsEditorOpen, setQuoteReply } from 'redux/slicers';
import { store } from 'redux/store';

export const handleQuote = (username, content) => {
  const isMobile = window.innerWidth < 600;
  const { editorId } = store.getState().textEditor;
  const blockquote = content.indexOf('<blockquote>');
  if (blockquote !== -1) {
    const endBlockquote = content.indexOf('</blockquote>');
    content = content.slice(0, blockquote) + content.slice(endBlockquote + 13);
  }
  store.dispatch(setQuoteReply({ username, content }));
  store.dispatch(setIsEditorOpen(true));

  setTimeout(() => {
    const tinyMCE = window.tinymce.get(editorId);
    if (tinyMCE) {
      tinyMCE.focus();
    }
  }, isMobile ? 1000 : 100);
};
