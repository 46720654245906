import { Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

export function BackToForumBtn() {
  const navigate = useNavigate();
  const { forum } = useParams();

  return (
    <Button
      sx={{
        position: 'absolute',
        right: 15,
        bottom: 10,
        backgroundColor: 'primary.main',
        '&:hover': {
          backgroundColor: 'primary.dark',
        },
        color: 'primary.contrastText',
        fontWeight: 'bold',
        zIndex: 1,
      }}
      size="small"
      onClick={() => { navigate(`/forum/${forum}`); }}
    >
      Voltar para o fórum
    </Button>
  );
}
