import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, IconButton } from '@mui/material';
import { setSelectedImage, setSnackbar, setGifPocket } from 'redux/slicers';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { deleteGifFromGifPocket } from 'services/providers/pandlr.provider';
import UpdateGifShortcutModal from '../Modals/UpdateGifShortcutModal';

export function GifShortcutCard({
  id, thumbnailUrl, shortcut, imageUrl, handleClose, isDeleting, isEditing,
}) {
  const dispatch = useDispatch();
  const gifPocket = useSelector((state) => state.gifPocket);

  const handleDelete = async () => {
    const result = await deleteGifFromGifPocket(id);
    if (result.success) {
      dispatch(setSnackbar({
        message: 'Gif deletado com sucesso!',
        status: 'success',
      }));
      return dispatch(setGifPocket(gifPocket.filter((gif) => gif.id !== id)));
    }
    return dispatch(setSnackbar({
      message: 'Erro ao deletar gif!',
      status: 'error',
    }));
  };

  return (
    <Card sx={{ maxWidth: 120, mb: 2 }}>
      <CardActionArea onClick={() => {
        if (!isDeleting && !isEditing) {
          dispatch(setSelectedImage(imageUrl));
          handleClose();
        }
      }}
      >
        <CardMedia
          component="img"
          height="110"
          width="110"
          image={process.env.REACT_APP_IMAGE_URL + thumbnailUrl}
          alt="shortcut"
          sx={{ padding: 0.5 }}
        />
        {isDeleting && (
          <IconButton
            onClick={handleDelete}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            <CloseIcon sx={{ backgroundColor: 'red' }} />
          </IconButton>
        )}
        {isEditing && (<UpdateGifShortcutModal gif={{ id, shortcut }} />)}
        <CardContent>
          <Typography sx={{ textAlign: 'center', fontSize: 12, mt: -2 }}>
            {shortcut}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
