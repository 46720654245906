import {
  ListItemText, Divider, Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CategoryCard } from 'components/Cards';
import { DeletedTopicMenu } from 'components/Menus';
import * as S from './styles';

export function DeletedForumTopicCard({ topic, groupSlug, isFeatured = false }) {
  const navigate = useNavigate();

  return (
    <>
      <S.ForumTopicCardContainer>
        <S.TopicCardAvatar topic={topic} navigate={navigate} />
        <ListItemText
          sx={{ mt: 0.5 }}
          primary={(
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            >
              {' '}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: { xs: 'flex-start', sm: 'center' },
                  gap: { xs: 0, sm: 1 },
                }}
              >
                <S.TopicCardHeader
                  to="#"
                  topic={topic}
                  isFeatured={isFeatured}
                />

                <CategoryCard
                  category={topic.category}
                  groupSlug={groupSlug}
                />

              </Box>
              <DeletedTopicMenu topic={topic} />
            </Box>
          )}
          secondary={(
            <S.TopicInfoContainer>
              <S.TopicPostQty topic={topic} />
              <S.TopicCardLastReplyLink
                to="#"
                navigate={navigate}
                topic={topic}
              />
            </S.TopicInfoContainer>
          )}
        />
      </S.ForumTopicCardContainer>
      <Divider component="li" variant="middle" />
    </>
  );
}
