import { Box } from '@mui/material';

export function UserRepliesContainer({ children }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
    >
      {children}
    </Box>
  );
}
