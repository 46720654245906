import { Navigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { selectLogged } from 'redux/slicers';

// export function PublicRouter({ children }) {
export function PublicRouter() {
  // const isLogged = useSelector(selectLogged);

  // return !isLogged ? children : <Navigate to="/forum/pan" />;
  return <Navigate to="/forum/pan" />;
}
