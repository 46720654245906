import React from 'react';
import { GifOptions } from 'components/Menus';
import { CardContainer } from 'common/CardContainer';
import { Divider } from '@mui/material';
import { useHtmlParse } from 'hooks';
import * as S from './styles';

export function UserReplyCard({ reply }) {
  const replyContent = reply.content || reply.reply.content;
  const { parsedContent, showGifOptions, setShowGifOptions } = useHtmlParse(replyContent);

  return (
    <>
      <GifOptions
        showGifOptions={showGifOptions}
        setShowGifOptions={setShowGifOptions}
      />
      <CardContainer>
        <S.CardHeader reply={reply} />
        <S.CardContent reply={reply}>
          {parsedContent}
        </S.CardContent>
        <S.CardActions>
          <S.ViewOnTopic reply={reply} />
        </S.CardActions>
        <Divider variant="middle" />
      </CardContainer>
    </>
  );
}
