import React, {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { getAdminLogs, getAdminLogsActions } from 'services/providers/pandlr.provider';
import {
  Box, MenuItem, Select, TextField, Typography,
} from '@mui/material';
import { AdminLogCard } from 'components/Cards';
import { ListContainer, ListContent } from 'components/Topic/styles';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import { AdminLogsOrderMenu } from 'components/Menus/AdminLogsOrderMenu';
import * as S from './styles';

export function AdminLogsList() {
  const forumDetails = useSelector((state) => state.forumDetails);
  const [logs, setLogs] = useState([]);
  const [actions, setActions] = useState([]);
  const [selectedAction, setSelectedAction] = useState('placeholder');
  const [order, setOrder] = useState('DESC');
  const [search, setSearch] = useState('');
  const { id } = forumDetails;

  const paginationRef = useRef({
    currentPage: 0,
    hasMore: true,
  });

  const isLoading = useRef(false);
  const listRef = useRef(null);
  const parentOffsetRef = useRef(0);

  const virtualizer = useWindowVirtualizer({
    count: logs.length,
    estimateSize: () => 100,
    scrollMargin: parentOffsetRef.current,
    overscan: 5,
  });

  const virtualItems = virtualizer.getVirtualItems();

  useLayoutEffect(() => {
    parentOffsetRef.current = listRef.current?.offsetTop ?? 0;
  }, [virtualItems]);

  const fetchAdminLogs = async (page, action, orderBy) => {
    if (!id) return;
    const res = await getAdminLogs(id, page, action, orderBy, search);
    if (res.success) {
      const { items, ...paginationOptions } = res.data;
      paginationRef.current = paginationOptions;
      setLogs((prevLogs) => [...prevLogs, ...items]);
    }
  };

  const fetchNextPage = async () => {
    const [lastItem] = [...virtualizer.getVirtualItems()].reverse();
    if (!lastItem || isLoading.current) return;
    if (lastItem.index >= logs.length - 1
        && paginationRef.current.hasMore) {
      isLoading.current = true;
      await fetchAdminLogs(paginationRef.current.currentPage + 1, selectedAction, order);
      isLoading.current = false;
    }
  };

  useEffect(() => {
    fetchNextPage();
  }, [
    isLoading.current,
    paginationRef,
    virtualItems,
    logs,
  ]);

  const fetchAdminLogsActions = async () => {
    const res = await getAdminLogsActions(id);
    if (res.success) {
      const items = res.data;
      const actionOptions = Object.entries(items).map(([key, value]) => ({
        value: key,
        label: value,
      }));
      setActions(actionOptions);
    }
  };

  useEffect(() => {
    setLogs([]);
    fetchAdminLogs(paginationRef.current.currentPage || 1);
    fetchAdminLogsActions();
    return () => {
      setLogs([]);
      paginationRef.current = {
        currentPage: 1,
        hasMore: true,
      };
    };
  }, []);

  const handleChangeOrder = (value) => {
    setOrder(value);
    setLogs([]);
    fetchAdminLogs(1, selectedAction, value);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    setLogs([]);
    fetchAdminLogs(1, selectedAction, order);
  };

  return (
    <>
      <Typography variant="h5" sx={{ m: 2 }}>
        Admin Logs
      </Typography>
      <S.AdminLogsListFilterContainer>
        <S.AdminLogsListFilterForm onSubmit={handleSubmit}>
          <TextField
            label="Filtra por usuário"
            variant="outlined"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <S.AdminLogsListSearchButton />
        </S.AdminLogsListFilterForm>
        <Select
          value={selectedAction}
          onChange={(e) => {
            setSelectedAction(e.target.value);
            setLogs([]);
            fetchAdminLogs(1, e.target.value, order);
          }}
        >
          <MenuItem value="placeholder" disabled>Filtrar por ação</MenuItem>
          <MenuItem value="all">Todos</MenuItem>
          {actions.map((action) => (
            <MenuItem key={action.value} value={action.value}>
              {action.label}
            </MenuItem>
          ))}
        </Select>
        <AdminLogsOrderMenu handleChangeOrder={handleChangeOrder} />
      </S.AdminLogsListFilterContainer>
      <S.AdminLogsListContainer>
        <ListContainer
          ref={listRef}
          virtualizer={virtualizer}
        >
          <ListContent items={virtualItems} virtualizer={virtualizer}>
            {virtualItems?.map((item) => (
              <Box
                key={item.key}
                data-index={item.index}
                ref={virtualizer.measureElement}
                sx={{ '*': { listStyle: 'none' } }}
              >
                <AdminLogCard log={logs[item.index]} />
              </Box>
            ))}
            {isLoading.current && Array.from({ length: 15 }).map(() => (
              <S.AdminLogsListSkeleton key={Math.random()} />
            ))}
            {logs.length === 0 && !isLoading.current && (
              <S.AdminLogsListHelperText
                helperText="Nenhum resultado encontrado"
              />
            )}
          </ListContent>
        </ListContainer>
      </S.AdminLogsListContainer>
    </>
  );
}
