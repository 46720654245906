import { Search } from '@mui/icons-material';
import {
  Box, Divider, IconButton, Skeleton, Typography,
} from '@mui/material';

export function AdminLogsListFilterContainer({ children }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        px: { xs: 1, sm: 2 },
        py: 1,
        mt: '1rem',
        bgcolor: 'background.level2',
        borderRadius: 1,
        overflow: 'hidden',
        boxShadow: (theme) => theme.shadows[1],
      }}
    >
      {children}
    </Box>
  );
}

export function AdminLogsListFilterForm({ children, ...props }) {
  return (
    <form
      style={{
        flexGrow: 1,
        position: 'relative',
        display: 'flex',
      }}
      {...props}
    >
      {children}
    </form>
  );
}

export function AdminLogsListSearchButton() {
  return (
    <IconButton
      sx={{
        position: 'absolute',
        right: '0',
        top: 'calc(50% - 20px)',
        width: '40px',
        height: '40px',
      }}
      type="submit"
    >
      <Search />
    </IconButton>
  );
}

export function AdminLogsListContainer({ children }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      mt="1rem"
      bgcolor="background.level2"
      borderRadius={1}
      boxShadow={(theme) => theme.shadows[1]}
      py={2}
    >
      {children}
    </Box>
  );
}

export function AdminLogsListHelperText({ helperText }) {
  return (
    <Typography
      variant="body1"
      color="text.primary"
      component="span"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {helperText}
    </Typography>
  );
}

export function AdminLogsListSkeleton() {
  return (
    <Box>
      <Box
        key={Math.random()}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: 'background.level2',
          p: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Skeleton variant="rectangular" height={20} width="50%" />
          <Skeleton variant="rectangular" height={20} width="22%" />
        </Box>
      </Box>
      <Divider variant="middle" />
    </Box>
  );
}
