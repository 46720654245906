import Embed from 'components/Embed/Embed';
import DOMPurify from 'dompurify';
import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import parse from 'html-react-parser';

export function useHtmlParse(content) {
  const filterContent = () => {
    const removeAutoPlay = content?.replace(
      /autoplay\s*=\s*(?:"autoplay"|true|1)/gi,
      '',
    );
    return removeAutoPlay;
  };

  const filteredContent = filterContent();

  const cleanHtmlContent = DOMPurify.sanitize(filteredContent);

  const [showGifOptions, setShowGifOptions] = useState({
    show: false,
    image: '',
    UUID: '',
    positions: { top: '', left: '' },
  });

  const handleClick = (UUID) => {
    const image = document.getElementById(UUID);

    function findAbsoluteParent(elemento) {
      if (!elemento) return null;
      let parent = elemento.parentNode;

      while (parent !== document.body) {
        const style = getComputedStyle(parent);
        if (style.position === 'absolute') {
          return parent;
        }
        parent = parent.parentNode;
      }

      return null;
    }

    const parenteAbsoluto = findAbsoluteParent(image);

    const parentRect = parenteAbsoluto?.getBoundingClientRect();
    const rect = image?.getBoundingClientRect();

    const positions = {
      top: rect.top - (parentRect?.top || 60 - window.scrollY) + 60,
      left: rect.left - (parentRect?.left || 0 - window.scrollX) + 10,
    };

    setShowGifOptions({
      show: true,
      image: image.src,
      UUID,
      positions,
      element: image,
    });
  };

  const renderEmbed = useCallback((url) => <Embed url={url} />, []);
  const renderLink = useCallback((text) => <a href={text}>{text}</a>, []);

  let linksCounter = 0;

  const parsedContent = parse(cleanHtmlContent, {
    replace: (domNode) => {
      if (domNode.type === 'text' && /https?:\/\//.test(domNode.data)) {
        // limit the embeds to 5
        if (linksCounter >= 5) {
          return renderLink(domNode.data);
        }
        linksCounter += 1;
        return renderEmbed(domNode.data);
      }
      if (domNode.name === 'img' && domNode.attribs.src) {
        const UUID = uuidv4();
        domNode.attribs.id = `${UUID}`;
        domNode.attribs.style = 'cursor: pointer;';
        domNode.attribs.onClick = () => handleClick(UUID);
      }
      return domNode;
    },
  });

  return {
    parsedContent,
    showGifOptions,
    setShowGifOptions,
  };
}
