import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export function NotFoundMsg() {
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: '100px', height: '75vh', textAlign: 'center' }}>
      Página não encontrada
      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/forum/pan')}
        sx={{ mt: 2 }}
      >
        Ir para o fórum PAN
      </Button>
    </div>
  );
}
