import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState({})('forumDetails');

export const forumDetailsSlice = createSlice({
  name: 'forumDetails',
  initialState,
  reducers: {
    setForumDetails: (state, { payload }) => {
      state = payload;
      return state;
    },
  },
});

export const { setForumDetails } = forumDetailsSlice.actions;
