import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState(false)('fastReply');

export const fastReplySlice = createSlice({
  name: 'fastReply',
  initialState,
  reducers: {
    setFastReply: (state, { payload }) => {
      state = payload;
      return state;
    },
  },
});

export const { setFastReply } = fastReplySlice.actions;
