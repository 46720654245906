import React from 'react';
import { List } from '@mui/material';
import { ForumTopicCard } from './ForumTopicCard';
import { ForumTopicsSkeleton } from './ForumTopicsSkeleton';

export default function ForumTopicsList({
  topics, groupSlug, isFeatured = false, showSkeleton = false,
}) {
  return (
    <List sx={{ width: '100%', bgcolor: 'background.level2', mb: isFeatured ? -3 : 0 }}>
      {showSkeleton && !topics?.length && <ForumTopicsSkeleton />}
      {topics?.map((topic) => (
        <ForumTopicCard
          key={`topic-${topic.id}`}
          topic={topic}
          groupSlug={groupSlug}
          isFeatured={isFeatured}
        />
      ))}
    </List>
  );
}
