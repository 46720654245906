import React, { useEffect, useState } from 'react';
import { SaveAs } from '@mui/icons-material';
import {
  getGroupDetails, updateForumDetails, uploadForumBanner, uploadForumImage,
} from 'services/providers/pandlr.provider';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/slicers';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  AvatarContainer,
  BannerImage,
  Description,
  ForumImage,
  MainHeaderContainer,
  MainHeaderDetailsContainer,
  MainHeaderDetailsContent,
  MainHeaderImagesContainer,
  MainHeaderName,
  MainHeaderNameMobile,
} from 'styles/MainHeader.styles';

import { useUploadImages } from 'hooks/useUploadImages';
import { validateUpdateForumForm } from 'utils/yupValidations';
import { useFormik } from 'formik';
import * as S from './styles';
import { ControlledTextField, ControlledTextarea } from './ControlledFormComponents';

export function UpdateForumForm() {
  const { uploadImages } = useUploadImages();

  const { slug } = useParams();
  const [forumDetails, setForumDetails] = useState({});
  const { user } = useSelector((state) => state.user);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const fetchForumDetails = async () => {
    const res = await getGroupDetails(slug, user?.id);

    if (!res.success) {
      dispatch(setSnackbar({ message: res.message, status: 'error' }));
      return;
    }

    setForumDetails(res.data);
  };

  useEffect(() => {
    fetchForumDetails();
  }, []);

  const INITIAL_STATE = {
    description: forumDetails.description || '',
    avatar: '',
    background: '',
    avatarLink: '',
  };

  const onSubmit = async (data, formik) => {
    const images = [
      {
        name: 'avatar',
        file: data.avatar,
        cb: uploadForumImage,
        id: forumDetails.id,
        slug: forumDetails.slug,
      },
      {
        name: 'background',
        file: data.background,
        cb: uploadForumBanner,
        id: forumDetails.id,
        slug: forumDetails.slug,
      },
    ];

    const { uploadedImages, uploadErrors } = await uploadImages(images);

    if (uploadErrors.avatar || uploadErrors.background) {
      formik.setErrors({
        avatar: uploadErrors.avatar,
        background: uploadErrors.background,
      });

      return;
    }

    const result = await updateForumDetails({
      description: data.description,
      groupImage: uploadedImages.avatar?.path300,
      groupImageThumb: uploadedImages.avatar?.path,
      groupBackgroundImage: uploadedImages?.background,
      avatarLink: data.avatarLink,
    }, forumDetails.id, forumDetails.slug);

    if (!result.success) {
      dispatch(setSnackbar({ message: result.data.message, status: 'error' }));
      return;
    }

    dispatch(setSnackbar({ message: 'Informações do grupo atualizadas com sucesso!', status: 'success' }));
    navigate(`/forum/${slug}`);
  };

  const formik = useFormik({
    initialValues: INITIAL_STATE,
    validationSchema: validateUpdateForumForm,
    onSubmit: async (data) => onSubmit(data, formik),
    enableReinitialize: true,
  });

  const AVATAR_URL = formik.values.avatar ? URL.createObjectURL(formik.values.avatar) : `${process.env.REACT_APP_IMAGE_URL}/${forumDetails.groupImage}`;
  const BANNER_URL = formik.values.background ? URL.createObjectURL(formik.values.background) : `${process.env.REACT_APP_IMAGE_URL}/${forumDetails.groupBackgroundImage}`;

  return (
    <>
      <MainHeaderContainer>
        <MainHeaderImagesContainer>
          <BannerImage src={BANNER_URL} />
          <AvatarContainer>
            <Link to={AVATAR_URL}>
              <ForumImage src={AVATAR_URL} />
            </Link>
          </AvatarContainer>
          <MainHeaderName>{forumDetails.name}</MainHeaderName>
        </MainHeaderImagesContainer>
        <MainHeaderDetailsContainer>
          <MainHeaderDetailsContent>
            <MainHeaderNameMobile>
              {forumDetails.name}
            </MainHeaderNameMobile>
          </MainHeaderDetailsContent>
          <Description description={formik.values.description} />
        </MainHeaderDetailsContainer>
      </MainHeaderContainer>

      <S.UpdateUserFormContainer>
        <S.Form onSubmit={formik.handleSubmit}>
          <S.FormSubtitle textAlign="start" mb={3}>Editar Fórum</S.FormSubtitle>
          <S.FormContainer>

            <S.Input>
              <ControlledTextarea
                fullWidth
                label="Descrição"
                name="description"
                formik={formik}
              />
            </S.Input>

            <S.Input>
              <S.UploadImageButton
                label="Carregue uma imagem para o avatar do Fórum"
                name="avatar"
                formik={formik}
              />
            </S.Input>

            <S.Input>
              <ControlledTextField
                label="Link para um tópico relevante do Fórum"
                fullWidth
                name="avatarLink"
                formik={formik}
                required
              />
            </S.Input>

            <S.Input>
              <S.UploadImageButton
                label="Carregue uma imagem para o banner do Fórum"
                name="background"
                formik={formik}
              />
            </S.Input>

            <S.FormActions>
              <S.LoadingButton
                disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                type="submit"
                loading={formik.isSubmitting}
                endIcon={<SaveAs />}
              >
                Salvar
              </S.LoadingButton>
            </S.FormActions>

          </S.FormContainer>
        </S.Form>
      </S.UpdateUserFormContainer>
    </>

  );
}
