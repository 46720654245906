import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar, setTopicDetails } from 'redux/slicers';
import { useNavigate } from 'react-router-dom';
import { editTopic, getCategories } from 'services/providers/pandlr.provider';
import { TextEditorFixed } from 'components';
import { TextField } from '@mui/material';
import { SaveAs } from '@mui/icons-material';
import * as S from './styles';
import { SelectCategory } from './SelectCategory';

export function EditTopicForm({ open, handleClose }) {
  const { topicDetails, forumDetails } = useSelector((state) => state);

  const [value, setValue] = useState(topicDetails.content);
  const [title, setTitle] = useState(topicDetails.title);
  const [category, setCategory] = useState(topicDetails.category);
  const [categories, setCategories] = useState([]);
  const [categoryError, setCategoryError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchCategories = async () => {
    const res = await getCategories(forumDetails.id);
    if (res.success) {
      setCategories(res.data);
    }
  };

  useEffect(() => {
    fetchCategories();

    return () => {
      setCategories([]);
      setCategory(null);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!category) {
      setCategoryError(true);
      return setLoading(false);
    }
    const updatedTopic = {
      title: e.target.title.value,
      content: value,
      topicId: topicDetails.id,
      forumId: topicDetails.groupId,
      categoryId: category.id,
    };
    const response = await editTopic(updatedTopic);
    if (response.success) {
      dispatch(setTopicDetails(response.data));
      dispatch(setSnackbar({ status: 'success', message: 'Tópico editado com sucesso!' }));
      handleClose?.();
      return navigate(`/forum/${forumDetails?.slug}/topic/${response.data.slug}/${response.data.id}`);
    }
    dispatch(setSnackbar({ status: 'error', message: response.data.message }));
    return setLoading(false);
  };

  useEffect(() => {
    setValue(topicDetails.content);
    setTitle(topicDetails.title);
  }, [topicDetails, open]);

  return (
    <S.Form onSubmit={handleSubmit} sx={{ maxWidth: 800 }}>
      <S.FormContainer spacing={{ xs: 1 }}>
        <S.InputGrid md={8}>
          <TextField
            id="title"
            label="Título do tópico"
            variant="outlined"
            sx={{ mb: 2 }}
            autoFocus
            fullWidth
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </S.InputGrid>
        <S.InputGrid md={4}>
          <SelectCategory
            category={category}
            setCategory={setCategory}
            categories={categories}
            categoryError={categoryError}
          />
        </S.InputGrid>
        <S.Input>
          <TextEditorFixed setValue={setValue} value={value} />
        </S.Input>
        <S.Input>
          <S.LoadingButton
            loading={loading}
            variant="contained"
            size="large"
            type="submit"
            endIcon={<SaveAs />}
            loadingPosition="end"
            width="100px !important"
          >
            Salvar
          </S.LoadingButton>
        </S.Input>
      </S.FormContainer>
    </S.Form>
  );
}
