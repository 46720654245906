import React from 'react';
import {
  Skeleton, ListItem, ListItemAvatar, ListItemText, Divider,
} from '@mui/material';

export default function ReplySkeleton() {
  return (
    <>
      <ListItem
        sx={{
          backgroundColor: 'background.level2',
          backgroundImage: 'none',
          borderRadius: '0px',
          marginBottom: 0,
          boxShadow: 'none',
          gap: 2,
        }}
      >
        <ListItemAvatar>
          <Skeleton
            variant="rounded"
            width={75}
            height={75}
            animation="wave"
          />
        </ListItemAvatar>
        <ListItemText
          primary={(
            <Skeleton variant="text" width="30%" animation="wave" />
        )}
          secondary={(
            <>
              <Skeleton variant="text" width="50%" animation="wave" />
              <Skeleton variant="text" width="50%" animation="wave" />
              <Skeleton variant="text" width="50%" animation="wave" />
            </>
        )}
        />
      </ListItem>
      <Divider variant="middle" />
    </>
  );
}
