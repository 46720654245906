import { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { setTopicPagination, setTopicDetails } from 'redux/slicers';
import { GifOptions, TopicMenu } from 'components/Menus';
import { useNavigate, useParams } from 'react-router-dom';
import { CardContainer } from 'common/CardContainer';
import { TopicContext } from 'contexts';
import { useHtmlParse } from 'hooks';
import { calculateTimeDifference } from 'utils/timeDifference';
import { CategoryCard } from 'components/Cards';
import { Box } from '@mui/material';
import { TopicPagination } from './TopicPagination';
import * as S from './styles';

export function TopicPostPreFetched({ topicDetails, pathname }) {
  const { setTopic } = useContext(TopicContext);
  const { forum } = useParams();
  const navigate = useNavigate();

  const { showGifOptions, setShowGifOptions, parsedContent } = useHtmlParse(
    topicDetails.content,
  );

  const { id, pageNumber = '1' } = useParams();

  const dispatch = useDispatch();

  const handlePageChange = (page) => {
    if (!pathname.includes('/page/')) {
      return navigate(`${pathname}/page/${page}`);
    }
    return navigate(
      `${pathname.replace(`page/${pageNumber}`, `page/${page}`)}`,
    );
  };

  const fetchTopicDetails = async () => {
    document.title = `${topicDetails.title} - Pandlr`;
    setTopic(topicDetails);
    dispatch(setTopicPagination(topicDetails.pagesCounter));
  };

  useEffect(() => {
    fetchTopicDetails();
    dispatch(setTopicDetails(topicDetails));
  }, [id]);

  const getCategorySlug = (categoryName) => {
    const regex = /[^a-zA-Z0-9]/g;
    return categoryName.toLowerCase().replace(/ /g, '-').replace(regex, '');
  };

  const AVATAR_URL = `${process.env.REACT_APP_IMAGE_URL}${topicDetails?.topicAuthor?.avatar}`;
  const TIME_SINCE = topicDetails?.createdAt ? calculateTimeDifference(topicDetails?.createdAt) : '';

  return (
    <>
      <GifOptions
        showGifOptions={showGifOptions}
        setShowGifOptions={setShowGifOptions}
      />
      <S.TopicPostHeaderContainer>
        <Box>
          <S.TopicPostTitle>{topicDetails?.title}</S.TopicPostTitle>
          {topicDetails.category && (
            <CategoryCard
              category={{
                ...topicDetails.category,
                slug: topicDetails.category?.slug || getCategorySlug(topicDetails.category?.name),
                group: {
                  slug: topicDetails.category?.group?.slug || forum,
                },
              }}
              groupSlug={topicDetails?.groupSlug}
            />
          )}
        </Box>
        <S.TopicPaginationContainer>
          <TopicPagination onSelectPage={handlePageChange} />
        </S.TopicPaginationContainer>
      </S.TopicPostHeaderContainer>
      {pageNumber === '1' && (
        <CardContainer>
          <S.CardHeaderContainer
            avatar={(
              <S.CardHeaderAvatar
                src={AVATAR_URL}
                user={topicDetails?.topicAuthor}
              />
            )}
            action={(
              <S.CardHeaderAction>
                <S.Quote username={topicDetails?.topicAuthor?.username} />
                <TopicMenu />
              </S.CardHeaderAction>
            )}
            title={(
              <S.CardHeaderTitle
                onClick={() => navigate(`/user/${topicDetails?.topicAuthor?.username}`)}
              >
                {topicDetails?.topicAuthor?.name}
              </S.CardHeaderTitle>
            )}
            subheader={(
              <S.CardHeaderSubtitle>
                {TIME_SINCE}
              </S.CardHeaderSubtitle>
            )}
          />
          <S.CardContentContainer>
            <S.CardPostContent id={`post-${topicDetails.id}`}>
              {parsedContent}
            </S.CardPostContent>
          </S.CardContentContainer>
        </CardContainer>
      )}
    </>
  );
}
