/* eslint-disable no-promise-executor-return */
import { forceLogout } from '../../utils/auth';
/* eslint-disable no-plusplus */

const requestWithRetry = async (
  API,
  resource,
  body,
  successCode = 200,
  method = 'get',
  withCredentials = false,
  maxRetries = 3,
  retryDelay = 2000, // in milliseconds
) => {
  const result = {};
  let numRetries = 0;

  const makeRequest = async () => {
    if (numRetries > maxRetries) {
      result.success = false;
      result.code = 500;
      result.data = {
        code: 500,
        message: 'Não foi possível processar a sua solicitação',
      };
      return;
    }

    try {
      const res = body
        ? await API[method](resource, body, {
          withCredentials,
        })
        : await API[method](resource, {
          withCredentials,
        });

      result.success = res.status === successCode;
      result.code = res.status;
      result.description = res.statusText;
      result.header = res?.headers;
      result.data = res?.data;

      if (res?.status !== 502 && res?.status !== 500) {
        // If response status code is not 502, we don't need to retry
        return;
      }
    } catch (err) {
      numRetries++;

      if (err?.response?.status === 403) {
        forceLogout();
        result.success = false;
        result.code = err?.response?.status || 403;
        result.data = err?.response?.data;
        return;
      }

      if (numRetries > maxRetries) {
        result.success = false;
        result.code = err?.response?.status || 500;
        result.header = null;
        result.data = {
          code: err?.response?.status || 500,
          message:
            err?.response?.data?.message
            || 'Não conseguimos processar essa requisição, tente novamente.',
          name: err?.name,
        };
      }

      if (err.response?.status !== 502 && err.response?.status !== 500 && err.code !== 'ERR_NETWORK') {
        // If error status code is not 502, we don't need to retry
        result.success = false;
        result.code = err?.response?.status || 500;
        result.header = null;
        result.data = {
          code: err?.response?.status || 500,
          message:
            err?.response?.data?.message
            || 'Não conseguimos processar essa requisição, tente novamente.',
          name: err?.name,
        };
        return;
      }

      await new Promise((resolve) => setTimeout(resolve, retryDelay));
      await makeRequest();
    }
  };

  await makeRequest();
  return result;
};

export default requestWithRetry;
