import { Box } from '@mui/material';
import { forwardRef } from 'react';

export const ListContainer = forwardRef(({ children, virtualizer }, ref) => (
  <Box
    ref={ref}
    style={{
      height: `${virtualizer.getTotalSize()}px`,
      width: '100%',
      position: 'relative',
    }}
  >
    {children}
  </Box>
));

export function ListContent({
  children, items, virtualizer, ...props
}) {
  return (
    <Box
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        transform: `translateY(${
          items?.[0]?.start ? items[0].start - virtualizer.options.scrollMargin : 0
        }px)`,
      }}
      {...props}
    >
      {children}
    </Box>
  );
}

export function ListItem({
  children, item, virtualizer, ...props
}) {
  return (
    <Box
      key={item.key}
      data-index={item.index}
      ref={virtualizer.measureElement}
      {...props}
    >
      {children}
    </Box>
  );
}
