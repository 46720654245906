import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState('')('addToGifPocket');

export const addToGifPocketSlice = createSlice({
  name: 'addToGifPocket',
  initialState,
  reducers: {
    setAddToGifPocket: (state, { payload }) => {
      state = payload;
      return state;
    },
  },
});

export const { setAddToGifPocket } = addToGifPocketSlice.actions;
