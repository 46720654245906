import {
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React from 'react';

export function CategoryCard({ sx, category }) {
  const navigate = useNavigate();

  const {
    name, color, groupId, id, slug, group,
  } = category;

  const link = `/forum/${group?.slug}/category/${groupId}/${slug}/${id}`;

  return (
    <Typography
      sx={{
        fontSize: '10px',
        fontWeight: 'bold',
        px: 0.8,
        mb: 0.25,
        bgcolor: color,
        color: 'text.sticky',
        borderRadius: '10px',
        width: 'fit-content',
        cursor: 'pointer',
        ...sx,
      }}
      onClick={() => navigate(link, {
        state: category,
      })}
    >
      {name || 'categoria'}
    </Typography>
  );
}
