import { ListItemIcon, MenuItem } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { EditTopicForm } from 'components/Forms';
import * as S from './styles';

export function EditTopicModal({ open, handleClose }) {
  return (
    <S.Modal
      open={open}
      handleClose={handleClose}
      modalName={S.MODAL_STYLES.EDIT_TOPIC_MODAL}
      title="Editar tópico"
    >
      <EditTopicForm
        open={open}
        handleClose={handleClose}
      />
    </S.Modal>
  );
}

export function EditTopicMenuItem({ handleOpen }) {
  return (
    <MenuItem onClick={handleOpen}>
      <ListItemIcon>
        <Edit />
      </ListItemIcon>
      Editar
    </MenuItem>
  );
}
