import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export function useAutoScroll() {
  const [lastScrollPos, setLastScrollPos] = useState(0);
  const [autoScroll, setAutoScroll] = useState(false);
  const [threshold, setThreshold] = useState(0);
  const { topicPagination, autoScroll: autoScrollActivated } = useSelector((state) => state);

  const HAS_MORE = !!topicPagination
    .totalPages === 0 || topicPagination.currentPage < topicPagination.totalPages;

  useEffect(() => {
    function handleScroll() {
      const scrollPos = window.pageYOffset;
      const docHeight = document.body.scrollHeight;
      const windowHeight = window.innerHeight;
      setThreshold(docHeight - windowHeight - 50);
      if (scrollPos > threshold && !HAS_MORE && threshold > 0) {
        setAutoScroll(true);
      }
      if (autoScroll && scrollPos < lastScrollPos) {
        setAutoScroll(false);
      }
      setLastScrollPos(scrollPos);
    }
    if (autoScrollActivated) {
      window.addEventListener('scroll', handleScroll);
    }

    if (!autoScrollActivated) {
      setAutoScroll(false);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollPos, autoScroll, autoScrollActivated, HAS_MORE, threshold]);

  useEffect(() => {
    let intervalId = null;
    if (autoScroll) {
      intervalId = setInterval(() => {
        window.scrollTo(0, document.body.scrollHeight, 'smooth');
      }, 100);
    } else {
      clearInterval(intervalId);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [autoScroll]);

  return [autoScroll];
}
