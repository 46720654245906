import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { TextEditorFixed } from 'components/TextEditor';
import { GifPocketModal } from 'components/Modals';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createNewTopic, getCategories } from 'services/providers/pandlr.provider';
import { setSnackbar } from 'redux/slicers';
import { Send } from '@mui/icons-material';
import * as S from './styles';
import { SelectCategory } from './SelectCategory';

export function CreateNewTopicForm() {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(null);
  const [categoryError, setCategoryError] = useState(false);
  const navigate = useNavigate();
  const { id: forumId } = useSelector((state) => state.forumDetails);
  const dispatch = useDispatch();
  const { slug } = useParams();

  const fetchCategories = async () => {
    const res = await getCategories(forumId);
    if (res.success) {
      setCategories(res.data);
    }
  };

  useEffect(() => {
    fetchCategories();

    return () => {
      setCategories([]);
      setCategory(null);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!category) {
      setCategoryError(true);
      return setLoading(false);
    }
    setLoading(true);
    const newTopic = {
      title: e.target.title.value,
      content: value,
      forumId,
      categoryId: category.id,
    };
    const response = await createNewTopic(newTopic);
    if (response.success) {
      dispatch(setSnackbar({ status: 'success', message: 'Tópico criado com sucesso!' }));
      return navigate(`/forum/${slug}/topic/${response.data.slug}/${response.data.id}`);
    }
    dispatch(setSnackbar({ status: 'error', message: response.data.message }));

    return setLoading(false);
  };

  return (
    <S.Form onSubmit={handleSubmit} sx={{ maxWidth: 800 }}>
      <S.FormContainer spacing={{ xs: 1 }}>
        <S.InputGrid md={8}>
          <TextField
            id="title"
            label="Título do tópico"
            variant="outlined"
            sx={{ mb: 2 }}
            autoFocus
            fullWidth
            required
          />
        </S.InputGrid>
        <S.InputGrid md={4}>
          <SelectCategory
            category={category}
            setCategory={setCategory}
            categories={categories}
            categoryError={categoryError}
          />
        </S.InputGrid>
        <S.Input>
          <TextEditorFixed setValue={setValue} value={value} />
          <GifPocketModal isCreatingTopic />
        </S.Input>
        <S.Input>
          <S.LoadingButton
            loading={loading}
            variant="contained"
            size="large"
            type="submit"
            endIcon={<Send />}
            loadingPosition="end"
            width="100px !important"
          >
            Criar
          </S.LoadingButton>
        </S.Input>
      </S.FormContainer>
    </S.Form>
  );
}
