import { BrowserRouter, Routes, Route } from 'react-router-dom';

import {
  Home,
  Forum,
  NotFound,
  Topic,
  Logout,
  Settings,
  Mentions,
  CreateForum,
} from 'pages';

import { ScrollToTop } from 'common/ScrollToTop';
import { ManageForum } from 'pages/ManageForum';
import { Category } from 'pages/Category';
import { DeletedTopics } from 'pages/DeletedTopics';
import { AdminLogs } from 'pages/AdminLogs/AdminLogs';
import { PublicRouter } from './PublicRouter';
import { PrivateRouter } from './PrivateRouter';
import { User } from '../pages/User/User';
import { ForumBans } from '../pages/ForumBans/ForumBans';

export function Router({ children }) {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<PublicRouter><Home /></PublicRouter>} />
        <Route path="/create-forum" element={<PrivateRouter><CreateForum /></PrivateRouter>} />
        <Route path="/forum/banned-users/:forum/:forumId" element={<ForumBans />} />
        <Route path="/forum/deleted-topics/:forum/:forumId" element={<DeletedTopics />} />
        <Route path="/forum/admin-logs/:forum/:forumId" element={<PrivateRouter><AdminLogs /></PrivateRouter>} />
        <Route path="/forum/:forum/category/:groupId/:slug/:categoryId" element={<Category />} />
        <Route path="/forum/:forum/topic/:topic/:id/page/:pageNumber" element={<Topic />} />
        <Route path="/forum/:forum/topic/:topic/:id" element={<Topic />} />
        <Route path="/forum/:slug/page/:pageNumber" element={<Forum />} />
        <Route path="/forum/manage/:slug" element={<PrivateRouter><ManageForum /></PrivateRouter>} />
        <Route path="/forum/:slug" element={<Forum />} />
        <Route path="/settings" element={<PrivateRouter><Settings /></PrivateRouter>} />
        <Route path="/user/:username" element={<User />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/mentions" element={<PrivateRouter><Mentions /></PrivateRouter>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {children}
    </BrowserRouter>
  );
}
