import {
  Box, IconButton, Menu, Tooltip,
} from '@mui/material';
import {
  AutoScrollBtn, FastReplyBtn, InfiniteScrollBtn,
  NightModeSwitchToggle, ScrollToFooter, MentionsBtn,
} from 'components/Buttons';
import React, { useEffect, useState } from 'react';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { useSelector, useDispatch } from 'react-redux';
import { setWidgetPreferences } from 'redux/slicers';

const widgetsToRender = (widgetKeys, widgets, items) => widgetKeys.map((widget) => (
  items.includes(widget) && (
    <Box key={widget}>
      {widgets[widget]}
    </Box>
  )
));

const verifyIfHaveItemsOnMenu = ({
  widgets, items,
}) => {
  const TopicWidgets = widgetsToRender(Object.keys(widgets.topic), widgets.topic, items);
  const CommonWidgets = widgetsToRender(Object.keys(widgets.common), widgets.common, items);

  if (!TopicWidgets.some(Boolean)) return null;
  if (!CommonWidgets.some(Boolean) && !TopicWidgets.some(Boolean)) return null;
  return true;
};

function RenderMenuWidgets({
  widgets, items, sx,
}) {
  const TopicWidgets = widgetsToRender(Object.keys(widgets.topic), widgets.topic, items);
  const CommonWidgets = widgetsToRender(Object.keys(widgets.common), widgets.common, items);

  return (
    <Box
      sx={sx}
    >
      {TopicWidgets}
      {CommonWidgets}
    </Box>
  );
}

function getWidgets(handleClose, widgetsOnMenu) {
  const widgets = {
    topic: {
      autoScroll: <AutoScrollBtn onClick={handleClose} isMenuItem={widgetsOnMenu.includes('autoScroll')} />,
      fastReply: <FastReplyBtn onClick={handleClose} isMenuItem={widgetsOnMenu.includes('fastReply')} />,
      infiniteScroll: <InfiniteScrollBtn onClick={handleClose} isMenuItem={widgetsOnMenu.includes('infiniteScroll')} />,
      scrollToFooter: <ScrollToFooter onClick={handleClose} isMenuItem={widgetsOnMenu.includes('scrollToFooter')} />,
    },
    common: {
      mentions: <MentionsBtn onClick={handleClose} isMenuItem={widgetsOnMenu.includes('mentions')} />,
      nightMode: <NightModeSwitchToggle onClick={handleClose} isMenuItem={widgetsOnMenu.includes('nightMode')} />,
    },
  };
  return widgets;
}

export function HeaderMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { widgetPreferences } = useSelector((state) => state.userPreferences);

  const widgetsOnMenu = Object.entries(widgetPreferences)
    ?.filter(([, value]) => !value)?.map(([key]) => key) || [];
  const outOfMenu = Object.entries(widgetPreferences)
    .filter(([, value]) => value).map(([key]) => key) || [];

  const widgets = getWidgets(handleClose, widgetsOnMenu);

  const showMenu = verifyIfHaveItemsOnMenu({
    widgets,
    items: widgetsOnMenu,
  });

  useEffect(() => {
    if (Object.keys(widgetPreferences).length === 0) {
      dispatch(setWidgetPreferences({
        scrollToFooter: false,
        mentions: true,
        nightMode: true,
        autoScroll: false,
        fastReply: false,
        infiniteScroll: false,
        all: false,
      }));
    }
  }, [widgetPreferences]);

  return (
    <>
      <Box display="flex" alignItems="center">
        <RenderMenuWidgets
          widgets={widgets}
          items={outOfMenu}
          sx={{ display: 'flex', alignItems: 'center', mr: showMenu ? 0 : 1 }}
        />
      </Box>
      {showMenu && (
        <>
          <Tooltip arrow title="Mais opções">
            <IconButton sx={{ mr: 1 }} onClick={handleClick}>
              <WidgetsIcon
                color="primary"
              />
            </IconButton>
          </Tooltip>
          <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
          >
            <Box>
              <RenderMenuWidgets
                widgets={widgets}
                items={widgetsOnMenu}
              />
            </Box>
          </Menu>
        </>
      )}
    </>
  );
}
