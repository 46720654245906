import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState({
  widgetPreferences: {},
})('userPreferences');

export const userPreferencesSlice = createSlice({
  name: 'widgetPreferences',
  initialState,
  reducers: {
    setWidgetPreferences: (state, { payload }) => {
      state.widgetPreferences = payload;
    },
  },
});

export const {
  setWidgetPreferences,
} = userPreferencesSlice.actions;
