import { Footer, Header } from 'components';
import { CreateForumForm } from 'components/Forms';
import * as S from './styles';

export function CreateForum() {
  return (
    <>
      <Header />
      <S.CreateForumFormContainer>
        <CreateForumForm />
      </S.CreateForumFormContainer>
      <Footer />
    </>
  );
}
