import { useState } from 'react';
import { Autorenew } from '@mui/icons-material';
import TooltipIconButton from 'common/TooltipIconButton';

export function ReloadTopicsBtn({ handleRefresh }) {
  const [loading, setLoading] = useState(false);
  const reloadTopics = async () => {
    setLoading(true);
    await handleRefresh();
    setLoading(false);
  };
  return (
    <TooltipIconButton
      title="Atualizar Tópicos"
      onClick={reloadTopics}
      icon={<Autorenew />}
      sx={{ color: 'text.secondary', mr: 1 }}
      disabled={loading}
    />
  );
}
