/* eslint-disable no-unused-vars */
import {
  Block, CalendarMonth, Gavel, Instagram, SupportAgent, Twitter, Warning,
} from '@mui/icons-material';
import { Stack } from '@mui/material';
import TooltipIconButton from 'common/TooltipIconButton';
import React from 'react';

export default function ForumLinks() {
  const Links = [
    {
      title: 'Twitter',
      icon: <Twitter />,
      href: 'https://twitter.com/forumpandlr',
    },
    {
      title: 'Instagram',
      icon: <Instagram />,
      href: 'https://instagram.com/forumdapan',
    },
    {
      title: 'Atendimento',
      icon: <SupportAgent />,
      href: 'https://pandlr.com/forum/pan/topic/oficial-atendimento/308',
    },
    // {
    //   title: 'Regras',
    //   icon: <Warning />,
    //   href: 'https://pandlr.com/forum/pan/topic/regras-do-forum/1',
    // },
    {
      title: 'Pangatório',
      icon: <Gavel />,
      href: 'https://pandlr.com/forum/pangatorio/',
    },
    {
      title: 'Agenda',
      icon: <CalendarMonth />,
      href: 'https://pandlr.com/forum/pan/topic/oficial-agenda/241080',
    },
    {
      title: 'Banidos',
      icon: <Block />,
      href: 'https://pandlr.com/forum/pan/topic/oficial-banidos/198301',
    },
  ];

  return (
    <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
      {Links.map((link) => (
        <TooltipIconButton key={link.title} {...link} />
      ))}
    </Stack>
  );
}
