import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState({
  latestTopics: [],
  popularTopics: [],
  featuredTopics: [],
})('forumTopicsList');

export const forumTopicsListSlice = createSlice({
  name: 'forumTopicsList',
  initialState,
  reducers: {
    setForumTopicsList: (state, { payload }) => {
      state = payload;
      return state;
    },
  },
});

export const { setForumTopicsList } = forumTopicsListSlice.actions;
