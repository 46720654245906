export const formatDate = (date, format = 'dd/mm/yyyy') => {
  const newDate = new Date(date);
  let day = newDate.getDate();
  let month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();

  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  return `${format.replace('dd', day).replace('mm', month).replace('yyyy', year)} às ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

export const verifyPreviousMessageTimeDistance = (previousMessage, currentMessage) => {
  if (!previousMessage?.createdAt || !currentMessage?.createdAt) return false;

  const previousMessageDate = new Date(previousMessage.createdAt);
  const currentMessageDate = new Date(currentMessage.createdAt);

  const timeDistance = currentMessageDate.getTime() - previousMessageDate.getTime();

  const oneDay = 60 * 60 * 24 * 1000;

  return timeDistance > oneDay;
};
