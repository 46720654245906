export const serializeChat = (chat, user) => {
  const getUnreadMessagesCount = () => {
    if (chat.lastReadMessagePositionId) {
      return Math.max((chat.lastMessage?.positionId || 0) - chat.lastReadMessagePositionId, 0);
    }
    return chat.unread;
  };

  const chatStates = {
    unread: getUnreadMessagesCount() || 0,
    isOpen: chat.isOpen || false,
    isMinimized: chat.isMinimized || false,
    isAddingParticipants: chat.isAddingParticipants || false,
    isRemovingParticipants: chat.isRemovingParticipants || false,
    isEditingChat: chat.isEditingChat || false,
  };

  if (chat.isGroup) {
    return { ...chat, ...chatStates };
  }

  return {
    ...chat,
    chatName: chat.chatName
      ?.split('__')
      ?.find((name) => name !== user.username),
    chatImage: chat.chatImage
      ?.split('__')
      ?.find((image) => !image.includes(`/${user.id}_avatar_100x100`)),
    originalChatImage: chat.chatImage,
    originalChatName: chat.chatName,
    ...chatStates,
  };
};
