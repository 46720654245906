import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

export function SelectCategory({
  categories = [], category, setCategory,
  categoryError, ...props
}) {
  return (
    <Autocomplete
      id="category"
      options={categories.filter((cat) => +cat.id !== 1)}
      getOptionLabel={(option) => option.name}
      value={category}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, newValue) => {
        setCategory(newValue);
      }}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label="Selecionar categoria"
          error={categoryError}
          helperText={categoryError && 'Selecione uma categoria'}
        />
      )}
      {...props}

    />
  );
}
