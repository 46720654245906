import { useState } from 'react';
import { Sort } from '@mui/icons-material';
import {
  Box, IconButton, Menu, MenuItem,
} from '@mui/material';

export function ForumBansOrderMenu({ handleChangeOrder }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        sx={{
          width: '40px',
          height: '40px',
        }}
        onClick={handleClick}
      >
        <Sort />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleChangeOrder('DESC')}>
          Mais recentes primeiro
        </MenuItem>
        <MenuItem onClick={() => handleChangeOrder('ASC')}>
          Mais antigos primeiro
        </MenuItem>
      </Menu>
    </Box>
  );
}
