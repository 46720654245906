import { useState } from 'react';
import { CreateNewTopicForm } from 'components/Forms';
import * as S from './styles';

export function NewTopicModal() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <S.Button handleOpen={handleOpen}>
        Criar tópico
      </S.Button>
      <S.Modal
        open={open}
        handleClose={handleClose}
        modalName={S.MODAL_STYLES.NEW_TOPIC_MODAL}
        title="Criar tópico"
      >
        <CreateNewTopicForm />
      </S.Modal>
    </>
  );
}
