import { Box } from '@mui/material';

export function UserInteractionsContainer({ children }) {
  return (
    <Box
      bgcolor="background.level2"
      borderRadius="5px"
      boxShadow={(theme) => theme.shadows[1]}
      sx={{
        mt: { xs: 2, md: 4 },
      }}
      width="100%"
    >
      {children}
    </Box>
  );
}

export function UserInteractionsTabs({ children }) {
  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
      }}
    >
      {children}
    </Box>
  );
}
