import ReplySkeleton from 'components/Reply/ReplySkeleton';
import React, {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { getUserReplies } from 'services/providers/pandlr.provider';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import { ListContainer, ListContent } from 'components/Topic/styles';
import { Box } from '@mui/material';
import { UserReplyCard } from './UserReplyCard';

export function UserReplies() {
  const [userReplies, setUserReplies] = useState([]);
  const {
    user: { id: userId },
    isLoading: isLoadingProfile,
  } = useSelector((state) => state.userProfile);

  const paginationRef = useRef({
    currentPage: 0,
    hasMore: true,
  });

  const isLoading = useRef(false);
  const listRef = useRef(null);
  const parentOffsetRef = useRef(0);

  const virtualizer = useWindowVirtualizer({
    count: userReplies.length,
    estimateSize: () => 5000,
    scrollMargin: parentOffsetRef.current,
    overscan: 5,
  });

  const virtualItems = virtualizer.getVirtualItems();

  useLayoutEffect(() => {
    parentOffsetRef.current = listRef.current?.offsetTop ?? 0;
  }, [virtualItems]);

  const fetchUserReplies = async (page) => {
    if (!userId || isLoadingProfile) return;
    const res = await getUserReplies(userId, page);
    if (res.success) {
      const { items, ...paginationOptions } = res.data;
      setUserReplies((prevReplies) => [...prevReplies, ...items]);
      paginationRef.current = paginationOptions;
    }
  };

  const fetchNextPage = async () => {
    const [lastItem] = [...virtualizer.getVirtualItems()].reverse();
    if (!lastItem || isLoading.current) return;
    if (lastItem.index >= userReplies.length - 1
        && paginationRef.current.hasMore) {
      isLoading.current = true;
      await fetchUserReplies(paginationRef.current.currentPage + 1);
      isLoading.current = false;
    }
  };

  useEffect(() => {
    fetchNextPage();
  }, [
    isLoading.current,
    paginationRef,
    virtualItems,
    userReplies,
  ]);

  useEffect(() => {
    setUserReplies([]);
    fetchUserReplies(1);

    return () => {
      setUserReplies([]);
      paginationRef.current = {
        currentPage: 0,
        hasMore: true,
      };
    };
  }, [userId, isLoadingProfile]);

  return (
    <ListContainer
      ref={listRef}
      virtualizer={virtualizer}
    >
      <ListContent items={virtualItems} virtualizer={virtualizer}>
        {virtualItems?.map((item) => (
          <Box
            key={item.key}
            data-index={item.index}
            ref={virtualizer.measureElement}
          >
            <UserReplyCard reply={userReplies[item.index]} />
          </Box>
        ))}
        {isLoading.current && Array(5).fill(0).map(() => (
          <ReplySkeleton key={`reply-skeleton-${uuidv4()}`} />
        ))}
      </ListContent>
    </ListContainer>
  );
}
