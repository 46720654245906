import { useCallback, useState } from 'react';
import { paperProps } from 'utils/paperProps';
import {
  Menu, MenuItem, ListItemIcon, Avatar,
} from '@mui/material';
import { promoteGroupUser, demoteGroupUser, unbanForumUser } from 'services/providers/pandlr.provider';
import { useSelector, useDispatch } from 'react-redux';
import {
  AccountBox, ThumbUp, ThumbDown, RemoveCircle,
} from '@mui/icons-material';
import { setSnackbar } from 'redux/slicers';
import { useNavigate } from 'react-router-dom';
import { BanMenuItem, BanModal } from 'components/Modals/BanModal';
import { ConfirmationModal } from 'components/Modals';

export function MenuUserAvatar({ user, ...props }) {
  const [isBanModalOpen, setIsBanModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const topicDetails = useSelector((state) => state.topicDetails);
  const forumDetails = useSelector((state) => state.forumDetails);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseBanModal = () => setIsBanModalOpen(false);

  const handleOpen = () => {
    setIsBanModalOpen(true);
    handleClose();
  };

  const handleUnban = async () => {
    const res = await unbanForumUser({
      groupId: forumDetails.id,
      userId: user.id,
    });
    if (res.success) {
      return dispatch(setSnackbar({
        message: res.data.message,
        status: 'success',
      }));
    }
    return dispatch(setSnackbar({
      message: res.data.message,
      status: 'error',
    }));
  };

  const handlePromote = async () => {
    const result = await promoteGroupUser(
      { userId: user.id, groupId: topicDetails.groupId, groupSlug: topicDetails.slug },
    );
    if (result.success) return dispatch(setSnackbar({ message: result.data.message, status: 'success' }));
    return dispatch(setSnackbar({ message: result.data.message, status: 'error' }));
  };

  const handleDemote = async () => {
    const result = await demoteGroupUser(
      { userId: user.id, groupId: topicDetails.groupId, groupSlug: topicDetails.slug },
    );
    if (result.success) return dispatch(setSnackbar({ message: result.data.message, status: 'success' }));
    return dispatch(setSnackbar({ message: result.data.message, status: 'error' }));
  };

  const handleViewProfile = () => {
    navigate(`/user/${user.username}`);
  };

  const ConfirmationModalUnban = useCallback(() => (
    <ConfirmationModal
      message="Você tem certeza que deseja desbanir este usuário?"
      onConfirm={handleUnban}
      title="Desbanir Usuário"
      confirmText="Desbanir"
      ModalTrigger={({ onClick }) => (
        <MenuItem onClick={onClick}>
          <ListItemIcon>
            <RemoveCircle />
          </ListItemIcon>
          Desbanir
        </MenuItem>
      )}
    />
  ), []);

  const ConfirmationModalPromote = useCallback(() => (
    <ConfirmationModal
      message="Você tem certeza que deseja promover este usuário?"
      onConfirm={handlePromote}
      title="Promover Usuário"
      confirmText="Promover"
      ModalTrigger={({ onClick }) => (
        <MenuItem onClick={onClick}>
          <ListItemIcon>
            <ThumbUp />
          </ListItemIcon>
          Promover
        </MenuItem>
      )}
    />
  ), []);

  const ConfirmationModalDemote = useCallback(() => (
    <ConfirmationModal
      message="Você tem certeza que deseja rebaixar este usuário?"
      onConfirm={handleDemote}
      title="Rebaixar Usuário"
      confirmText="Rebaixar"
      ModalTrigger={({ onClick }) => (
        <MenuItem onClick={onClick}>
          <ListItemIcon>
            <ThumbDown />
          </ListItemIcon>
          Rebaixar
        </MenuItem>
      )}
    />
  ), []);

  return (
    <>
      <Avatar
        onClick={handleClick}
        {...props}
      />
      <Menu
        id="reply-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={paperProps}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleViewProfile}>
          <ListItemIcon>
            <AccountBox sx={{
              width: 20,
            }}
            />
          </ListItemIcon>
          Ver perfil
        </MenuItem>
        {forumDetails.userCredentials?.isModerator
          && (
            <div>
              <BanMenuItem handleOpen={handleOpen} />
              <ConfirmationModalUnban />
              <ConfirmationModalPromote />
              <ConfirmationModalDemote />
            </div>
          )}
      </Menu>
      <BanModal
        open={isBanModalOpen}
        handleClose={handleCloseBanModal}
        userId={user?.id}
      />
    </>
  );
}
