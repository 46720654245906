/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from '@emotion/styled';
import { Close } from '@mui/icons-material';
import {
  Box, Slide, Avatar, Typography, IconButton, Tooltip, tooltipClasses, Badge, useMediaQuery,
} from '@mui/material';
import { useSelector } from 'react-redux';

export function ChatPopupContainer({
  children, open, index, isSm, ...props
}) {
  const isChatMenuOpen = useSelector((state) => state.chat.isChatMenuOpen);
  const rightPosition = isChatMenuOpen ? index * 340 + 440 : index * 340 + 440 - 340;

  return (
    open && (
      <Slide
        direction="up"
        easing={{ enter: 'ease-out', exit: 'ease-in' }}
        in={open}
        mountOnEnter
        unmountOnExit
      >
        <Box
          {...props}
          id="chatPopup"
          sx={{
            bgcolor: (theme) => theme.palette.background.paper,
            borderRadius: '8px 8px 0 0',
            position: 'fixed',
            bottom: 0,
            boxShadow: (theme) => theme.shadows[4],
            right: isSm ? 0 : rightPosition,
            height: isSm ? 'calc(100% - 56px)' : '455px',
            maxHeight: isSm ? '100%' : '455px',
            width: isSm ? '100%' : '330px',
            flexDirection: 'column',
            justifyContent: 'space-between',
            zIndex: 1000,
          }}
        >
          {children}
        </Box>
      </Slide>
    )
  );
}

export function ChatPopupHeaderContainer({ children }) {
  return (
    <Box
      sx={{
        px: 1,
        position: 'relative',
        bgColor: 'background.paper',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        color: (theme) => theme.palette.text.primary,
        height: '48px !important',
      }}
    >
      {children}
    </Box>
  );
}

export function ChatPopupHeaderContent({ children }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      {children}
    </Box>
  );
}

export function ChatPopupHeaderInfo({ chat }) {
  return (
    <a
      href={chat.isGroup ? '#' : `${process.env.REACT_APP_WEB_URL}/user/${chat.chatName}`}
      style={{
        textDecoration: 'none',
        color: 'inherit',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          height: '100%',
        }}
      >
        <Avatar
          src={`${process.env.REACT_APP_IMAGE_URL}${chat.chatImage}`}
          sx={{
            width: 32,
            height: 32,
          }}
        />
        <Typography
          component="span"
          sx={{
            fontSize: '1.2rem',
            lineHeight: '1',
            fontWeight: '400 !important',
          }}
        >
          {chat.chatName}
        </Typography>
      </Box>
    </a>
  );
}

export function ChatPopupActionsContainer({ children }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .MuiIconButton-root': {
          p: 0.5,
        },
      }}
    >
      {children}
    </Box>
  );
}

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[5],
    fontSize: 16,
    padding: '8px 16px',
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));

export function MinimizedChatCard({
  handleOpen, index, handleClose, chat,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <Box
      color="primary"
      aria-label="add"
      sx={{
        position: 'fixed',
        bottom: 60 + (index + 1) * 64,
        right: { xs: 18, sm: 32, md: 32 },
        cursor: 'pointer',
        borderRadius: '50%',
        '&:hover': {
          [`.close-button-${index}`]: {
            display: 'flex',
          },
          [`.badge-${index}`]: {
            display: 'none',
          },
        },
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.4),0 12px 20px rgba(0, 0, 0, 0.3)',
      }}
    >
      <Box
        sx={{
          position: 'relative',
        }}
      >
        {!isMobile && (
          <IconButton
            onClick={handleClose}
            className={`close-button-${index}`}
            sx={{
              position: 'absolute',
              top: '-6px',
              right: '-6px',
              zIndex: 1,
              bgcolor: 'background.level2',
              width: '24px',
              height: '24px',
              display: 'none',
              '&:hover': {
                bgcolor: 'background.level2',

              },
            }}
          >
            <Close
              sx={{
                width: '16px',
                height: '16px',
              }}
            />
          </IconButton>
        )}
        <Badge
          overlap="circular"
          color="error"
          badgeContent={chat.unread}
          className={`badge-${index}`}
          sx={{
            position: 'absolute',
            top: '6px',
            right: '6px',
          }}
        />
        <LightTooltip
          title={chat.chatName}
          placement="left"
          arrow
        >
          <Avatar
            onClick={handleOpen}
            alt={chat.chatName}
            src={`${process.env.REACT_APP_IMAGE_URL}${chat.chatImage}`}
            sx={{
              width: 56,
              height: 56,
            }}
          />
        </LightTooltip>
      </Box>
    </Box>
  );
}

export function MoreMinimizedChatsCard({ index, minimizedChats }) {
  return (
    <Box
      aria-label="add"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '56px',
        height: '56px',
        borderRadius: '50%',
        position: 'fixed',
        bottom: 60 + (index + 1) * 64,
        right: { xs: 18, sm: 32, md: 32 },
        backgroundColor: 'background.secondary',
        cursor: 'pointer',
      }}
    >
      <Typography
        sx={{
          position: 'relative',
          color: 'common.black',
          fontSize: '1.25rem',
        }}
      >
        +
        {minimizedChats.length - 5}
      </Typography>
    </Box>
  );
}
