const { setSnackbar, logout } = require('redux/slicers');
const { store } = require('redux/store');

export const forceLogout = () => {
  const state = localStorage.getItem('reduxStore');

  if (state) {
    const parsedState = JSON.parse(state);
    if (parsedState.user.user) {
      parsedState.user = null;

      localStorage.setItem('reduxStore', JSON.stringify(parsedState));
      store.dispatch(setSnackbar({
        message: 'Sua sessão expirou, faça login novamente.',
        status: 'error',
      }));

      setTimeout(() => {
        store.dispatch(logout());
        window.location.reload();
      }, 1000);
    }
  }
};
