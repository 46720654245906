import ExploreIcon from '@mui/icons-material/Explore';
import ExploreOffIcon from '@mui/icons-material/ExploreOff';
import { useSelector, useDispatch } from 'react-redux';
import { setAutoScroll, setSnackbar } from 'redux/slicers';
import IconButton from '@mui/material/IconButton';
import { ListItemIcon, MenuItem, Tooltip } from '@mui/material';

export function AutoScrollBtn({
  isMenuItem = false, onClick,
}) {
  const autoScroll = useSelector((state) => state.autoScroll);
  const dispatch = useDispatch();

  const handleClick = () => {
    onClick?.();
    dispatch(setAutoScroll(!autoScroll));
    dispatch(setSnackbar({
      status: 'info',
      message: autoScroll ? 'Rolagem automática desativada.' : 'Rolagem automática ativada.',
    }));
  };

  return isMenuItem ? (
    <MenuItem
      sx={{ display: 'flex' }}
      onClick={handleClick}
    >
      <ListItemIcon>
        {autoScroll ? <ExploreOffIcon /> : <ExploreIcon />}
      </ListItemIcon>
      {autoScroll ? 'Rolagem manual' : 'Rolagem automática'}
    </MenuItem>
  ) : (
    <Tooltip
      arrow
      title={
        autoScroll ? 'Rolagem manual' : 'Rolagem automática'
      }
    >
      <IconButton
        color="primary"
        aria-label="auto scroll"
        component="label"
        onClick={handleClick}
      >
        {autoScroll ? <ExploreIcon /> : <ExploreOffIcon />}
      </IconButton>
    </Tooltip>
  );
}
