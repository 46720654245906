import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState({
  isLogged: false,
  user: null,
  pendingUser: null,
})('user');

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.isLogged = true;
      state.user = payload;
    },
    clearUserInfo: (state) => {
      state.isLogged = false;
      state.user = null;
    },
    setLogged: (state, { payload }) => {
      state.isLogged = payload;
    },
    setPendingUser: (state, { payload }) => {
      state.pendingUser = payload;
    },
    updateUser: (state, { payload }) => {
      state.user = { ...state.user, ...payload };
    },
    logout: (state) => {
      state.isLogged = false;
      state.user = null;
    },
  },
});

export const {
  setUser, setLogged, clearUserInfo, setPendingUser, logout,
} = userSlice.actions;

export const selectUser = (state) => state.user.user;

export const selectLogged = (state) => state.user.isLogged;

export const selectPendingUser = (state) => state.user.pendingUser;
