import { AdminLogsList, Footer, Header } from 'components';
import * as S from './styles';

export function AdminLogs() {
  return (
    <>
      <Header />
      <S.ForumBansContainer>
        <AdminLogsList />
      </S.ForumBansContainer>
      <Footer />
    </>
  );
}
