import { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getGroupTopics, getPopularTopicsByGroupId, getGroupFeaturedTopics } from 'services/providers/pandlr.provider';
import { NewTopicModal, ReloadTopicsBtn } from 'components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import { setSnackbar, setForumTopicsList } from 'redux/slicers';
import * as S from './styles';
import { ForumTopicPagination } from './ForumTopicsPagination';
import { LoginModal } from '../Modals';
import ForumTopicsList from './ForumTopicsList';

export function ForumTopics() {
  const isLogged = useSelector((state) => state.user.isLogged);
  const forumDetails = useSelector((state) => state.forumDetails);
  const topics = useSelector((state) => state.forumTopicsList);
  const { pageNumber = '1' } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = forumDetails;

  const paginationRef = useRef({
    currentPage: +pageNumber,
    totalPages: 10,
    totalItems: 1,
  });

  const fetchGroupTopics = async (page) => {
    if (!id) return;
    await Promise.all([
      getGroupTopics(id, page),
      getGroupFeaturedTopics(id),
      getPopularTopicsByGroupId(id, page),
    ]).then((results) => {
      const [latestTopics, featuredTopics, popularTopics] = results;
      if (latestTopics.success && popularTopics.success && featuredTopics.success) {
        const { items: latestItems, ...latestPaginationOptions } = latestTopics.data;
        const popularItems = popularTopics.data.items;
        const featuredItems = featuredTopics.data.items;
        paginationRef.current = latestPaginationOptions;
        dispatch(setForumTopicsList(
          {
            latestTopics: latestItems,
            popularTopics: popularItems,
            featuredTopics: featuredItems,
          },
        ));
      }
    });
  };

  useEffect(() => {
    fetchGroupTopics(pageNumber);
    return () => dispatch(setForumTopicsList({}));
  }, [pathname, id]);

  const handlePageChange = (page) => {
    if (!pathname.includes('/page/')) { return navigate(`${pathname}/page/${page}`); }
    return navigate(`${pathname.replace(`page/${pageNumber}`, `page/${page}`)}`);
  };

  const handleRefresh = async () => {
    await fetchGroupTopics(1);
    return dispatch(setSnackbar({
      message: 'Tópicos atualizados',
      severity: 'success',
    }));
  };

  const SHOW_POPULAR_TOPICS = topics.popularTopics?.length > 0 && topics.latestTopics?.length > 0;
  return (
    <>
      <S.ForumTopicsContainer>
        <S.ForumTopicsHeader>
          {isLogged ? <NewTopicModal /> : <LoginModal text="New Topic" />}
          <ReloadTopicsBtn handleRefresh={handleRefresh} />
        </S.ForumTopicsHeader>

        <ForumTopicsList
          topics={topics.featuredTopics}
          groupSlug={forumDetails.slug}
          isFeatured
        />

        <ForumTopicsList
          topics={topics.latestTopics}
          showSkeleton
          groupSlug={forumDetails.slug}
        />
        <ForumTopicPagination
          onSelectPage={handlePageChange}
          totalPages={paginationRef.current.totalPages}
        />
      </S.ForumTopicsContainer>
      <S.ForumTopicsContainer>
        {SHOW_POPULAR_TOPICS && (
          <>
            <Typography variant="h5" sx={{ mt: 2, ml: 2 }}>Tópicos Populares</Typography>
            <ForumTopicsList
              topics={topics.popularTopics}
              groupSlug={forumDetails.slug}
            />
            <ForumTopicPagination
              onSelectPage={handlePageChange}
              totalPages={paginationRef.current.totalPages}
            />
          </>
        )}
      </S.ForumTopicsContainer>
    </>
  );
}
