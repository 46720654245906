import { pandlrAPI } from '../api/pandlr';
import request from '../http/request';

export const loginFirebase = async (idToken, providerUserInfo) => request(
  pandlrAPI,
  '/auth/login',
  { idToken, providerUserInfo },
  200,
  'post',
  true,
);

export const checkPhoneNumber = async (phoneNumber) => request(
  pandlrAPI,
  `/auth/check-phone-number/${phoneNumber}`,
  null,
  200,
  'get',
);

export const createNewAccount = async (userInfos) => request(
  pandlrAPI,
  '/users/create-new-account',
  userInfos,
  201,
  'post',
  true,
);

export const updateUserInfo = async (userInfos) => request(
  pandlrAPI,
  '/users/update-user',
  userInfos,
  201,
  'put',
  true,
);

export const updateUserProfile = async (userInfos) => request(
  pandlrAPI,
  '/users/update-user-profile',
  userInfos,
  201,
  'put',
  true,
);

export const login = async (userInfos) => request(
  pandlrAPI,
  '/users/login',
  userInfos,
  200,
  'post',
  true,
);

export const loginWithGoogle = async (googleToken) => request(
  pandlrAPI,
  '/users/login-with-google',
  { googleToken },
  200,
  'post',
  true,
);

export const logout = async () => request(
  pandlrAPI,
  '/users/logout',
  null,
  200,
  'get',
  true,
);

export const getAllUsers = async () => request(
  pandlrAPI,
  '/users',
  null,
  200,
  'get',
);

export const getGroupDetails = async (groupSlug, userId) => request(
  pandlrAPI,
  `/groups/details/${groupSlug}/${userId}`,
  null,
  200,
  'get',
);

export const getGroupTopics = async (groupId, page) => request(
  pandlrAPI,
  `/groups/topics/${groupId}?page=${page}`,
  null,
  200,
  'get',
);

export const getGroupFeaturedTopics = async (groupId) => request(
  pandlrAPI,
  `/groups/featured-topics/${groupId}`,
  null,
  200,
  'get',
);

export const getTopicDetails = async (topicId, userId) => request(
  pandlrAPI,
  `/topics/details/${topicId}/${userId}`,
  null,
  200,
  'get',
);

export const getTopicReplies = async (topicId, page) => request(
  pandlrAPI,
  `/topics/replies/${topicId}?page=${page}`,
  null,
  200,
  'get',
);

export const getTopicRepliesLastPage = async (topicId) => request(
  pandlrAPI,
  `/topics/replies/last-page/${topicId}`,
  null,
  200,
  'get',
);

export const uploadUserAvatar = async (FormData) => request(
  pandlrAPI,
  '/users/upload-avatar',
  FormData,
  200,
  'post',
  true,
);

export const uploadUserBackground = async (FormData) => request(
  pandlrAPI,
  '/users/upload-background',
  FormData,
  200,
  'post',
  true,
);

export const joinGroup = async (groupId, groupSlug) => request(
  pandlrAPI,
  `/groups/join/${groupId}/${groupSlug}`,
  null,
  200,
  'get',
  true,
);

export const leaveGroup = async (groupId, groupSlug) => request(
  pandlrAPI,
  `/groups/leave/${groupId}/${groupSlug}`,
  null,
  200,
  'get',
  true,
);

export const createNewTopic = async ({
  title, content, categoryId, forumId,
}) => request(
  pandlrAPI,
  `/topics/new/${forumId}`,
  { title, content, categoryId },
  201,
  'post',
  true,
);

export const editTopic = async ({
  title, content, topicId, categoryId, forumId,
}) => request(
  pandlrAPI,
  `/topics/edit/${topicId}/${forumId}`,
  { title, content, categoryId },
  201,
  'put',
  true,
);

export const createNewReply = async ({
  content, topicId, forumId, userInfo,
}) => request(
  pandlrAPI,
  `/topics/reply/new/${topicId}/${forumId}`,
  { content, userInfo },
  201,
  'post',
  true,
);

export const forumBanUser = async ({
  groupId, userId, reason, groupSlug, banExpiration, bannedIndefinitely,
}) => request(
  pandlrAPI,
  `/groups/ban/${groupId}/${groupSlug}/${userId}`,
  { reason, banExpiration, bannedIndefinitely },
  200,
  'post',
  true,
);

export const forumTopicDelete = async ({ topicId, groupId }) => request(
  pandlrAPI,
  `/topics/delete/${topicId}/${groupId}`,
  null,
  200,
  'get',
  true,
);

export const forumReplyDelete = async ({ replyId, groupId, topicId }) => request(
  pandlrAPI,
  `/topics/reply/delete/${replyId}/${groupId}/${topicId}`,
  null,
  200,
  'get',
  true,
);

export const forumReplyDeleteFlood = async ({ userId, groupId }) => request(
  pandlrAPI,
  `/groups/delete-flood/${groupId}/${userId}`,
  null,
  200,
  'get',
  true,
);

export const serverCheck = async () => request(
  pandlrAPI,
  '/check-cores',
  null,
  200,
  'get',
  true,
);

export const promoteGroupUser = async ({ groupId, userId, groupSlug }) => request(
  pandlrAPI,
  `/groups/promote/${groupId}/${groupSlug}/${userId}`,
  null,
  200,
  'get',
  true,
);

export const demoteGroupUser = async ({ groupId, userId, groupSlug }) => request(
  pandlrAPI,
  `/groups/demote/${groupId}/${groupSlug}/${userId}`,
  null,
  200,
  'get',
  true,
);

export const closeTopic = async ({ topicId, groupId }) => request(
  pandlrAPI,
  `/topics/close/${topicId}/${groupId}`,
  null,
  200,
  'get',
  true,
);

export const openTopic = async ({ topicId, groupId }) => request(
  pandlrAPI,
  `/topics/open/${topicId}/${groupId}`,
  null,
  200,
  'get',
  true,
);

export const getPopularTopicsByGroupId = async (groupId, page) => request(
  pandlrAPI,
  `/groups/popular-topics/${groupId}?page=${page}`,
  null,
  200,
  'get',
);

export const recountTopicRepliesById = async ({ topicId, groupId }) => request(
  pandlrAPI,
  `/topics/recount-replies/${topicId}/${groupId}`,
  null,
  200,
  'get',
  true,
);

export const getUserByUsername = async (username) => request(
  pandlrAPI,
  `/users/username/${username}`,
  null,
  200,
  'get',
  true,
);

export const getUserReplies = async (userId, page) => request(
  pandlrAPI,
  `/users/replies/${userId}?page=${page}`,
  null,
  200,
  'get',
);

export const getUserTopics = async (userId, page) => request(
  pandlrAPI,
  `/users/topics/${userId}?page=${page}`,
  null,
  200,
  'get',
);

export const addNewGifToGifPocket = async ({ shortcut, imageUrl }) => request(
  pandlrAPI,
  '/users/gif-pocket/add',
  { shortcut, imageUrl },
  201,
  'post',
  true,
);

export const getGifPocketList = async () => request(
  pandlrAPI,
  '/users/gif-pocket/list',
  null,
  200,
  'get',
  true,
);

export const deleteGifFromGifPocket = async (gifId) => request(
  pandlrAPI,
  `/users/gif-pocket/delete/${gifId}`,
  null,
  200,
  'delete',
  true,
);

export const updateGifShortcut = async ({ gifId, shortcut }) => request(
  pandlrAPI,
  `/users/gif-pocket/update/${gifId}`,
  { shortcut },
  200,
  'put',
  true,
);

export const getForumBans = async (groupId, page, query, orderBy, bannedIndefinitely) => request(
  pandlrAPI,
  `/groups/bans/${groupId}?page=${page}&query=${query ?? ''}&orderBy=${orderBy}${bannedIndefinitely ? '&bannedIndefinitely=true' : ''}`,
  null,
  200,
  'get',
  true,
);

export const unbanForumUser = async ({ groupId, userId, groupSlug }) => request(
  pandlrAPI,
  `/groups/unban/${groupId}/${groupSlug}/${userId}/`,
  null,
  200,
  'get',
  true,
);

export const getMentionsByUserId = async (userId, page) => request(
  pandlrAPI,
  `/users/mentions/${userId}?page=${page}`,
  null,
  200,
  'get',
);

export const createNewChatMessage = async (message, chatId) => request(
  pandlrAPI,
  `/chat/new-message/${chatId}`,
  message,
  201,
  'post',
  true,
);

export const getChatMessages = async (chatId, page) => request(
  pandlrAPI,
  `/chat/chat-messages/${chatId}?page=${page}`,
  null,
  200,
  'get',
  true,
);

export const createNewChat = async (chatName, chatParticipants) => request(
  pandlrAPI,
  '/chat/new-chat',
  { chatName, chatParticipants },
  201,
  'post',
  true,
);

export const addChatParticipants = async (chatId, chatParticipant) => request(
  pandlrAPI,
  `/chat/add-participants/${chatId}`,
  { chatParticipant },
  200,
  'put',
  true,
);

export const getChatParticipants = async (chatId) => request(
  pandlrAPI,
  `/chat/chat-participants/${chatId}`,
  null,
  200,
  'get',
  true,
);

export const uploadChatImage = async (formData, chatId, chatName) => request(
  pandlrAPI,
  `/chat/upload-image/${chatId}/${chatName}`,
  formData,
  200,
  'post',
  true,
);

export const updateChat = async (chatId, chatInfo) => request(
  pandlrAPI,
  `/chat/update-chat/${chatId}`,
  chatInfo,
  200,
  'put',
  true,
);

export const leaveGroupChat = async (chatId) => request(
  pandlrAPI,
  `/chat/leave-chat/${chatId}`,
  null,
  200,
  'delete',
  true,
);

export const kickChatParticipants = async (chatId, participants) => request(
  pandlrAPI,
  `/chat/kick-participants/${chatId}`,
  { participants },
  200,
  'put',
  true,
);

export const followUser = async (followedUserId) => request(
  pandlrAPI,
  '/users/follow',
  { followedUserId },
  200,
  'post',
  true,
);

export const unfollowUser = async (followedUserId) => request(
  pandlrAPI,
  `/users/unfollow/${followedUserId}`,
  null,
  200,
  'delete',
  true,
);

export const getUserFriends = async (page, query) => request(
  pandlrAPI,
  `/users/friends?page=${page}&query=${query ?? ''}`,
  null,
  200,
  'get',
  true,
);

export const getUserChatList = async (page) => request(
  pandlrAPI,
  `chat/chat-list?page=${page}`,
  null,
  200,
  'get',
  true,
);

export const pinTopicByTopicId = async ({ topicId, groupId }) => request(
  pandlrAPI,
  `/topics/pin/${topicId}/${groupId}`,
  null,
  200,
  'get',
  true,
);

export const unpinTopicByTopicId = async ({ topicId, groupId }) => request(
  pandlrAPI,
  `/topics/unpin/${topicId}/${groupId}`,
  null,
  200,
  'get',
  true,
);

export const uploadForumImage = async (formData, forumId, groupSlug) => request(
  pandlrAPI,
  `/groups/upload-image/${forumId}/${groupSlug}`,
  formData,
  200,
  'post',
  true,
);

export const uploadForumBanner = async (formData, forumId, groupSlug) => request(
  pandlrAPI,
  `/groups/upload-banner/${forumId}/${groupSlug}`,
  formData,
  200,
  'post',
  true,
);

export const updateForumDetails = async (data, groupId, groupSlug) => request(
  pandlrAPI,
  `/groups/edit-details/${groupId}/${groupSlug}`,
  data,
  200,
  'put',
  true,
);

export const uploadFirstFrameToServer = async (FormData) => {
  try {
    const res = await pandlrAPI
      .post('/users/upload-gif-pocket', FormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });

    return {
      success: res.status === 200,
      data: res.data,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || 'Não foi possível fazer o upload da imagem',
    };
  }
};

export const createNewForum = async (data) => request(
  pandlrAPI,
  '/groups/create',
  data,
  201,
  'post',
  true,
);

export const createCategory = async (data, groupId) => request(
  pandlrAPI,
  `/groups/create-category/${groupId}`,
  data,
  201,
  'post',
  true,
);

export const getCategories = async (groupId) => request(
  pandlrAPI,
  `/groups/categories/${groupId}`,
  null,
  200,
  'get',
  true,
);

export const getCategoryById = async (groupId, categoryId) => request(
  pandlrAPI,
  `/groups/category/${groupId}/${categoryId}`,
  null,
  200,
  'get',
  true,
);

export const getTopicsByCategoryId = async (categoryId, groupId, page) => request(
  pandlrAPI,
  `/groups/topics/category/${groupId}/${categoryId}?page=${page}`,
  null,
  200,
  'get',
  true,
);

export const updateCategory = async (data, groupId, categoryId) => request(
  pandlrAPI,
  `/groups/edit-category/${groupId}/${categoryId}`,
  data,
  200,
  'put',
  true,
);

export const getDeletedTopics = async (groupId, page) => request(
  pandlrAPI,
  `/groups/deleted-topics/${groupId}?page=${page}`,
  null,
  200,
  'get',
  true,
);

export const restoreDeletedTopic = async (topicId, groupId) => request(
  pandlrAPI,
  `/topics/restore/${topicId}/${groupId}`,
  null,
  200,
  'get',
  true,
);

export const getAdminLogs = async (groupId, page, action, orderBy, search) => request(
  pandlrAPI,
  `/groups/admin-logs/${groupId}?page=${page}&action=${action ?? ''}&orderBy=${orderBy ?? 'DESC'}&search=${search ?? ''}`,
  null,
  200,
  'get',
  true,
);

export const getAdminLogsActions = async (groupId) => request(
  pandlrAPI,
  `/groups/admin-logs-actions/${groupId}`,
  null,
  200,
  'get',
  true,
);
