/* eslint-disable func-names */
import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { addNewGifToGifPocket, getGifPocketList, uploadFirstFrameToServer } from 'services/providers/pandlr.provider';
import { setGifPocket, setSnackbar } from 'redux/slicers';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { ValidateAddGifForm } from 'utils/yupValidations';
import * as S from './styles';

export function AddGifToGifPocket({
  setShowAddForm,
}) {
  const dispatch = useDispatch();

  const getFirstFrameFromImageUrl = async (imageUrl) => new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, img.width, img.height);

      try {
        canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/jpeg');
      } catch (error) {
        reject(error);
      }
    };
    img.onerror = function (error) {
      reject(error);
    };
    img.src = imageUrl;
  });

  const handleAddNewGifToGifPocket = async (values) => {
    const { imageUrl, shortcut } = values;
    const firstFrameBlob = await getFirstFrameFromImageUrl(imageUrl);
    const formData = new FormData();
    const fileName = `${encodeURI(imageUrl.replace('https://', '').replace('http://', '').replaceAll('.', '').replaceAll('/', ''))}`;
    formData.append('file', firstFrameBlob, `${fileName}`);

    await uploadFirstFrameToServer(formData);

    const result = await addNewGifToGifPocket({ imageUrl, shortcut });

    if (result.success) {
      const fetchList = await getGifPocketList();
      if (fetchList.success) {
        dispatch(setGifPocket(fetchList.data));
      }
      return dispatch(setSnackbar({
        status: 'success',
        message: 'Gif adicionado com sucesso!',
        open: true,
      }));
    }
    return dispatch(setSnackbar({
      status: 'error',
      message: result.data.message || 'Erro ao adicionar gif',
      open: true,
    }));
  };

  const formik = useFormik({
    initialValues: {
      imageUrl: '',
      shortcut: '',
    },
    validationSchema: ValidateAddGifForm,
    onSubmit: async (values) => {
      await handleAddNewGifToGifPocket(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <S.TextField
        label="Endereço (URL)"
        formik={formik}
        name="imageUrl"
        sx={{ mb: 2 }}
      />
      <S.TextField
        label="Atalho"
        name="shortcut"
        formik={formik}
        sx={{ mb: 2 }}
      />
      <LoadingButton
        variant="contained"
        size="large"
        type="submit"
        endIcon={<SaveIcon />}
        loadingPosition="end"
        onClick={formik.handleSubmit}
        disabled={!formik.isValid}
        loading={formik.isSubmitting}
      >
        Salvar
      </LoadingButton>
      <Button
        variant="contained"
        size="large"
        color="error"
        sx={{ ml: 1 }}
        onClick={() => setShowAddForm(false)}
      >
        Cancelar
      </Button>
    </form>
  );
}
