import {
  Header, Footer, ForumDetails, ForumTopics,
} from 'components';

import * as S from './styles';

export function Forum() {
  return (
    <>
      <Header />
      <S.ForumContainer>
        <ForumDetails />
        <ForumTopics />
      </S.ForumContainer>
      <Footer />
    </>
  );
}
