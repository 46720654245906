import React from 'react';
import UserInfo from './UserInfo';
import { UserInteractions } from './UserInteractions';

export function UserProfile() {
  return (
    <div>
      <UserInfo />
      <UserInteractions />
    </div>
  );
}
