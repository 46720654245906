import { Tab, Tabs } from '@mui/material';
import { TabPanel } from 'components/Tabs/TabPanel';
import React from 'react';
import { a11yProps } from 'utils/muiUtils';
import * as S from './styles';
import { UserReplies } from './UserReplies';
import { UserTopics } from './UserTopics';

export function UserInteractions() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <S.UserInteractionsContainer>
      <S.UserInteractionsTabs>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Replies" {...a11yProps(0)} />
          <Tab label="Topics" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <UserReplies />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <UserTopics />
        </TabPanel>
      </S.UserInteractionsTabs>
    </S.UserInteractionsContainer>
  );
}
