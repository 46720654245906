import { useEffect } from 'react';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import {
  Logo, LoginModal, LoggedMenu, HeaderMenu,
} from 'components';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slicers/user/userSlice';
import * as S from './styles';

export function Header() {
  const user = useSelector(selectUser);
  useEffect(() => {
    if (user) {
      document.cookie = 'loggedIn=true; path=/;';
    } else {
      document.cookie = 'loggedIn=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
  }, [user]);

  return (
    <S.HeaderContainer>
      <S.HeaderContent>
        <Link component={RouterLink} to="/forum/pan" underline="none">
          <Logo />
        </Link>
        <S.HeaderMenuContainer>
          <HeaderMenu />
          {user ? <LoggedMenu /> : <LoginModal />}
        </S.HeaderMenuContainer>
      </S.HeaderContent>
    </S.HeaderContainer>
  );
}
