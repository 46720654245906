import { RegistrationForm } from 'components/Forms';
import React from 'react';
import { useAuth } from 'hooks';
import * as S from './styles';

export default function RegisterModal() {
  const { openRegisterModal } = useAuth();

  return (
    <S.Modal
      open={openRegisterModal}
      modalName={S.MODAL_STYLES.REGISTER_MODAL}
    >
      <RegistrationForm />
    </S.Modal>
  );
}
