import { EditCategoryForm } from 'components/Forms';
import { useState } from 'react';
import { IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';
import * as S from './styles';

export function EditCategoryModal({ category, setCategory }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        <Edit />
      </IconButton>
      <S.Modal
        open={open}
        handleClose={handleClose}
      >
        <EditCategoryForm
          category={category}
          setCategory={setCategory}
          handleClose={handleClose}
        />
      </S.Modal>
    </>
  );
}
