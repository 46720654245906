import {
  Box, CardContent, CardHeader, Typography,
} from '@mui/material';
import { MenuUserAvatar } from 'components/Menus';
import { Reply } from '@mui/icons-material';
import { PostContent } from 'common/PostContent';

export function CardHeaderContainer({ ...props }) {
  return (
    <CardHeader
      sx={{
        alignItems: 'flex-start',
        paddingBottom: { md: 0 },
        '& .MuiCardHeader-content': {
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'flex-start', md: 'center' },
        },
      }}
      {...props}
    />
  );
}

export function CardHeaderAvatar({ src, user }) {
  return (
    <MenuUserAvatar
      user={user}
      src={src}
      alt={user?.username}
      variant="rounded"
      sx={{
        width: { xs: 45, sm: 50, md: 75 },
        height: { xs: 45, sm: 50, md: 75 },
        marginTop: '3px',
        cursor: 'pointer',
      }}
    />
  );
}

export function CardHeaderAction({ children }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        cursor: 'pointer',
      }}
    >
      {children}
    </Box>
  );
}

export function Quote({ username, onClick }) {
  return (
    <Box
      sx={{
        display: { xs: 'none', sm: 'flex' },
        alignItems: 'center',
        gap: '5px',
      }}
      onClick={onClick}
    >
      <Reply sx={{ width: 20 }} />
      <Typography
        sx={{ display: 'inline' }}
        component="span"
        variant="body3"
        color="text.secondary"
      >
        @
        {username}
      </Typography>
    </Box>
  );
}

export function CardHeaderTitle({ children, onClick }) {
  return (
    <Typography
      sx={{
        fontWeight: 'bold',
        mr: 1,
        cursor: 'pointer',
      }}
      component="span"
      variant="body1"
      color="text.primary"
      onClick={onClick}
    >
      {children}
    </Typography>
  );
}

export function CardHeaderSubtitle({ children }) {
  return (
    <Typography
      sx={{ display: { xs: 'block', md: 'inline' } }}
      component="span"
      variant="body3"
      color="text.secondary"
    >
      {children}
    </Typography>
  );
}

export function CardHeaderSubheader({ children }) {
  return (
    <Typography
      component="span"
      variant="body3"
      color="text.secondary"
    >
      {children}
    </Typography>
  );
}

export function CardContentContainer({ children }) {
  return (
    <CardContent
      sx={{
        paddingTop: 0,
        transform: { xs: 'none', md: 'translateY(-20px)' },
      }}
    >
      {children}
    </CardContent>
  );
}

export function CardPostContent({ children, ...props }) {
  return (
    <PostContent
      sx={{
        ml: { xs: 0, sm: 0, md: 12 },
        mt: 0,
        display: 'block',
      }}
      component="span"
      variant="body1"
      color="text.primary"
      {...props}
    >
      {children}
    </PostContent>
  );
}
