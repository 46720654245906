import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const PostContent = styled(Box)`
  p {
    margin: 0;
    word-break: break-word;
  }
  img {
    object-fit: 'contain';
    max-width: 100%;
  }
  a {
    color: #2196f3;
  }
  a:hover {
    color: #0d47a1;
  }
`;
