import { useEffect, useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getGroupDetails } from 'services/providers/pandlr.provider';
import { useDispatch, useSelector } from 'react-redux';
import { setForumDetails } from 'redux/slicers';
import { Box } from '@mui/material';
import { ForumModeratosMenu } from 'components/Menus';
import { JoinForumButton } from 'components/Buttons';
import {
  AvatarContainer,
  BannerImage,
  Description,
  ForumImage,
  MainHeaderContainer,
  MainHeaderDetailsContainer,
  MainHeaderDetailsContent,
  MainHeaderImagesContainer,
  MainHeaderName,
  MainHeaderNameMobile,
  MainHeaderSkeleton,
  MembersCount,
  MembersCountMobile,
} from 'styles/MainHeader.styles';
import ForumModerators from './ForumModeratos';
import ForumLinks from './ForumLinks';

export function ForumDetails() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const forumDetails = useSelector((state) => state.forumDetails);
  const [isLoading, setIsLoading] = useState(true);

  const previousSlugRef = useRef(slug);
  const previousSlug = previousSlugRef.current;

  useEffect(() => {
    previousSlugRef.current = slug;
    dispatch(setForumDetails({}));
  }, [slug]);

  useEffect(() => {
    previousSlugRef.current = slug;
    const fetchGroupDetails = async () => {
      setIsLoading(true);
      const result = await getGroupDetails(slug, user.user?.id);
      document.title = `${result.data.name} - Pandlr`;
      dispatch(setForumDetails(result.data));
      setIsLoading(false);
    };
    fetchGroupDetails();
    return () => {
      if (previousSlug !== slug) {
        dispatch(setForumDetails({}));
      }
    };
  }, [slug]);

  const GROUP_AVATAR_URL = `${process.env.REACT_APP_IMAGE_URL}${forumDetails.groupImage}`;
  const GROUP_BANNER_URL = `${process.env.REACT_APP_IMAGE_URL}${forumDetails.groupBackgroundImage}`;
  const avatarUrl = forumDetails.avatarLink || '#';
  const currentUrl = 'https://pandlr.com';
  const AVATAR_URL = avatarUrl.replace(currentUrl, '');

  const DESCRIPTION = forumDetails?.description;

  return (
    <MainHeaderContainer>
      {isLoading ? (
        <MainHeaderSkeleton />
      ) : (
        <>
          <MainHeaderImagesContainer>
            <BannerImage src={GROUP_BANNER_URL} />
            {forumDetails.userCredentials?.isModerator && (<ForumModeratosMenu />)}
            <AvatarContainer>
              <Link to={AVATAR_URL}>
                <ForumImage src={GROUP_AVATAR_URL} alt={forumDetails.name} />
              </Link>
            </AvatarContainer>
            <MainHeaderName>{forumDetails.name}</MainHeaderName>
          </MainHeaderImagesContainer>
          <MainHeaderDetailsContainer>
            <MainHeaderDetailsContent>
              <MainHeaderNameMobile>
                {forumDetails.name}
                <MembersCountMobile membersCount={forumDetails?.membersCount} />
              </MainHeaderNameMobile>
              <MembersCount membersCount={forumDetails?.membersCount} />
              <ForumModerators />
            </MainHeaderDetailsContent>
            <Box>
              <JoinForumButton forumDetails={forumDetails} />
              <Description description={DESCRIPTION} />
              <ForumLinks />
            </Box>
          </MainHeaderDetailsContainer>
        </>
      )}
    </MainHeaderContainer>
  );
}
