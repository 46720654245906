import React from 'react';
import { SaveAs } from '@mui/icons-material';
import {
  updateUserProfile,
  uploadUserAvatar,
  uploadUserBackground,
} from 'services/providers/pandlr.provider';
import { useDispatch, useSelector } from 'react-redux';

import {
  AvatarContainer,
  BannerImage,
  ForumImage,
  MainHeaderContainer,
  MainHeaderDetailsContainer,
  MainHeaderImagesContainer,
  MainHeaderName,
  MainHeaderUserNameMobile,
  MainHeaderUsername,
} from 'styles/MainHeader.styles';
import { useUploadImages } from 'hooks/useUploadImages';
import { useFormik } from 'formik';
import { validateUpdateUserForm } from 'utils/yupValidations';
import { setSnackbar, setUser } from 'redux/slicers';
import * as S from './styles';
import { ControlledTextField } from './ControlledFormComponents';

export function UpdateUserForm() {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { uploadImages } = useUploadImages();

  const INITIAL_STATE = {
    name: user.name || '',
    avatar: '',
    background: '',
  };

  const onSubmit = async (data, formik) => {
    const images = [
      {
        name: 'avatar',
        file: data.avatar,
        cb: uploadUserAvatar,
        id: user.id,
      },
      {
        name: 'background',
        file: data.background,
        cb: uploadUserBackground,
        id: user.id,
      },
    ];

    const { uploadedImages, uploadErrors } = await uploadImages(images);

    if (uploadErrors.avatar || uploadErrors.background) {
      formik.setErrors({
        avatar: uploadErrors.avatar || '',
        background: uploadErrors.background || '',
      });

      return;
    }

    const result = await updateUserProfile({
      ...data,
      avatar: uploadedImages.avatar?.path300,
      avatarThumb: uploadedImages.avatar?.path,
      background: uploadedImages.background?.path,
    });

    if (!result.success) {
      const error = result.data.message;
      if (error.includes('name')) {
        formik.setErrors({ name: result.data.message });
        return;
      }

      dispatch(setSnackbar({ message: result.data.message, status: 'error' }));
      return;
    }

    dispatch(setUser(result.data));

    dispatch(
      setSnackbar({
        message: 'Perfil atualizado com sucesso!',
        status: 'success',
      }),
    );
  };

  const formik = useFormik({
    initialValues: INITIAL_STATE,
    validationSchema: validateUpdateUserForm,
    onSubmit: async (data) => onSubmit(data, formik),
  });

  const AVATAR_URL = formik.values.avatar
    ? URL.createObjectURL(formik.values.avatar)
    : `${process.env.REACT_APP_IMAGE_URL}/${user.avatar}`;

  const BANNER_URL = formik.values.background
    ? URL.createObjectURL(formik.values.background)
    : `${process.env.REACT_APP_IMAGE_URL}/${user.background}`;

  return (
    <>
      <MainHeaderContainer>
        <MainHeaderImagesContainer>
          <BannerImage src={BANNER_URL} />
          <AvatarContainer>
            <ForumImage src={AVATAR_URL} />
          </AvatarContainer>
          <MainHeaderName>{user.name}</MainHeaderName>
        </MainHeaderImagesContainer>
        <MainHeaderDetailsContainer>
          <MainHeaderUserNameMobile>{user.name}</MainHeaderUserNameMobile>
          <MainHeaderUsername>
            @
            {user.username}
          </MainHeaderUsername>
        </MainHeaderDetailsContainer>
      </MainHeaderContainer>

      <S.UpdateUserFormContainer>
        <S.Form onSubmit={formik.handleSubmit}>
          <S.FormSubtitle textAlign="start" mb={3}>Atualizar perfil</S.FormSubtitle>
          <S.FormContainer>
            <S.Input>
              <ControlledTextField
                variant="outlined"
                fullWidth
                label="Nome *"
                name="name"
                formik={formik}
              />
            </S.Input>

            <S.Input>
              <S.UploadImageButton
                label="Carregue uma imagem para seu avatar"
                name="avatar"
                formik={formik}
              />
            </S.Input>

            <S.Input>
              <S.UploadImageButton
                label="Carregue uma imagem para seu background"
                name="background"
                formik={formik}
              />
            </S.Input>

            <S.FormActions>
              <S.LoadingButton
                disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                type="submit"
                loading={formik.isSubmitting}
                endIcon={<SaveAs />}
              >
                Salvar
              </S.LoadingButton>
            </S.FormActions>
          </S.FormContainer>
        </S.Form>
      </S.UpdateUserFormContainer>
    </>
  );
}
