/* eslint-disable no-underscore-dangle */
import {
  RecaptchaVerifier, deleteUser, signInWithPhoneNumber, signInWithPopup,
} from 'firebase/auth';
import {
  createContext, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setPendingUser, setSnackbar, setUser } from 'redux/slicers';
import { auth } from 'services/firebase';
import { checkPhoneNumber, loginFirebase } from 'services/providers/pandlr.provider';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [codeWasSent, setCodeWasSent] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [loginProviderData, setLoginProviderData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginWithFirebase = async (
    responseIdToken,
    providerUserInfo,
    providerId,
    verifiedPhoneNumber = false,
  ) => {
    const loginRes = await loginFirebase(
      responseIdToken,
      { ...providerUserInfo, providerId, verifiedPhoneNumber },
    );

    if (loginRes.success) {
      if (loginRes.data.status === 'pending') {
        dispatch(setPendingUser(loginRes.data));
        setOpenRegisterModal(true);
        return;
      }

      dispatch(setUser(loginRes.data));
      navigate('/forum/pan');
    } else {
      const { message } = loginRes.data;
      const possibleErrors = [
        'account-not-old-enough',
        'phone-verification-not-enabled',
        'needs-phone-verification',
      ];

      if (possibleErrors.includes(message)) {
        setOpenConfirmationModal(true);
        return;
      }
      dispatch(setSnackbar({
        message: 'Não foi possível realizar o login. Tente novamente mais tarde.',
        status: 'error',
      }));
    }
  };

  const loginPopup = async (provider) => {
    try {
      const res = await signInWithPopup(auth, provider);

      const providerUserInfo = JSON.parse(res._tokenResponse.rawUserInfo);

      const { providerId } = res;
      const { idToken } = res._tokenResponse;

      await loginWithFirebase(idToken, providerUserInfo, providerId);

      setLoginProviderData({
        providerUserInfo,
        providerId,
        idToken,
      });
    } catch (error) {
      dispatch(setSnackbar({
        message: 'Não foi possível fazer login. Tente novamente.',
        status: 'error',
      }));
    }
  };

  const renderRecaptcha = () => {
    const container = document.getElementById('verifier');
    container.innerHTML = '';
    const recaptchaContainer = document.createElement('div');
    recaptchaContainer.setAttribute('id', 'recaptcha-container');
    container.appendChild(recaptchaContainer);
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      callback: () => { },
    }, auth);
  };

  const sentVerificationCode = async (phoneNumber) => {
    try {
      setIsLoading(true);
      const isValidPhoneNumberResult = await checkPhoneNumber(phoneNumber);

      if (!isValidPhoneNumberResult.data.isValid) {
        const { message } = isValidPhoneNumberResult.data;

        const messages = {
          'phone-number-already-in-use': 'Número de telefone já cadastrado.',
          'error-while-checking-phone-number': 'Não foi possível verificar o número de telefone. Tente mais tarde.',
        };

        dispatch(setSnackbar({
          message: messages[message] || 'Número de telefone inválido.',
          status: 'error',
        }));

        setIsLoading(false);
        return;
      }

      renderRecaptcha();

      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        window.recaptchaVerifier,
      );

      window.confirmationResult = confirmationResult;

      setCodeWasSent(true);
      setIsLoading(false);
    } catch (err) {
      const messages = {
        'auth/invalid-phone-number': 'Número de telefone inválido.',
      };

      dispatch(setSnackbar({
        message: messages[err.code] || 'Não foi possível enviar o código de verificação.',
        status: 'error',
      }));
      setIsLoading(false);
    }
  };

  const confirmCode = async (code) => {
    try {
      setIsLoading(true);

      const result = await window.confirmationResult.confirm(code);

      if (result._tokenResponse.phoneNumber) {
        const { providerUserInfo, providerId, idToken } = loginProviderData;

        await loginWithFirebase(
          idToken,
          providerUserInfo,
          providerId,
          result._tokenResponse.phoneNumber,
        );
      }

      deleteUser(result.user);
      setIsLoading(false);
    } catch (error) {
      const messages = {
        'auth/invalid-verification-code': 'Código de verificação inválido',
      };

      dispatch(setSnackbar({
        message: messages[error.code] || 'Não foi possível confirmar o código de verificação.',
        status: 'error',
      }));

      setIsLoading(false);
    }
  };

  const values = useMemo(() => ({
    loginPopup,
    openRegisterModal,
    openConfirmationModal,
    setOpenConfirmationModal,
    setCodeWasSent,
    isLoading,
    confirmCode,
    sentVerificationCode,
    codeWasSent,
  }), [
    loginPopup,
    openRegisterModal,
    openConfirmationModal,
  ]);

  return (
    <AuthContext.Provider value={values}>
      {children}
    </AuthContext.Provider>
  );
}
