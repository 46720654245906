import { useFormik } from 'formik';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { createCategory } from 'services/providers/pandlr.provider';
import { setSnackbar } from 'redux/slicers';
import { validateCreateGroupCategory } from 'utils/yupValidations';
import { CategoryCard } from 'components/Cards';
import { ControlledTextField, ControlledTextarea } from './ControlledFormComponents';
import * as S from './styles';
import { ControlledColorInput } from './ControlledFormComponents/ControlledFormComponents';

export function CreateCategoryForm() {
  const { forumDetails } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleSubmit = async (data) => {
    const { name, description, color } = data;
    const res = await createCategory({ name, description, color }, forumDetails.id);

    if (res.success) {
      return dispatch(setSnackbar({
        message: 'Categoria criada com sucesso!',
        status: 'success',
      }));
    }

    return dispatch(setSnackbar({
      message: 'Não foi possível criar a categoria.',
      status: 'error',
    }));
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      color: '#757575',
    },
    validationSchema: validateCreateGroupCategory,
    onSubmit: handleSubmit,
  });

  return (
    <Box
      width="100%"
      margin="0 auto"
      mt={{ xs: 2, sm: 6 }}
      p={{ xs: 2, sm: 4 }}
      bgcolor="background.level2"
      borderRadius="5px"
      boxShadow={(theme) => theme.shadows[1]}
    >
      <S.Form onSubmit={formik.handleSubmit}>
        <S.SimpleText variant="h6">Criar uma nova categoria</S.SimpleText>
        <CategoryCard
          category={formik.values}
          sx={{
            fontSize: '14px',
            my: 2,
          }}
        />
        <S.FormContainer>
          <S.InputGrid>
            <ControlledTextField
              name="name"
              label="Nome da categoria"
              formik={formik}
              fullWidth
            />
          </S.InputGrid>
          <S.InputGrid>
            <ControlledColorInput
              name="color"
              label="Cor da categoria"
              formik={formik}
              fullWidth
            />
          </S.InputGrid>
          <S.Input>
            <ControlledTextarea
              name="description"
              label="Descrição da categoria"
              formik={formik}
              fullWidth
            />
          </S.Input>
          <S.FormActions>
            <S.LoadingButton
              type="submit"
              endIcon={<div />}
              loading={formik.isSubmitting}
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
            >
              Criar categoria
            </S.LoadingButton>
          </S.FormActions>
        </S.FormContainer>
      </S.Form>
    </Box>
  );
}
