import { CategoryDetails, Header } from 'components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getCategories } from 'services/providers/pandlr.provider';
import { CategoryTopics } from 'components/Categories';
import * as S from './styles';

export function Category() {
  const stateLocation = useLocation().state;
  const { categoryId, groupId, slug } = useParams();
  const navigate = useNavigate();

  const [category, setCategory] = useState(stateLocation);
  const [categories, setCategories] = useState([]);

  const fetchCategory = async () => {
    const res = await getCategories(groupId);

    if (res.success) {
      const categoryFound = res.data.find((cat) => cat.id === Number(categoryId));

      if (!categoryFound) {
        return navigate('/404');
      }
      setCategories(res.data);
      if (!category) {
        return setCategory(categoryFound);
      }
    }

    return null;
  };

  useEffect(() => {
    fetchCategory();
  }, [slug]);

  const handleSelectCategory = (value) => {
    if (!value) return;
    const categoryFound = categories.find((cat) => cat.id === Number(value.id));
    setCategory(categoryFound);
    navigate(`/forum/${categoryFound.group.slug}/category/${groupId}/${categoryFound.slug}/${value.id}`);
  };

  return (
    <>
      <Header />
      <S.CategoryContainer>
        <CategoryDetails
          category={category}
          categories={categories}
          handleSelectCategory={handleSelectCategory}
          setCategory={setCategory}
        />
        <CategoryTopics
          category={category}
        />
      </S.CategoryContainer>
    </>
  );
}
