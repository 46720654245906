import React from 'react';

import { Divider } from '@mui/material';
import { GifOptions } from 'components/Menus';
import { useNavigate } from 'react-router-dom';
import { CardContainer } from 'common/CardContainer';
import { handleQuote } from 'utils/topic';
import { calculateTimeDifference } from 'utils/timeDifference';
import { useHtmlParse } from 'hooks';
import { ReplyMenu } from './ReplyMenu';
import * as S from './styles';

export function ReplyCard({ reply }) {
  const navigate = useNavigate();

  const AVATAR_URL = `${process.env.REACT_APP_IMAGE_URL}${reply.replyAuthor?.avatar}`;
  const TIME_SINCE = reply?.updatedAt ? calculateTimeDifference(reply?.updatedAt) : '';

  const { parsedContent, showGifOptions, setShowGifOptions } = useHtmlParse(reply.content);

  return (
    <>
      <GifOptions
        showGifOptions={showGifOptions}
        setShowGifOptions={setShowGifOptions}
      />
      <CardContainer>
        <S.CardHeaderContainer
          avatar={
            <S.CardHeaderAvatar src={AVATAR_URL} user={reply.replyAuthor} />
          }
          action={(
            <S.CardHeaderAction>
              <S.Quote
                onClick={() => handleQuote(reply.replyAuthor?.username, reply.content)}
                username={reply.replyAuthor?.username}
              />
              <ReplyMenu reply={reply} />
            </S.CardHeaderAction>
          )}
          title={(
            <S.CardHeaderTitle
              onClick={() => navigate(`/user/${reply.replyAuthor?.username}`)}
            >
              {reply.replyAuthor?.name}
            </S.CardHeaderTitle>
          )}
          subheader={
            <S.CardHeaderSubheader>{TIME_SINCE}</S.CardHeaderSubheader>
          }
        />
        <S.CardContentContainer>
          <S.CardPostContent id={`post-${reply.id}`}>
            {parsedContent}
          </S.CardPostContent>
        </S.CardContentContainer>
        <Divider variant="middle" />
      </CardContainer>
    </>
  );
}
