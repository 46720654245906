import { TextField } from '@mui/material';

export function BanModalFormContainer({ children, onSubmit }) {
  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 16,
        padding: '0 8px',
      }}
      onSubmit={onSubmit}
    >
      {children}
    </form>
  );
}

export function BanReasonInput({ ...props }) {
  return (
    <TextField
      sx={{
        borderRadius: '5px',
        width: { sm: '100%', md: '600px' },
      }}
      multiline
      id="reason"
      placeholder="Motivo do banimento"
      required
      rows={3}
      {...props}
    />
  );
}
