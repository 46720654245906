import { GifOptions } from 'components/Menus';
import { CardContainer } from 'common/CardContainer';
import { useHtmlParse } from 'hooks';
import { calculateTimeDifference } from 'utils/timeDifference';
import * as S from '../Topic/styles';

export function TopicLogCard({ topic }) {
  const { showGifOptions, setShowGifOptions, parsedContent } = useHtmlParse(
    topic.content,
  );

  const AVATAR_URL = `${process.env.REACT_APP_IMAGE_URL}${topic?.topicAuthor?.avatar}`;
  const TIME_SINCE = topic?.createdAt ? calculateTimeDifference(topic?.createdAt) : '';

  return (
    <>
      <GifOptions
        showGifOptions={showGifOptions}
        setShowGifOptions={setShowGifOptions}
      />
      <CardContainer>
        <S.CardHeaderContainer
          avatar={(
            <S.CardHeaderAvatar
              src={AVATAR_URL}
              user={topic?.topicAuthor}
            />
            )}
          title={(
            <S.CardHeaderTitle>
              {topic?.topicAuthor?.name}
            </S.CardHeaderTitle>
            )}
          subheader={(
            <S.CardHeaderSubtitle>
              {TIME_SINCE}
            </S.CardHeaderSubtitle>
            )}
        />
        <S.CardContentContainer>
          <S.CardPostContent id={`post-${topic.id}`}>
            {parsedContent}
          </S.CardPostContent>
        </S.CardContentContainer>
      </CardContainer>
    </>
  );
}
