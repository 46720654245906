import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState(true)('autoScroll');

export const autoScrollSlice = createSlice({
  name: 'autoScroll',
  initialState,
  reducers: {
    setAutoScroll: (state, { payload }) => {
      state = payload;
      return state;
    },
  },
});

export const { setAutoScroll } = autoScrollSlice.actions;
