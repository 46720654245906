import { SaveAs } from '@mui/icons-material';
import {
  Typography, Box, Checkbox, FormControlLabel, FormGroup,
} from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { setWidgetPreferences, setSnackbar } from 'redux/slicers';
import * as S from './styles';

export function WidgetPreferences() {
  const { widgetPreferences } = useSelector((state) => state.userPreferences);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      scrollToFooter: widgetPreferences?.scrollToFooter || false,
      mentions: widgetPreferences?.mentions || false,
      nightMode: widgetPreferences?.nightMode || false,
      autoScroll: widgetPreferences?.autoScroll || false,
      fastReply: widgetPreferences?.fastReply || false,
      infiniteScroll: widgetPreferences?.infiniteScroll || false,
      all: false,
    },
    onSubmit: (values) => {
      dispatch(setWidgetPreferences(values));
      dispatch(setSnackbar({
        message: 'Preferências salvas com sucesso!',
        status: 'success',
      }));
      formik.setSubmitting(false);
    },
  });

  const widgets = [
    { id: 'scrollToFooter', label: 'Ir para o rodapé' },
    { id: 'mentions', label: 'Menções' },
    { id: 'nightMode', label: 'Modo noturno' },
    { id: 'autoScroll', label: 'Rolagem automática' },
    { id: 'fastReply', label: 'Resposta rápida' },
    { id: 'infiniteScroll', label: 'Rolagem infinita' },
  ];

  return (
    <S.WidgetPreferencesContainer>
      <S.Form onSubmit={formik.handleSubmit}>
        <Box>
          <Typography variant="h6" component="h2">
            Escolha os widgets que deseja fixar na barra superior
          </Typography>
        </Box>
        <FormGroup>
          <FormControlLabel
            label="Marcar todos"
            control={(
              <Checkbox
                checked={Object.values(formik.values).every((value) => value)}
                onChange={(e) => {
                  const { checked } = e.target;
                  formik.setValues(widgets.reduce((acc, widget) => ({
                    ...acc,
                    [widget.id]: checked,
                  }), {}));
                }}
                name="all"
              />
            )}
          />
          {
            widgets.map((widget) => (
              <FormControlLabel
                key={widget.id}
                control={(
                  <Checkbox
                    checked={formik.values[widget.id]}
                    onChange={formik.handleChange}
                    name={widget.id}
                  />
                )}
                label={widget.label}
              />
            ))
          }
        </FormGroup>
        <Box sx={{
          mt: { xs: 2, sm: 3 },
        }}
        >
          <S.FormActions>
            <S.LoadingButton
              type="submit"
              loading={formik.isSubmitting}
              endIcon={<SaveAs />}
            >
              Salvar
            </S.LoadingButton>
          </S.FormActions>
        </Box>
      </S.Form>
    </S.WidgetPreferencesContainer>
  );
}
