import { NavigateNext } from '@mui/icons-material';
import {
  Box, Breadcrumbs, Link, TextField, Typography,
} from '@mui/material';
import { SelectCategory } from 'components/Forms/SelectCategory';
import { EditCategoryModal } from 'components/Modals';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

export function CategoryDetails({
  category, categories, handleSelectCategory, setCategory,
}) {
  const { forumDetails } = useSelector((state) => state);
  const IS_MODERATOR = forumDetails.userCredentials?.isModerator;

  const breadCrumbs = [
    <Box key={1}>fórum</Box>,
    <Link
      key={2}
      to={`${process.env.REACT_APP_WEB_URL}/forum/${category?.group?.slug}`}
      component={RouterLink}
      underline="none"
      color="inherit"
    >
      {category?.group?.slug}
    </Link>,
    <Box key={3}>categoria</Box>,
    <SelectCategory
      key={4}
      categories={categories}
      category={category}
      setCategory={handleSelectCategory}
      renderInput={(props) => (
        <TextField
          {...props}
          error={false}
          helperText={false && 'Selecione uma categoria'}
          variant="standard"
          sx={{
            minWidth: {
              xs: '155px',
              sm: '200px',
            },
          }}
        />
      )}
    />,
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        gap: 2,
        mb: 2,
        mt: { xs: 2, sm: 0 },
      }}
    >
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNext fontSize="small" />}
        sx={{
          '& .MuiBreadcrumbs-separator': {
            ml: { xs: 0, sm: 0.5 },
            mr: { xs: 0, sm: 0.5 },
          },
        }}
      >
        { breadCrumbs}
      </Breadcrumbs>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            position: 'relative',
          }}
        >
          <Typography
            variant="h5"
          >
            {category?.name}
          </Typography>
          { IS_MODERATOR && (
            <EditCategoryModal
              category={category}
              setCategory={setCategory}
            />
          )}
        </Box>

        <Typography
          variant="body1"
          sx={{
            color: 'text.secondary',
          }}
        >
          {category?.description}
        </Typography>
      </Box>
    </Box>
  );
}
