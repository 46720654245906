import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getForumBans, unbanForumUser } from 'services/providers/pandlr.provider';
import {
  Checkbox, FormControlLabel, List, TextField, Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { setSnackbar } from 'redux/slicers';
import { ForumBansOrderMenu } from 'components/Menus';
import { ForumBanCard, ForumBanCardSkeleton } from 'components/Cards';
import * as S from './styles';

export function ForumBansList() {
  const [bans, setBans] = React.useState([]);
  const [helperText, setHelperText] = useState('');
  const { forumId } = useParams();
  const [pagination, setPagination] = useState({ currentPage: 0 });
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState('DESC');
  const [isLoading, setIsLoading] = useState(true);
  const forumDetails = useSelector((state) => state.forumDetails);
  const [bannedIndefinitely, setBannedIndefinitely] = useState(false);

  const HAS_MORE = pagination.currentPage !== 0;

  const dispatch = useDispatch();

  const fetchForumBans = async (page, query, orderBy, indefinitely) => {
    setIsLoading(true);
    const res = await getForumBans(forumId, page, query, orderBy, indefinitely);
    if (res.success) {
      const { items, currentPage } = res.data;
      setBans((prevBans) => [...prevBans, ...items]);
      setPagination({ currentPage });
      if (items.length < 15) {
        setPagination({ currentPage: 0 });
      }
    }
    setHelperText(res.data.message);
    setIsLoading(false);
  };

  const handleUnban = async (ban) => {
    const res = await unbanForumUser({
      groupId: forumId,
      userId: ban.userId,
      groupSlug: forumDetails.slug,
    });
    if (res.success) {
      dispatch(setSnackbar({
        message: res.data.message,
        status: 'success',
      }));
      setBans([]);
      return fetchForumBans(1, search, order, bannedIndefinitely);
    }
    return dispatch(setSnackbar({
      message: res.data.message,
      status: 'error',
    }));
  };

  useEffect(() => {
    fetchForumBans(1, search, order, bannedIndefinitely);

    return () => {
      setBans([]);
      setPagination({});
    };
  }, []);

  const handleSubmit = (e) => {
    e?.preventDefault();
    setBans([]);
    fetchForumBans(1, search, order, bannedIndefinitely);
  };

  const handleChangeOrder = (value) => {
    setOrder(value);
    setBans([]);
    fetchForumBans(1, search, value, bannedIndefinitely);
  };

  const handleBannedIndefinitely = (value) => {
    setBannedIndefinitely(value);
    setBans([]);
    fetchForumBans(1, search, order, value);
  };

  return (
    <>
      <Typography variant="h5" sx={{ m: 2 }}>
        Banidos
      </Typography>

      <S.ForumBansListFilterContainer>
        <S.ForumBansFilterForm
          onSubmit={handleSubmit}
        >
          <TextField
            sx={{ flexGrow: 1 }}
            label="Pesquisar"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <S.ForumBansSearchButton />
        </S.ForumBansFilterForm>
        <ForumBansOrderMenu handleChangeOrder={handleChangeOrder} />
        <FormControlLabel
          sx={{ width: '100%' }}
          control={(
            <Checkbox
              id="bannedIndefinitely"
              name="bannedIndefinitely"
              checked={bannedIndefinitely}
              onChange={(e, checked) => handleBannedIndefinitely(
                checked,
              )}
            />
          )}
          label="Banidos por tempo indeterminado"
        />
      </S.ForumBansListFilterContainer>
      <S.ForumBansListContainer>
        <List>
          <InfiniteScroll
            dataLength={bans.length}
            pageStart={0}
            next={() => fetchForumBans(
              pagination.currentPage + 1,
              search,
              order,
              bannedIndefinitely,
            )}
            useWindow
            hasMore={HAS_MORE}
            loader={Array(5).fill().map(() => (
              <ForumBanCardSkeleton key={uuidv4()} />
            ))}
          >
            {isLoading && bans.length === 0 && Array(5).fill().map(() => (
              <ForumBanCardSkeleton key={uuidv4()} />
            ))}
            {bans.map((ban) => (
              <ForumBanCard key={uuidv4()} ban={ban} handleUnban={handleUnban} />
            ))}
          </InfiniteScroll>
          {bans.length === 0 && !isLoading && (
            <S.ForumBansHelperText helperText={helperText} />
          )}
        </List>
      </S.ForumBansListContainer>
    </>
  );
}
