import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setGifOptions, setAddToGifPocket, setIsEditorOpen } from 'redux/slicers';
import { ContentCopy, Cloud } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import {
  Paper, MenuItem, ListItemText, ListItemIcon,
} from '@mui/material';

export function GifOptions({ showGifOptions, setShowGifOptions }) {
  const dispatch = useDispatch();

  const copyImageToClipboard = () => {
    navigator.clipboard.writeText(showGifOptions.image);
    setShowGifOptions((prevState) => ({ ...prevState, show: false }));
  };

  const style = {
    position: 'absolute',
    top: `${showGifOptions?.positions.top}px`,
    left: `${showGifOptions?.positions.left}px`,
    zIndex: 1,
  };

  const handleClickOutside = (e) => {
    if (showGifOptions?.element?.contains(e.target)) return;
    if (showGifOptions?.show) setShowGifOptions((prevState) => ({ ...prevState, show: false }));
  };

  const handleAddGifToGifPocket = async () => {
    dispatch(setAddToGifPocket(showGifOptions.image));
    setShowGifOptions((prevState) => ({ ...prevState, show: false }));
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  return (
    showGifOptions?.show && (
      <div className="git-options" style={style}>
        <Paper sx={{ width: 150, maxWidth: '100%' }}>
          <MenuItem>
            <ListItemIcon>
              <ContentCopy fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={copyImageToClipboard}>Copiar</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <Cloud fontSize="small" />
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                dispatch(setIsEditorOpen(true));
                dispatch(setGifOptions({ image: showGifOptions.image }));
                setShowGifOptions((prevState) => ({ ...prevState, show: false }));
              }}
            >
              Repostar
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <SaveIcon sx={{ mr: 2 }} fontSize="small" />
            <ListItemText
              onClick={handleAddGifToGifPocket}
            >
              GIF Pocket
            </ListItemText>
          </MenuItem>
        </Paper>
      </div>
    )
  );
}
