import {
  Button, Checkbox, FormControlLabel, ListItemIcon, MenuItem,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { forumBanUser } from 'services/providers/pandlr.provider';
import BlockIcon from '@mui/icons-material/Block';
import { setSnackbar } from 'redux/slicers';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import * as S from './styles';

export function BanModal({ userId, handleClose, open }) {
  const dispatch = useDispatch();

  const { groupId, slug } = useSelector((state) => state.topicDetails);

  const formik = useFormik({
    initialValues: {
      banExpiration: '',
      reason: '',
      bannedIndefinitely: false,
    },
    onSubmit: async (values) => {
      const newBan = {
        groupId,
        userId,
        reason: values.reason,
        groupSlug: slug,
        banExpiration: values.bannedIndefinitely ? null : dayjs(values.banExpiration).toString(),
        bannedIndefinitely: values.bannedIndefinitely,
      };
      const response = await forumBanUser(newBan);
      handleClose();
      if (response.success) {
        return dispatch(setSnackbar({
          open: true,
          message: 'Usuário banido',
          type: 'success',
        }));
      }
      return dispatch(setSnackbar({
        open: true,
        message: 'Erro ao banir usuário',
        type: 'error',
      }));
    },
  });

  return (
    <S.Modal
      open={open}
      handleClose={handleClose}
      modalName={S.MODAL_STYLES.BAN_USER_MODAL}
      title="Banir Usuário"
    >
      <S.BanModalFormContainer onSubmit={formik.handleSubmit}>
        <S.BanReasonInput
          label="Motivo do banimento"
          name="reason"
          onChange={formik.handleChange}
          value={formik.values.reason}
        />
        <FormControlLabel
          control={(
            <Checkbox
              id="bannedIndefinitely"
              name="bannedIndefinitely"
              checked={formik.values.bannedIndefinitely}
              onChange={formik.handleChange}
            />
      )}
          label="Banir por tempo indeterminado"
        />
        <DateTimePicker
          label="Data de expiração"
          name="banExpiration"
          value={formik.values.banExpiration}
          onChange={(value) => formik.setFieldValue('banExpiration', value)}
          disabled={formik.values.bannedIndefinitely}
          disablePast
          required={!formik.values.bannedIndefinitely}
        />
        <Button
          id="submit"
          type="submit"
          variant="contained"
          sx={{ alignSelf: 'flex-end' }}
          disabled={formik.isSubmitting}
        >
          Banir
        </Button>
      </S.BanModalFormContainer>
    </S.Modal>
  );
}

export function BanMenuItem({ handleOpen }) {
  return (
    <MenuItem onClick={handleOpen}>
      <ListItemIcon>
        <BlockIcon fontSize="small" />
      </ListItemIcon>
      Banir
    </MenuItem>
  );
}
