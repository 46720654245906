import { Send } from '@mui/icons-material';
import {
  Box, Button, Fab, TextField,
} from '@mui/material';

export function TopicNewReplyContainer({ children }) {
  return (
    <Box
      sx={{
        position: 'sticky',
        width: '100%',
        bottom: 0,
      }}
    >
      {children}
    </Box>
  );
}

export function ClosedTopicMessage({ children }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <p>{children}</p>
    </Box>
  );
}

export function DisabledTextEditorContainer({ children }) {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: 50,
        borderRadius: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </Box>
  );
}

export function DisabledTextEditor({ onClick, darkMode }) {
  return (
    <TextField
      type="text"
      placeholder="Responder..."
      sx={{
        width: '100%',
        backgroundColor: darkMode.active ? '#222f3e' : 'background.paper',
      }}
      onClick={onClick}
    />
  );
}

export function Form({ children, onSubmit, textEditor }) {
  return (
    <form
      onSubmit={onSubmit}
      style={{ display: textEditor.isEditorOpen ? 'block' : 'none' }}
      id="reply-form"
    >
      {children}
    </form>
  );
}

export function CloseEditorButton({ children, onClick, darkMode }) {
  return (
    <Button
      variant="link"
      sx={{
        position: 'absolute',
        right: 15,
        bottom: 8,
        backgroundColor: 'rgba(0, 0, 0, 0)',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        },
        color: darkMode.active ? 'primary.contrastText' : 'primary',
        fontWeight: 'bold',
        zIndex: 1,
        px: 2,
      }}
      size="small"
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

export function SendButton({ onClick, type }) {
  return (
    <Fab
      color="primary"
      size="small"
      sx={{
        position: 'absolute',
        right: 15,
        top: 10,
      }}
      id={type}
      type={type}
      onClick={onClick}
    >
      <Send />
    </Fab>
  );
}
