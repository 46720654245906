import { Card } from '@mui/material';

export function CardContainer({ children, ...props }) {
  return (
    <Card
      sx={{
        backgroundColor: 'background.level2',
        backgroundImage: 'none',
        borderRadius: '0px',
        marginBottom: 0,
        boxShadow: 'none',
      }}
      {...props}
    >
      {children}
    </Card>
  );
}
