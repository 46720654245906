import React from 'react';
import { SaveAs } from '@mui/icons-material';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import {
  createNewForum,
  updateForumDetails,
  uploadForumBanner,
  uploadForumImage,
} from 'services/providers/pandlr.provider';
import {
  AvatarContainer,
  BannerImage,
  Description,
  ForumImage,
  MainHeaderContainer,
  MainHeaderDetailsContainer,
  MainHeaderDetailsContent,
  MainHeaderImagesContainer,
  MainHeaderName,
  MainHeaderNameMobile,
} from 'styles/MainHeader.styles';
import { validateCreateForumForm } from 'utils/yupValidations';
import { useUploadImages } from 'hooks/useUploadImages';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/slicers';
import * as S from './styles';
import {
  ControlledCheckbox,
  ControlledTextField,
  ControlledTextarea,
} from './ControlledFormComponents';

export function CreateForumForm() {
  const INITIAL_STATE = {
    name: '',
    description: '',
    avatar: '',
    background: '',
    avatarLink: '',
    needsApproval: false,
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { uploadImages } = useUploadImages();

  const onSubmit = async (data, formik) => {
    const {
      name, description, avatarLink, needsApproval,
    } = data;

    const createdForum = await createNewForum({
      name,
      description,
      avatarLink,
      needsApproval,
    });

    if (!createdForum.success) {
      const createForumErrorMessages = {
        'forum-name-already-in-use': () => {
          formik.setErrors({
            name: 'O nome do fórum já está em uso',
          });
          return;
        },
      };
      createForumErrorMessages[createdForum.data.message]?.();

      dispatch(setSnackbar({ message: createdForum.data.message, status: 'error' }));
      return;
    }

    const images = [
      {
        name: 'avatar',
        file: data.avatar,
        cb: uploadForumImage,
        id: createdForum.data.id,
        slug: createdForum.data.slug,
      },
      {
        name: 'background',
        file: data.background,
        cb: uploadForumBanner,
        id: createdForum.data.id,
        slug: createdForum.data.slug,
      },
    ];

    const { uploadedImages, uploadErrors } = await uploadImages(images);

    if (uploadErrors.avatar || uploadErrors.background) {
      formik.setErrors({
        avatar: uploadErrors.avatar,
        background: uploadErrors.background,
      });
    }

    const result = await updateForumDetails(
      {
        groupImage: uploadedImages.avatar.path300,
        groupImageThumb: uploadedImages.avatar.path,
        groupBackgroundImage: uploadedImages.background.path,
      },
      createdForum.data.id,
    );

    if (!result.success) {
      dispatch(setSnackbar({ message: result.message, status: 'error' }));
      return;
    }

    dispatch(setSnackbar({ message: 'Fórum criado com sucesso', status: 'success' }));

    navigate(`/forum/${createdForum.data.slug}`);
  };

  const formik = useFormik({
    initialValues: INITIAL_STATE,
    validationSchema: validateCreateForumForm,
    onSubmit: async (data) => onSubmit(data, formik),
  });

  const AVATAR_URL = formik.values.avatar && URL.createObjectURL(formik.values.avatar);
  const BANNER_URL = formik.values.background && URL.createObjectURL(formik.values.background);

  return (
    <>
      <MainHeaderContainer>
        <MainHeaderImagesContainer>
          <BannerImage src={BANNER_URL} />
          <AvatarContainer>
            <Link to={AVATAR_URL}>
              <ForumImage src={AVATAR_URL} />
            </Link>
          </AvatarContainer>
          <MainHeaderName>{formik.values.name}</MainHeaderName>
        </MainHeaderImagesContainer>
        <MainHeaderDetailsContainer>
          <MainHeaderDetailsContent>
            <MainHeaderNameMobile>
              {formik.values.name}
            </MainHeaderNameMobile>
          </MainHeaderDetailsContent>
          <Description description={formik.values.description} />
        </MainHeaderDetailsContainer>
      </MainHeaderContainer>

      <S.UpdateUserFormContainer>
        <S.Form onSubmit={formik.handleSubmit}>
          <S.FormContainer>
            <S.Input>
              <ControlledTextField
                label="Nome do Forum"
                fullWidth
                name="name"
                formik={formik}
              />
            </S.Input>

            <S.Input>
              <ControlledTextarea
                fullWidth
                label="Descrição"
                name="description"
                formik={formik}
              />
            </S.Input>

            <S.Input>
              <S.UploadImageButton
                label="Carregue uma imagem para o avatar do Fórum"
                name="avatar"
                formik={formik}
              />
            </S.Input>

            <S.Input>
              <ControlledTextField
                label="Link para um tópico relevante do Fórum"
                fullWidth
                name="avatarLink"
                formik={formik}
              />
            </S.Input>

            <S.Input>
              <S.UploadImageButton
                label="Carregue uma imagem para o banner do Fórum"
                name="background"
                formik={formik}
              />
            </S.Input>

            <S.Input>
              <ControlledCheckbox
                name="needsApproval"
                label={(
                  <S.SimpleText>
                    Fórum privado ( Requer aprovação para entrar )
                  </S.SimpleText>
                )}
                formik={formik}
              />
            </S.Input>

            <S.FormActions>
              <S.LoadingButton
                disabled={
                  formik.isSubmitting || !formik.dirty || !formik.isValid
                }
                type="submit"
                loading={formik.isSubmitting}
                endIcon={<SaveAs />}
              >
                Criar
              </S.LoadingButton>
            </S.FormActions>
          </S.FormContainer>
        </S.Form>
      </S.UpdateUserFormContainer>
    </>
  );
}
