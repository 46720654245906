import { Footer, ForumDeletedTopics, Header } from 'components';
import * as S from './styles';

export function DeletedTopics() {
  return (
    <>
      <Header />
      <S.DeletedTopicsContainer>
        <ForumDeletedTopics />
      </S.DeletedTopicsContainer>
      <Footer />
    </>
  );
}
