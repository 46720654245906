import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState([])('gifPocket');

export const gifPocketSlice = createSlice({
  name: 'gifPocket',
  initialState,
  reducers: {
    setGifPocket: (state, { payload }) => {
      state = payload;
      return state;
    },
  },
});

export const { setGifPocket } = gifPocketSlice.actions;
