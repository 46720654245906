import { Box, Divider, Typography } from '@mui/material';
import { AdminLogDetailsModal } from 'components/Modals';
import React from 'react';
import { formatDate } from 'utils/date';

export function AdminLogCard({ log }) {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: { xs: 'flex-start', sm: 'center' },
          p: { xs: 1, sm: 2 },
        }}
      >
        <Typography
          variant="subtitle1"
        >
          {log.message}
        </Typography>
        <Typography
          sx={{
            minWidth: '210px',
            color: 'text.secondary',
          }}
        >
          {formatDate(log.createdAt)}
          <AdminLogDetailsModal log={log} />
        </Typography>
      </Box>
      <Divider variant="middle" />
    </Box>
  );
}
