import { useState } from 'react';
import { paperProps } from 'utils/paperProps';
import {
  Menu, MenuItem, ListItemIcon, IconButton,
} from '@mui/material';
import { RestoreFromTrash, MoreVert } from '@mui/icons-material';
import { restoreDeletedTopic } from 'services/providers/pandlr.provider';
import { useNavigate } from 'react-router-dom';

export function DeletedTopicMenu({ topic }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleRestoreTopic = async () => {
    const res = await restoreDeletedTopic(topic.id, topic.groupId);
    if (res.success) {
      handleClose();
      navigate(`/forum/${topic.category?.group?.slug}/topic/${topic.slug}/${topic.id}`, {
        state: { topic },
      });
    }
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={paperProps}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleRestoreTopic}>
          <ListItemIcon>
            <RestoreFromTrash />
          </ListItemIcon>
          <span>Restaurar Tópico</span>
        </MenuItem>
      </Menu>
    </>
  );
}
