import {
  useRef, useEffect, useState, useLayoutEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { getDeletedTopics } from 'services/providers/pandlr.provider';
import { Box, Typography } from '@mui/material';

import { useWindowVirtualizer } from '@tanstack/react-virtual';
import { ListContainer, ListContent } from 'components/Topic/styles';
import * as S from './styles';
import { DeletedForumTopicCard } from './DeletedForumTopicCard';
import { ForumTopicsSkeleton } from './ForumTopicsSkeleton';

export function ForumDeletedTopics() {
  const forumDetails = useSelector((state) => state.forumDetails);
  const [topics, setTopics] = useState([]);

  const { id } = forumDetails;

  const paginationRef = useRef({
    currentPage: 0,
    hasMore: true,
  });

  const isLoading = useRef(false);
  const listRef = useRef(null);
  const parentOffsetRef = useRef(0);

  const virtualizer = useWindowVirtualizer({
    count: topics.length,
    estimateSize: () => 5000,
    scrollMargin: parentOffsetRef.current,
    overscan: 5,
  });

  const virtualItems = virtualizer.getVirtualItems();

  useLayoutEffect(() => {
    parentOffsetRef.current = listRef.current?.offsetTop ?? 0;
  }, [virtualItems]);

  const fetchGroupTopics = async (page) => {
    if (!id) return;
    const res = await getDeletedTopics(id, page);
    if (res.success) {
      const { items, ...paginationOptions } = res.data;
      paginationRef.current = paginationOptions;
      setTopics((prev) => [...prev, ...items]);
    }
  };

  const fetchNextPage = async () => {
    const [lastItem] = [...virtualizer.getVirtualItems()].reverse();
    if (!lastItem || isLoading.current) return;
    if (lastItem.index >= topics.length - 1
        && paginationRef.current.hasMore) {
      isLoading.current = true;
      await fetchGroupTopics(paginationRef.current.currentPage + 1);
      isLoading.current = false;
    }
  };

  useEffect(() => {
    fetchNextPage();
  }, [
    isLoading.current,
    paginationRef,
    virtualItems,
    topics,
  ]);

  useEffect(() => {
    setTopics([]);
    fetchGroupTopics(paginationRef.current.currentPage || 1);
    return () => {
      setTopics([]);
      paginationRef.current = {
        currentPage: 1,
        hasMore: true,
      };
    };
  }, []);

  return (
    <S.ForumTopicsContainer>
      <S.ForumTopicsHeader>
        <Typography variant="h5">Tópicos Deletados</Typography>
      </S.ForumTopicsHeader>
      <ListContainer
        ref={listRef}
        virtualizer={virtualizer}
      >
        <ListContent items={virtualItems} virtualizer={virtualizer}>
          {virtualItems?.map((item) => (
            <Box
              key={item.key}
              data-index={item.index}
              ref={virtualizer.measureElement}
              sx={{ '*': { listStyle: 'none' } }}
            >
              <DeletedForumTopicCard
                topic={topics[item.index]}
                groupSlug={topics[item.index].group?.slug}
              />
            </Box>
          ))}
          {isLoading.current && (
          <Box sx={{ bgcolor: 'background.level2' }}>
            <ForumTopicsSkeleton />
          </Box>
          )}
        </ListContent>
      </ListContainer>
    </S.ForumTopicsContainer>
  );
}
