import { Box, Button, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/slicers';
import { updateCategory } from 'services/providers/pandlr.provider';
import { validateCreateGroupCategory } from 'utils/yupValidations';
import { CategoryCard } from 'components/Cards';

import {
  ControlledTextField,
  ControlledTextarea,
} from './ControlledFormComponents';
import * as S from './styles';
import { ControlledColorInput } from './ControlledFormComponents/ControlledFormComponents';

export function EditCategoryForm({ category, handleClose, setCategory }) {
  const { forumDetails } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleSubmit = async (data) => {
    const { name, description, color } = data;
    const res = await updateCategory(
      { name, description, color },
      forumDetails.id,
      category.id,
    );

    if (res.success) {
      setCategory({
        ...category,
        name,
        description,
        color,
      });

      handleClose();

      return dispatch(
        setSnackbar({
          message: 'Categoria criada com sucesso!',
          status: 'success',
        }),
      );
    }

    return dispatch(
      setSnackbar({
        message: 'Ocorreu um erro ao editar a categoria.',
        status: 'error',
      }),
    );
  };

  const formik = useFormik({
    initialValues: category,
    validationSchema: validateCreateGroupCategory,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <Box width="100%">
      <S.Form onSubmit={formik.handleSubmit}>
        <S.SimpleText variant="h6">Editar categoria</S.SimpleText>
        <CategoryCard
          category={{
            ...category,
            color: formik.values.color,
          }}
          sx={{
            fontSize: '14px',
            my: 2,
          }}
        />
        <S.FormContainer>
          <S.InputGrid>
            <ControlledTextField
              name="name"
              label="Nome da categoria"
              formik={formik}
              fullWidth
            />
          </S.InputGrid>
          <S.InputGrid>
            <ControlledColorInput
              name="color"
              label="Cor da categoria"
              formik={formik}
              fullWidth
            />
          </S.InputGrid>
          <S.Input>
            <ControlledTextarea
              name="description"
              label="Descrição da categoria"
              formik={formik}
              fullWidth
            />
          </S.Input>
          <Grid item container xs={12} gap={2}>
            <Grid item xs={12} sm={5.75}>
              <S.LoadingButton
                type="submit"
                size="medium"
                endIcon={<div />}
                loading={formik.isSubmitting}
                disabled={
                  formik.isSubmitting || !formik.isValid || !formik.dirty
                }
                fullWidth
              >
                Salvar
              </S.LoadingButton>
            </Grid>
            <Grid item xs={12} sm={5.75}>
              <Button
                variant="outlined"
                color="error"
                onClick={handleClose}
                fullWidth
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </S.FormContainer>
      </S.Form>
    </Box>
  );
}
