import {
  Box, Divider, AppBar as MuiAppBar, useTheme,
} from '@mui/material';
import { Container } from 'common/Container';

export function HeaderContainer({ children }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: '100%',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <MuiAppBar
        position="fixed"
        sx={{
          backgroundColor: theme.palette.background.topbar,
        }}
        elevation={0}
      >
        {children}
        <Divider />
      </MuiAppBar>
    </Box>
  );
}

export function HeaderContent({ children }) {
  return (
    <Container paddingY={{ xs: 1 / 2, sm: 1 }} maxWidth={{ md: '980px' }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        {children}
      </Box>
    </Container>
  );
}

export function HeaderMenuContainer({ children }) {
  return (
    <Box display="flex" alignItems="center">
      {children}
    </Box>
  );
}
