import { Button, IconButton, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGifPocket, setSnackbar } from 'redux/slicers';
import { getGifPocketList, updateGifShortcut } from 'services/providers/pandlr.provider';
import { Edit, Close } from '@mui/icons-material';
import * as S from './styles';

export default function UpdateGifShortcutModal({
  gif,
}) {
  const [updatedShortcut, setUpdatedShortcut] = useState(gif.shortcut);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const dispatch = useDispatch();

  const handleUpdateGifShortcut = async () => {
    const result = await updateGifShortcut({ gifId: gif.id, shortcut: updatedShortcut });
    if (result.success) {
      const fetchList = await getGifPocketList();
      handleClose();
      if (fetchList.success) {
        dispatch(setGifPocket(fetchList.data));
      }
      return dispatch(setSnackbar({
        status: 'success',
        message: 'Atalho atualizado com sucesso!',
        open: true,
      }));
    }
    return dispatch(setSnackbar({
      status: 'error',
      message: 'Erro ao atualizar atalho!',
      open: true,
    }));
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        variant="contained"
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        <Edit sx={{ backgroundColor: 'green' }} />
      </IconButton>
      <S.Modal
        open={open}
        handleClose={handleClose}
        modalName={S.MODAL_STYLES.UPDATE_GIF_SHORTCUT_MODAL}
        title="Atualizar atalho"
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        >
          <Close />
        </IconButton>
        <Box sx={{ mb: 5 }}>
          <TextField
            id="shortcut"
            label="Atalho"
            variant="outlined"
            value={updatedShortcut}
            onChange={(event) => setUpdatedShortcut(event.target.value)}
            sx={{ width: '100%' }}
          />
        </Box>
        <Button
          variant="contained"
          onClick={() => handleUpdateGifShortcut()}
          sx={{ width: '100%' }}
        >
          Atualizar
        </Button>
      </S.Modal>
    </>
  );
}
