/* eslint-disable no-underscore-dangle */
import { useState } from 'react';
import { providers } from 'services/firebase';
import { AuthProvider } from 'contexts';
import { useAuth } from 'hooks';
import * as S from './styles';
import RegisterModal from './RegisterModal';
import { PhoneVerificationModal } from './PhoneVerificationModal';

function Login({ text = 'Login', description = 'Login' }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { loginPopup } = useAuth();

  return (
    <div id="login-pandlr">
      <S.Button handleOpen={handleOpen}>
        {text}
      </S.Button>
      <S.Modal
        open={open}
        handleClose={handleClose}
        modalName={S.MODAL_STYLES.LOGIN_MODAL}
        title={description}
      >
        <S.GoogleButton
          onClick={() => loginPopup(providers.google)}
        >
          Entrar com Google
        </S.GoogleButton>

        <S.TwitterButton
          onClick={() => loginPopup(providers.twitter)}
        >
          Entrar com Twitter
        </S.TwitterButton>

      </S.Modal>
      <RegisterModal />
      <PhoneVerificationModal />
      <div id="verifier" />
    </div>
  );
}

export function LoginModal({ text, description }) {
  return (
    <AuthProvider>
      <Login
        text={text}
        description={description}
      />
    </AuthProvider>
  );
}
