import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  FirstPageButton, PrevButton, NextButton, LastPageButton,
} from 'components/Buttons/PaginationButtons';

export function TopicPagination({ onSelectPage, style, containerId }) {
  const { totalPages } = useSelector((state) => state.topicPagination);
  const { pageNumber } = useParams();

  const weOnlyHaveOnePage = totalPages === 1 || !totalPages;
  const isFirstPage = pageNumber === '1' || !pageNumber;
  const isLastPage = pageNumber === 'last' || Number(pageNumber) === totalPages;
  const currentPage = isLastPage ? totalPages : Number(pageNumber) || 1;

  return (
    <Box
      sx={{ ...style }}
      id={containerId}
    >
      <FirstPageButton
        disabled={isFirstPage}
        onClick={() => {
          onSelectPage(1);
        }}
      />
      <PrevButton
        disabled={isFirstPage}
        onClick={() => {
          onSelectPage(Number(currentPage) - 1);
        }}
      />
      <NextButton
        disabled={weOnlyHaveOnePage || isLastPage}
        onClick={() => {
          onSelectPage(Number(currentPage) + 1);
        }}
      />
      <LastPageButton
        disabled={weOnlyHaveOnePage || isLastPage}
        onClick={() => {
          onSelectPage('last');
        }}
      />
    </Box>
  );
}
