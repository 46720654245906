import { Header, TopicPost, TopicReplies } from 'components';
import { TopicProvider } from 'contexts';
import { useSelector } from 'react-redux';
import * as S from './styles';

export function Topic() {
  const { isLogged } = useSelector((state) => state.user);

  return (
    <>
      <Header />
      <S.TopicContainer>
        <TopicProvider>
          <TopicPost />
          {isLogged
            ? <TopicReplies />
            : <S.RegisterToReply />}
        </TopicProvider>
      </S.TopicContainer>
    </>
  );
}
