import {
  Header, Footer, NotFoundMsg, NotFoundMsgNotLogged,
} from 'components';
import { useSelector } from 'react-redux';

export function NotFound() {
  const { isLogged } = useSelector((state) => state.user);
  return (
    <>
      <Header />
      {
        isLogged
          ? <NotFoundMsg />
          : <NotFoundMsgNotLogged />
      }
      <Footer />
    </>
  );
}
