import { configureStore } from '@reduxjs/toolkit';
import storeSynchronize from 'redux-localstore';
import {
  topicPaginationSlice,
  userProfileSlice,
  darkModeSlice,
  forumDetailsSlice,
  topicDetailsSlice,
  userSlice,
  gifOptionsSlice,
  quoteReplySlice,
  textEditorSlice,
  fastReplySlice,
  snackbarsSlice,
  autoScrollSlice,
  infiniteScrollSlice,
  gifPocketSlice,
  selectedImageSlice,
  addToGifPocketSlice,
  userPreferencesSlice,
  forumTopicsListSlice,
  chatSlice,
  friendsSlice,
  mentionsCounterSlice,
} from './slicers';

export const store = configureStore({
  reducer: {
    darkMode: darkModeSlice.reducer,
    forumDetails: forumDetailsSlice.reducer,
    forumTopicsList: forumTopicsListSlice.reducer,
    topicDetails: topicDetailsSlice.reducer,
    user: userSlice.reducer,
    gifOptions: gifOptionsSlice.reducer,
    quoteReply: quoteReplySlice.reducer,
    topicPagination: topicPaginationSlice.reducer,
    textEditor: textEditorSlice.reducer,
    fastReply: fastReplySlice.reducer,
    snackbars: snackbarsSlice.reducer,
    autoScroll: autoScrollSlice.reducer,
    infiniteScroll: infiniteScrollSlice.reducer,
    userProfile: userProfileSlice.reducer,
    gifPocket: gifPocketSlice.reducer,
    selectedImage: selectedImageSlice.reducer,
    addToGifPocket: addToGifPocketSlice.reducer,
    userPreferences: userPreferencesSlice.reducer,
    chat: chatSlice.reducer,
    friends: friendsSlice.reducer,
    mentionsCounter: mentionsCounterSlice.reducer,
  },
});

storeSynchronize(store, {
  whitelist: ['forumDetails', 'darkMode', 'user', 'autoScroll', 'infiniteScroll', 'fastReply', 'userPreferences', 'chat'],
  blacklist: ['forumTopicsList', 'topicDetails', 'gifOptions', 'quoteReply', 'topicPagination', 'textEditor', 'snackbars', 'gifPocket', 'selectedImage', 'addToGifPocket', 'friends', 'mentionsCounter'],
});
