import { Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addFriend, removeFriend, setSnackbar, setUserProfile,
} from 'redux/slicers';
import { followUser, unfollowUser } from 'services/providers/pandlr.provider';
import { io } from 'socket.io-client';
import { serializeChat } from 'utils/chat';

export function FollowUserBtn({ children, ...props }) {
  const { user: profile } = useSelector((state) => state.userProfile);
  const [socket, setSocket] = useState(null);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const follow = async () => {
    const res = await followUser(profile.id);
    if (res.success) {
      dispatch(setUserProfile({ ...profile, isFollowing: true }));
      if (res.data.isMutual) {
        dispatch(addFriend({
          ...res.data,
          chat: serializeChat(res.data.chat, user),
        }));
      }

      if (socket && res.data.isMutual) {
        socket.emit('new-friend', {
          ...res.data,
          friendId: user.id,
        });
      }
    }
    dispatch(setSnackbar({
      message: res.success ? 'Usuário seguido com sucesso!' : 'Erro ao seguir usuário.',
      status: res.success ? 'success' : 'error',
    }));
  };

  const unFollow = async () => {
    const res = await unfollowUser(profile.id);
    if (res.success) {
      if (socket) {
        socket.emit('remove-friend', {
          id: profile.id,
          friendId: user.id,
        });
      }
      dispatch(setUserProfile({ ...profile, isFollowing: false }));
      dispatch(removeFriend(profile.id));
    }
    dispatch(setSnackbar({ message: res.data.message, status: res.success ? 'success' : 'error' }));
  };

  useEffect(() => {
    let newSocket = null;
    if (user) {
      newSocket = io(process.env.REACT_APP_API_URL, {
        transports: ['websocket'],
      });

      newSocket.on('connect', () => {
        newSocket.emit('join-chat-list', { room: `${user.id}` });
      });
    }
    setSocket(newSocket);
    return () => {
      if (newSocket) {
        newSocket.emit('leave', { room: `chat-list-${user.id}` });
        newSocket.disconnect();
      }
    };
  }, [user]);

  return (
    <Typography
      variant="body1"
      component="span"
      display="block"
      sx={{
        mt: -5,
        float: 'right',
        mr: { xs: -2.7, sm: -0.8 },
      }}
    >
      <Button
        variant="outlined"
        color="background"
        size="small"
        fontSize={{ xs: '1rem', sm: '1.2rem', md: '1.4rem' }}
        sx={{
          fontWeight: 'bold',
          border: '1.7px solid',
          mt: 0,
        }}
        onClick={() => (profile?.isFollowing ? unFollow() : follow())}
        {...props}
      >
        {profile?.isFollowing ? 'Deixar de seguir' : 'Seguir'}
      </Button>
    </Typography>
  );
}
