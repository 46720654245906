import * as yup from 'yup';

export const validateSignUpForm = yup.object({
  name: yup
    .string('Enter your name')
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please specify your name'),
  username: yup
    .string('Enter your username')
    .trim()
    .min(2, 'Please enter a valid username')
    .max(50, 'Please enter a valid username')
    .required('Please specify your username'),
  email: yup
    .string('Enter your email')
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  password: yup
    .string()
    .required('Please specify your password')
    .min(6, 'The password should have at minimum length of 6 characters'),
  confirmPassword: yup
    .string()
    .required('Please confirm your password')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  phoneNumber: yup
    .string('Enter your phone number')
    .trim()
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number')
    .required('Please specify your phone number'),
});

const linkRegex = /https?:\/\/.+/;
export const validateCreateForumForm = yup.object({
  name: yup
    .string()
    .min(3, 'O nome do fórum deve ter pelo menos 2 caracteres')
    .max(20, 'O nome do fórum deve ter no máximo 20 caracteres')
    .required('O nome do fórum é obrigatório'),
  description: yup
    .string()
    .min(2, 'A descrição do fórum deve ter pelo menos 2 caracteres')
    .max(200, 'A descrição do fórum deve ter no máximo 200 caracteres')
    .required('A descrição do fórum é obrigatória'),
  avatar: yup.mixed().required('O avatar do fórum é obrigatório'),
  background: yup.mixed().required('O banner do fórum é obrigatório'),
  avatarLink: yup.string().matches(linkRegex, 'Link inválido').nullable(),
  needsApproval: yup.boolean(),
});

export const validateUpdateForumForm = yup.object({
  description: yup
    .string()
    .min(2, 'A descrição do fórum deve ter pelo menos 2 caracteres')
    .max(200, 'A descrição do fórum deve ter no máximo 200 caracteres')
    .nullable(),
  avatar: yup.mixed().nullable(),
  background: yup.mixed().nullable(),
  avatarLink: yup.string().matches(linkRegex, 'Link inválido').nullable(),
  needsApproval: yup.boolean().nullable(),
});

export const validateUpdateUserForm = yup.object({
  name: yup
    .string('Digite seu nome')
    .min(3, 'O nome deve ter pelo menos 3 caracteres')
    .max(20, 'O nome deve ter no máximo 20 caracteres')
    .nullable(),
  avatar: yup.mixed().nullable(),
  background: yup.mixed().nullable(),
});

export const validateActivePendingUserForm = yup.object({
  name: yup
    .string('Digite seu nome')
    .min(3, 'O nome deve ter pelo menos 3 caracteres')
    .max(20, 'O nome deve ter no máximo 20 caracteres'),
  username: yup
    .string('Digite seu nome de usuário')
    .min(3, 'O nome de usuário deve ter pelo menos 3 caracteres')
    .max(20, 'O nome de usuário deve ter no máximo 20 caracteres'),
  avatar: yup.mixed(),
  background: yup.mixed(),
});

export const validateLoginForm = yup.object({
  email: yup
    .string('Enter your email')
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  password: yup
    .string()
    .required('Please specify your password')
    .min(6, 'The password should have at minimum length of 6 characters'),
});

export const ValidateAddGifForm = yup.object({
  imageUrl: yup.string()
    .matches(/(https?:\/\/.*\.(?:png|jpg|jpeg|gif))/i, 'URL inválida')
    .required('Campo obrigatório'),
  shortcut: yup.string()
    .min(3, 'Mínimo de 3 caracteres')
    .max(20, 'Máximo de 20 caracteres')
    .required('Campo obrigatório'),
});

export const validateCreateGroupChat = yup.object().shape({
  chatName: yup.string()
    .max(20, 'O nome do chat deve ter no máximo 20 caracteres')
    .required('Campo obrigatório'),
  chatParticipants: yup.array().min(2, 'Selecione pelo menos 2 participantes'),
  chatImage: yup.mixed().required('Adicione uma imagem para o chat'),
});

export const validateUpdateGroupChat = yup.object().shape({
  chatName: yup.string()
    .max(20, 'O nome do chat deve ter no máximo 20 caracteres'),
  chatImage: yup.mixed(),
});

export const validateCreateGroupCategory = yup.object().shape({
  name: yup.string()
    .min(2, 'O nome da categoria deve ter pelo menos 3 caracteres')
    .max(20, 'O nome da categoria deve ter no máximo 20 caracteres')
    .required('Campo obrigatório'),
  description: yup.string()
    .min(3, 'A descrição da categoria deve ter pelo menos 3 caracteres')
    .max(200, 'A descrição da categoria deve ter no máximo 200 caracteres')
    .required('Campo obrigatório'),
  color: yup.string()
    .required('Campo obrigatório'),
});
