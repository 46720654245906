import { AccountBox, Close, Image } from '@mui/icons-material';
import {
  Box, IconButton, Modal, Skeleton, Typography,
} from '@mui/material';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { useState } from 'react';

export function MainHeaderContainer({ children }) {
  return (
    <Box
      bgcolor="background.level2"
      borderRadius="5px"
      boxShadow={(theme) => theme.shadows[1]}
      height="100%"
      sx={{
        mt: { xs: '1rem', md: '-2rem' },
      }}
    >
      {children}
    </Box>
  );
}

export function MainHeaderDetailsContainer({ children }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight={{ xs: '100px', sm: '150px', md: '170px' }}
      maxHeight="600px"
      gap={1}
      pr={{ xs: '2rem', sm: '1rem' }}
      pb={1}
      mt={{ xs: '0.5rem' }}
      ml={{ xs: '1rem', sm: 'calc(2% + 150px + 1rem)', md: 'calc(2% + 200px + 1.25rem)' }}
    >
      {children}
    </Box>
  );
}

export function MainHeaderDetailsContent({ children, ...props }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        mb: '1rem',
      }}
      {...props}
    >
      {children}
    </Box>
  );
}

export function MainHeaderName({ children, ...props }) {
  return (
    <Box
      flexDirection="column"
      gap={{ xs: '0.5rem', sm: '0.8rem', md: '1rem' }}
      position="absolute"
      bottom={{ xs: 'calc(-2%)' }}
      left={{ xs: 'calc(2% + 100px + 1rem)', sm: 'calc(2% + 150px + 1rem)', md: 'calc(2% + 200px + 1rem)' }}
      display={{ xs: 'none', sm: 'flex' }}
    >
      <Typography
        variant="h3"
        component="h1"
        fontSize={{ xs: '1.8rem', sm: '2.4rem', md: '3.2rem' }}
        fontFamily={(theme) => theme.typography.fontFamily}
        sx={{
          textShadow: '1px 1px 4px #000',
          color: '#ecf0f1',
        }}
        {...props}
      >
        {children}
      </Typography>
    </Box>
  );
}

export function MainHeaderNameMobile({ children, ...props }) {
  return (
    <Box
      component="span"
      sx={{
        display: { xs: 'flex', sm: 'none' },
        gap: '0.5rem',
        fontWeight: 'bold',
      }}
      {...props}
    >
      {children}
    </Box>
  );
}

export function MainHeaderUserNameMobile({ children }) {
  return (
    <MainHeaderNameMobile
      fontSize={{ xs: '1.2rem', sm: '2rem', md: '2.5rem' }}
    >
      {children}
    </MainHeaderNameMobile>
  );
}

export function Description({ description }) {
  const cleanHtml = DOMPurify.sanitize(description);

  const parsedHtml = parse(cleanHtml.replace(/\n/g, '<br />'));

  return (
    <Typography
      variant="body1"
      fontSize={{ xs: '1rem' }}
      component="div"
      maxHeight="500px"
      overflow="hidden"
    >
      {parsedHtml}
    </Typography>
  );
}

export function MembersCountMobile({ membersCount = 0 }) {
  return (
    <Box>
      ■
      {' '}
      {`${membersCount.toLocaleString()} ${membersCount !== 1 ? 'membros' : 'membro'}`}
    </Box>
  );
}

export function MembersCount({ membersCount = 0 }) {
  return (
    <Box
      component="span"
      sx={{
        display: { xs: 'none', sm: 'inline' },
        fontWeight: 'bold',
      }}
    >
      {`${membersCount.toLocaleString()} ${membersCount !== 1 ? 'membros' : 'membro'}`}
    </Box>
  );
}

export function MainHeaderUsername({ children }) {
  return (
    <Typography
      variant="h4"
      component="h2"
      fontSize={{ xs: '1rem', sm: '1.4rem', md: '1.6rem' }}
      fontFamily={(theme) => theme.typography.fontFamily}
      sx={{
        color: (theme) => theme.palette.text.secondary,
      }}
    >
      {children}
    </Typography>
  );
}

export function MainHeaderImagesContainer({ children }) {
  return (
    <Box
      position="relative"
      width="100%"
      height={{ xs: '200px', sm: '250px', md: '300px' }}
    >
      {children}
    </Box>
  );
}

export function AvatarContainer({ children, ...rest }) {
  return (
    <Box
      sx={{
        width: { xs: '100px', sm: '150px', md: '200px' },
        height: { xs: '100px', sm: '150px', md: '200px' },
        position: 'absolute',
        top: { xs: '49%', sm: '87%', md: '85%' },
        left: '2.5%',
        overflow: 'hidden',
        borderRadius: '5px',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: (theme) => theme.palette.background.level2,
        bgcolor: (theme) => theme.palette.background.level2,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}

export function AvatarPlaceholder({ avatarType = 'user' }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        border: '1px solid',
        borderColor: (theme) => theme.palette.divider,
      }}
    >
      {avatarType === 'user' ? (
        <AccountBox sx={{
          width: { xs: '50%', sm: '40%', md: '30%' },
          height: { xs: '50%', sm: '40%', md: '30%' },
          color: (theme) => theme.palette.text.secondary,
        }}
        />
      ) : (
        <Image sx={{
          width: { xs: '50%', sm: '40%', md: '30%' },
          height: { xs: '50%', sm: '40%', md: '30%' },
          color: (theme) => theme.palette.text.secondary,
        }}
        />
      )}
    </Box>
  );
}

export function ImageModal({
  src, open, onClose, ...rest
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      {...rest}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          p: 2.2,
          borderRadius: '5px',
          width: '300px',
          height: '300px',
          '&:focus': {
            outline: 'none',
          },
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: -10,
            right: -10,
          }}
        >
          <Close />
        </IconButton>
        <img
          src={src}
          alt="Imagem"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            borderRadius: '5px',
          }}
        />
      </Box>
    </Modal>
  );
}

export function BannerImagePlaceholder() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      }}
    >
      <Image sx={{
        width: { xs: '50%', sm: '40%', md: '30%' },
        height: { xs: '50%', sm: '40%', md: '30%' },
        color: (theme) => theme.palette.text.secondary,
      }}
      />
    </Box>
  );
}

export function UserAvatar({
  src, alt, avatarType, ...props
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    src ? (
      <>
        <Box
          onClick={() => {
            if (avatarType === 'forum') return;
            handleOpen();
          }}
          sx={{ width: '100%', height: '100%', cursor: 'pointer' }}
        >
          <img
            src={src}
            alt={alt || 'Avatar'}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            {...props}
          />
        </Box>

        <ImageModal
          open={open}
          onClose={handleClose}
          src={src}
        />
      </>

    ) : <AvatarPlaceholder avatarType={avatarType} />
  );
}

export function ForumImage({ src }) {
  return (
    <UserAvatar
      src={src}
      cursor="pointer"
      avatarType="forum"
      alt="Imagem do forum"
    />
  );
}

export function BannerImage({ src }) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: (theme) => theme.palette.divider,
        borderRadius: '5px 5px 0 0',
        overflow: 'hidden',
      }}
    >
      {src ? (
        <img
          src={src}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          alt="Banner"
        />
      ) : <BannerImagePlaceholder />}
    </Box>
  );
}

export function MainHeaderSkeleton() {
  return (
    <Box
      bgcolor="background.level2"
      borderRadius="5px"
      boxShadow={(theme) => theme.shadows[1]}
      sx={{
        mt: { xs: '1rem', md: '-2rem' },
      }}
    >
      <MainHeaderImagesContainer>
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          animation="wave"
          sx={{
            borderRadius: '5px',
            borderWidth: { xs: '1px', md: '2px' },
            borderStyle: 'solid',
            borderColor: (theme) => theme.palette.divider,
          }}
        />
        <AvatarContainer>
          <Skeleton
            variant="square"
            width="100%"
            height="100%"
            animation="wave"
          />
        </AvatarContainer>
      </MainHeaderImagesContainer>
      <MainHeaderDetailsContainer>
        <Skeleton
          width="50%"
          variant="rectangular"
          animation="wave"
        />
        <Skeleton
          width="35%"
          variant="rectangular"
          animation="wave"
        />
      </MainHeaderDetailsContainer>
    </Box>
  );
}
