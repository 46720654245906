import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState({ image: '' })('gifOptions');

export const gifOptionsSlice = createSlice({
  name: 'gifOptions',
  initialState,
  reducers: {
    setGifOptions: (state, { payload }) => {
      state = payload;
      return state;
    },
  },
});

export const { setGifOptions } = gifOptionsSlice.actions;
