import { Search } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

export function ForumBansListFilterContainer({ children }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 2,
        px: { xs: 1, sm: 2 },
        py: 1,
        mt: '1rem',
        bgcolor: 'background.level2',
        borderRadius: 1,
        overflow: 'hidden',
        boxShadow: (theme) => theme.shadows[1],
      }}
    >
      {children}
    </Box>
  );
}

export function ForumBansFilterForm({ children, ...props }) {
  return (
    <form
      style={{
        flexGrow: 1,
        position: 'relative',
        display: 'flex',
      }}
      {...props}
    >
      {children}
    </form>
  );
}

export function ForumBansSearchButton() {
  return (
    <IconButton
      sx={{
        position: 'absolute',
        right: '0',
        top: 'calc(50% - 20px)',
        width: '40px',
        height: '40px',
      }}
      type="submit"
    >
      <Search />
    </IconButton>
  );
}

export function ForumBansListContainer({ children }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      mt="1rem"
      bgcolor="background.level2"
      borderRadius={1}
      overflow="hidden"
      boxShadow={(theme) => theme.shadows[1]}
    >
      {children}
    </Box>
  );
}

export function ForumBansHelperText({ helperText }) {
  return (
    <Typography
      variant="body1"
      color="text.primary"
      component="span"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {helperText || 'Nenhum banimento encontrado'}
    </Typography>
  );
}
