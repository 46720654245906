import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState({
  currentPage: 1,
  hasMore: true,
  totalPages: 1,
  totalItems: 0,
})('topicPagination');

export const topicPaginationSlice = createSlice({
  name: 'topicPagination',
  initialState,
  reducers: {
    setTopicPagination: (state, { payload }) => {
      state.currentPage = payload.currentPage;
      state.hasMore = payload.hasMore;
      state.totalPages = payload.totalPages || state.totalPages;
      state.totalItems = payload.totalItems || state.totalItems;
    },
    resetTopicPagination: () => initialState,
  },
});

export const { setTopicPagination, resetTopicPagination } = topicPaginationSlice.actions;
