import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

export default function TooltipIconButton({ title, icon, ...props }) {
  return (
    <Tooltip title={title} arrow>
      <IconButton aria-label="title" {...props}>
        {icon}
      </IconButton>
    </Tooltip>
  );
}
