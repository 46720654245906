import React from 'react';
import { useFormik } from 'formik';

import Link from '@mui/material/Link';
import { validateSignUpForm } from 'utils/yupValidations';
import { createNewAccount } from 'services/providers/pandlr.provider';
import * as S from './styles';

export function SignUpForm() {
  const INITIAL_STATE = {
    name: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
  };

  const onSubmit = async (data) => {
    await createNewAccount(data);
  };

  const formik = useFormik({
    initialValues: INITIAL_STATE,
    validationSchema: validateSignUpForm,
    onSubmit,
  });

  return (
    <S.Form onSubmit={formik.handleSubmit}>
      <S.FormTitle> Welcome to the Sign Up Form!</S.FormTitle>
      <S.FormSubtitle>Fill out the form to get started.</S.FormSubtitle>
      <S.FormContainer>
        <S.InputGrid>
          <S.LabelText>Enter your name</S.LabelText>
          <S.TextField
            label="Name *"
            name="name"
            formik={formik}
          />
        </S.InputGrid>

        <S.InputGrid>
          <S.LabelText>Enter your username</S.LabelText>
          <S.TextField
            label="Username *"
            name="username"
            formik={formik}
          />
        </S.InputGrid>

        <S.Input>
          <S.LabelText>Enter your email</S.LabelText>
          <S.TextField
            label="Email *"
            name="email"
            formik={formik}
          />
        </S.Input>

        <S.InputGrid>
          <S.LabelText>Enter your password</S.LabelText>
          <S.TextField
            label="Password *"
            name="password"
            type="password"
            formik={formik}
          />
        </S.InputGrid>

        <S.InputGrid>
          <S.LabelText>Confirm your password</S.LabelText>
          <S.TextField
            label="Confirm your password *"
            name="confirmPassword"
            type="password"
            formik={formik}
          />
        </S.InputGrid>

        <S.Input>
          <S.LabelText>Enter your phone number</S.LabelText>
          <S.TextField
            label="Phone Number *"
            name="phoneNumber"
            formik={formik}
          />
        </S.Input>

        <S.FormActions>
          <S.SimpleText variant="subtitle2">
            Already have an account?
            {' '}
            <S.Link
              to="/login"
            >
              Login.
            </S.Link>
          </S.SimpleText>

          <S.LoadingButton type="submit" loading={false}>
            Sign Up
          </S.LoadingButton>
        </S.FormActions>

        <S.Agreement>
          By clicking Sign up button you agree with our
          {' '}
          <S.Link component={Link} to="/privacy-policy">
            Privacy policy.
          </S.Link>
        </S.Agreement>

      </S.FormContainer>
    </S.Form>
  );
}
