/* eslint-disable no-return-assign */
import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setGifOptions, setQuoteReply, setEditorId, setSelectedImage,
} from 'redux/slicers';

function DarkEditorFixed({ editorConfig }) {
  return (
    <Editor
      {...{
        ...editorConfig,
        init: {
          ...editorConfig.init,
          skin: 'oxide-dark',
          content_css: 'dark',
        },
      }}
    />
  );
}

function LightEditorFixed({ editorConfig }) {
  return (
    <Editor
      {...editorConfig}
    />
  );
}

export function TextEditorFixed({ value, setValue }) {
  const dispatch = useDispatch();
  const gifPocket = useSelector((state) => state.gifPocket);
  const gifOptions = useSelector((state) => state.gifOptions);
  const quoteReply = useSelector((state) => state.quoteReply);
  const selectedImage = useSelector((state) => state.selectedImage);
  const darkMode = useSelector((state) => state.darkMode);

  const editorRef = useRef(null);

  const contentStyle = `
    .mce-content-body [data-mce-selected=inline-boundary] {
       background-color: transparent;
    }
  `;

  function transformValue(checkValue, gifPocketFiles) {
    let transformedValue = checkValue;
    gifPocketFiles.forEach((gifPocketFile) => {
      const regex = new RegExp(gifPocketFile.shortcut, 'g');
      transformedValue = transformedValue.replace(regex, `<img alt="Gif Pocket" src="${gifPocketFile.imageUrl}" />`);
    });
    return transformedValue;
  }

  const handleUpdate = (newValue) => {
    const valueWithShortcut = transformValue(newValue, gifPocket);
    setValue(valueWithShortcut);
  };

  useEffect(() => {
    if (gifOptions.image) {
      setValue(`${value} <img src="${gifOptions.image}" />`);
      dispatch(setGifOptions({ image: '' }));
    }
  }, [gifOptions.image]);

  useEffect(() => {
    if (quoteReply.content) {
      setValue(`${value} <blockquote>@${quoteReply.username} <br /> ${quoteReply.content}</blockquote> <br />`);
      dispatch(setQuoteReply({ content: '' }));
    }
  }, [quoteReply.content]);

  const onInit = (_e, editor) => {
    editorRef.current = editor;
    dispatch(setEditorId(editor.id));
  };

  useEffect(() => {
    if (selectedImage) {
      setValue(`${value} <img src="${selectedImage}" />`);
      dispatch(setSelectedImage(''));
    }
  }, [selectedImage]);

  const editorConfig = useMemo(() => ({
    onInit: (e, editor) => onInit(e, editor),
    plugins: 'code image',
    toolbar: 'image bold italic underline strikethrough alignleft aligncenter alignright alignjustify fontsizeselect forecolor backcolor removeformat undo redo | code',
    init: {
      selector: 'textarea',
      autoresize_bottom_margin: 15,
      branding: false,
      menubar: false,
      statusbar: false,
      toolbar_mode: 'sliding',
      height: 350,
      content_style: contentStyle,
    },
    initialValue: '',
    value,
    onEditorChange: handleUpdate,
  }), [value]);

  return darkMode.active
    ? <DarkEditorFixed editorConfig={editorConfig} />
    : <LightEditorFixed editorConfig={editorConfig} />;
}
