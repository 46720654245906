import { Box } from '@mui/material';
import { Container } from 'common/Container';

export function CategoryContainer({ children }) {
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      marginTop={{ xs: 7, sm: 8, md: 15 }}
    >
      <Container
        maxWidth={980}
      >
        {children}
      </Container>
    </Box>
  );
}
