/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

export function MentionsSnackBar({
  open, close, link, mention, handleReset,
}) {
  const navigate = useNavigate();
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    event?.stopPropagation();
    close();
  };

  const handleSnackbarClick = () => {
    navigate(link);
    handleReset();
    close();
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      message={(
        <span onClick={handleSnackbarClick} style={{ cursor: 'pointer' }}>
          @
          {mention}
          {' '}
          mencionou você
        </span>
)}
      action={action}
      ContentProps={{
        style: { cursor: 'pointer' },
      }}
      onClick={handleSnackbarClick}
    />
  );
}
