import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoadingProfile, setUserProfile } from 'redux/slicers';

import {
  AvatarContainer,
  BannerImage,
  MainHeaderContainer,
  MainHeaderDetailsContainer,
  MainHeaderDetailsContent,
  MainHeaderImagesContainer,
  MainHeaderName,
  MainHeaderSkeleton,
  MainHeaderUserNameMobile,
  MainHeaderUsername,
  UserAvatar,
} from 'styles/MainHeader.styles';

import { getUserByUsername } from 'services/providers/pandlr.provider';
import { FollowUserBtn } from 'components/Buttons';
import { Box } from '@mui/material';

export default function UserInfo() {
  const { username } = useParams();
  const { user, isLoading } = useSelector((state) => state.userProfile);
  const { user: currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const getUser = async () => {
    dispatch(setIsLoadingProfile(true));
    const res = await getUserByUsername(username);
    if (res.success) {
      document.title = `${res.data.name} - Pandlr`;
      dispatch(setUserProfile(res.data));
      dispatch(setIsLoadingProfile(false));
    }
  };

  useEffect(() => {
    getUser();
    return () => {
      dispatch(setUserProfile({}));
    };
  }, [username]);

  const AVATAR_URL = `${process.env.REACT_APP_IMAGE_URL}${user?.avatar}`;
  const BANNER_URL = user?.background ? `${process.env.REACT_APP_IMAGE_URL}${user?.background}` : 'https://pbs.twimg.com/profile_banners/787361839827062788/1670721542/1500x500';

  return (
    isLoading ? <MainHeaderSkeleton /> : (
      <MainHeaderContainer>
        <MainHeaderImagesContainer>
          <BannerImage src={BANNER_URL} />
          <AvatarContainer>
            <UserAvatar src={AVATAR_URL} />
          </AvatarContainer>
          <MainHeaderName>{user.name}</MainHeaderName>
        </MainHeaderImagesContainer>
        <MainHeaderDetailsContainer>
          <MainHeaderDetailsContent sx={{ flexDirection: 'column' }}>
            <MainHeaderUserNameMobile>
              {user.name}
            </MainHeaderUserNameMobile>
            <MainHeaderUsername>
              @
              {user.username}
            </MainHeaderUsername>
          </MainHeaderDetailsContent>
          <Box>
            {user?.id !== currentUser?.id && (<FollowUserBtn />)}
          </Box>
        </MainHeaderDetailsContainer>
      </MainHeaderContainer>
    )
  );
}
