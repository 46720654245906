import { useAuth } from 'hooks';
import React, { useState } from 'react';
import {
  Box, Button, TextField,
} from '@mui/material';
import * as S from './styles';

export function VerificationCodeForm() {
  const [verificationCode, setVerificationCode] = useState('');

  const {
    isLoading,
    confirmCode,
    setCodeWasSent,
  } = useAuth();

  const handleSubmitCode = (e) => {
    e.preventDefault();
    confirmCode(verificationCode);
  };

  return (
    <S.Form onSubmit={handleSubmitCode}>
      <S.FormSubtitle>
        Insira o código de verificação enviado para o seu telefone
      </S.FormSubtitle>
      <S.VerificationCodeFormContainer>
        <S.VerificationCodeFormContentContainer>
          <TextField
            value={verificationCode}
            sx={{
              flex: 1,
            }}
            label="Código de verificação"
            onChange={(e) => setVerificationCode(e.target.value)}
            required
          />
          <S.LoadingButton
            type="submit"
            loading={isLoading}
            endIcon={<Box />}
            sx={{ width: '100%', minWidth: 150 }}
          >
            Confirmar
          </S.LoadingButton>
        </S.VerificationCodeFormContentContainer>
        <S.SimpleText>
          Não recebeu o código?
          <Button
            variant="text"
            onClick={() => setCodeWasSent((prev) => !prev)}
          >
            Reenviar código
          </Button>
        </S.SimpleText>
      </S.VerificationCodeFormContainer>

    </S.Form>
  );
}
