import { IconButton } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';

export function NextButton({ onClick, disabled }) {
  return (
    <IconButton
      disabled={disabled}
      onClick={onClick}
    >
      <NavigateNextIcon />
    </IconButton>
  );
}

export function PrevButton({ onClick, disabled }) {
  return (
    <IconButton
      disabled={disabled}
      onClick={onClick}
    >
      <NavigateBeforeIcon />
    </IconButton>
  );
}

export function LastPageButton({ onClick, disabled }) {
  return (
    <IconButton
      disabled={disabled}
      onClick={onClick}
    >
      <LastPageIcon />
    </IconButton>
  );
}

export function FirstPageButton({ onClick, disabled }) {
  return (
    <IconButton
      disabled={disabled}
      onClick={onClick}
    >
      <FirstPageIcon />
    </IconButton>
  );
}
