import { Box, Typography } from '@mui/material';

export function TopicPostHeaderContainer({ children }) {
  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }}
      mt="0.5rem"
      justifyContent="space-between"
      mb="1rem"
    >
      {children}
    </Box>
  );
}

export function TopicPostTitle({ children }) {
  return (
    <Typography
      variant="h5"
      component="h6"
      color="text.primary"
      fontSize={{ xs: '1rem', sm: '1.5rem' }}
    >
      {children}
    </Typography>
  );
}

export function TopicPaginationContainer({ children }) {
  return (
    <Box
      sx={{
        minWidth: '160px',
        alignSelf: 'flex-end',
        mb: { xs: '-1rem', sm: '0' },
      }}
    >
      {children}
    </Box>
  );
}
