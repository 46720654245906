import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState('')('selectedImage');

export const selectedImageSlice = createSlice({
  name: 'selectedImage',
  initialState,
  reducers: {
    setSelectedImage: (state, { payload }) => {
      state = payload;
      return state;
    },
  },
});

export const { setSelectedImage } = selectedImageSlice.actions;
