import { Box } from '@mui/material';

export function ForumTopicsContainer({ children }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      mt={{ xs: '1rem', sm: '2rem', md: '3rem' }}
      bgcolor="background.level2"
      borderRadius="5px"
      boxShadow={(theme) => theme.shadows[1]}
    >
      {children}
    </Box>
  );
}

export function ForumTopicsHeader({ children }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        ml: { xs: 1, sm: 2 },
        mt: 1,
        mb: -1.5,
      }}
    >
      {children}
    </Box>
  );
}

export function TopicContainer({ children }) {
  return (
    <Box
      display="flex"
      borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
      gap={1}
    >
      {children}
    </Box>
  );
}
