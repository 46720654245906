import {
  useState, useRef, useEffect, useLayoutEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { getUserTopics } from 'services/providers/pandlr.provider';
import { ForumTopicsSkeleton } from 'components/Forum/ForumTopicsSkeleton';
import { ForumTopicCard } from 'components/Forum/ForumTopicCard';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import { ListContainer, ListContent } from 'components/Topic/styles';
import { Box } from '@mui/material';

export function UserTopics() {
  const [topics, setTopics] = useState([]);
  const { user } = useSelector((state) => state.userProfile);

  const paginationRef = useRef({
    currentPage: 0,
    hasMore: true,
  });

  const isLoading = useRef(false);
  const listRef = useRef(null);
  const parentOffsetRef = useRef(0);

  const virtualizer = useWindowVirtualizer({
    count: topics.length,
    estimateSize: () => 5000,
    scrollMargin: parentOffsetRef.current,
    overscan: 5,
  });

  const virtualItems = virtualizer.getVirtualItems();

  useLayoutEffect(() => {
    parentOffsetRef.current = listRef.current?.offsetTop ?? 0;
  }, [virtualItems]);

  const fetchUserTopics = async (page) => {
    if (!user.id) return;

    const res = await getUserTopics(user.id, page);

    if (res.success) {
      const { items, ...paginationOptions } = res.data;
      paginationRef.current = paginationOptions;
      setTopics((prev) => [...prev, ...items]);
    }
  };

  const fetchNextPage = async () => {
    const [lastItem] = [...virtualizer.getVirtualItems()].reverse();
    if (!lastItem || isLoading.current) return;
    if (lastItem.index >= topics.length - 1
        && paginationRef.current.hasMore) {
      isLoading.current = true;
      await fetchUserTopics(paginationRef.current.currentPage + 1);
      isLoading.current = false;
    }
  };

  useEffect(() => {
    fetchNextPage();
  }, [
    isLoading.current,
    paginationRef,
    virtualItems,
    topics,
  ]);

  useEffect(() => {
    setTopics([]);
    fetchUserTopics(paginationRef.current.currentPage || 1);
    return () => {
      setTopics([]);
      paginationRef.current = {
        currentPage: 1,
        hasMore: true,
      };
    };
  }, [user.id]);

  return (
    <ListContainer
      ref={listRef}
      virtualizer={virtualizer}
    >
      <ListContent items={virtualItems} virtualizer={virtualizer}>
        {virtualItems?.map((item) => (
          <Box
            key={item.key}
            data-index={item.index}
            ref={virtualizer.measureElement}
            sx={{ '*': { listStyle: 'none' } }}
          >
            <ForumTopicCard
              topic={topics[item.index]}
              groupSlug={topics[item.index].group?.slug}
            />
          </Box>
        ))}
        {isLoading.current && (
          <Box sx={{ bgcolor: 'background.level2' }}>
            <ForumTopicsSkeleton />
          </Box>
        )}
      </ListContent>
    </ListContainer>
  );
}
