import { Header, Footer, UserMentions } from 'components';
import * as S from './styles';

export function Mentions() {
  return (
    <>
      <Header />
      <S.MentionsContainer>
        <S.MentionsTitle />
        <S.MentionsInteractionContainer>
          <UserMentions />
        </S.MentionsInteractionContainer>
      </S.MentionsContainer>
      <Footer />
    </>
  );
}
