import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState({ status: '', message: '', open: false })('snackbars');

export const snackbarsSlice = createSlice({
  name: 'snackbars',
  initialState,
  reducers: {
    setSnackbar: (state, { payload }) => {
      state = payload;
      state.open = true;
      return state;
    },
    hideSnackbar: (state) => {
      state.open = false;
      return state;
    },
  },
});

export const { setSnackbar, hideSnackbar } = snackbarsSlice.actions;
