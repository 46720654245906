import {
  useEffect, useState,
  useRef, useLayoutEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import { getTopicsByCategoryId } from 'services/providers/pandlr.provider';
import { ForumTopicsSkeleton } from 'components/Forum/ForumTopicsSkeleton';
import { ForumTopicCard } from 'components/Forum/ForumTopicCard';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import { ListContainer, ListContent } from 'components/Topic/styles';
import { Box, List } from '@mui/material';

export function CategoryTopics({ category }) {
  const [topics, setTopics] = useState([]);
  const { categoryId, groupId, slug } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const params = useParams();

  const paginationRef = useRef({
    currentPage: 0,
    hasMore: true,
  });

  const listRef = useRef(null);
  const parentOffsetRef = useRef(0);

  const virtualizer = useWindowVirtualizer({
    count: topics.length,
    estimateSize: () => 5000,
    scrollMargin: parentOffsetRef.current,
    overscan: 5,
  });

  const virtualItems = virtualizer.getVirtualItems();

  useLayoutEffect(() => {
    parentOffsetRef.current = listRef.current?.offsetTop ?? 0;
  }, [virtualItems]);

  const fetchTopics = async (page) => {
    const res = await getTopicsByCategoryId(categoryId, groupId, page);
    if (res.success) {
      const { items, ...paginationOptions } = res.data;
      paginationRef.current = paginationOptions;
      setTopics((prev) => [...prev, ...items]);
      setIsLoading(false);
    }
  };

  const fetchNextPage = async () => {
    const [lastItem] = [...virtualizer.getVirtualItems()].reverse();
    if (!lastItem || isLoading) return;
    if (lastItem.index >= topics.length - 1
      && paginationRef.current.hasMore) {
      setIsLoading(true);
      await fetchTopics(paginationRef.current.currentPage + 1);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNextPage();
  }, [
    isLoading,
    paginationRef,
    virtualItems,
    topics,
  ]);

  useEffect(() => {
    if (category) {
      setTopics([]);
      setIsLoading(true);
      fetchTopics(paginationRef.current.currentPage || 1);
    }
    return () => {
      setTopics([]);
      paginationRef.current = {
        currentPage: 1,
        hasMore: true,
      };
    };
  }, [categoryId, slug, category]);

  return (
    <ListContainer
      ref={listRef}
      virtualizer={virtualizer}
    >
      <ListContent items={virtualItems} virtualizer={virtualizer}>
        <List sx={{ width: '100%', bgcolor: 'background.level2' }}>
          {virtualItems?.map((item) => (
            <Box
              key={item.key}
              data-index={item.index}
              ref={virtualizer.measureElement}
              sx={{ '*': { listStyle: 'none' } }}
            >
              <ForumTopicCard
                topic={topics[item.index]}
                groupSlug={params.forum}
              />
            </Box>
          ))}
        </List>
        {isLoading && (
          <Box sx={{ bgcolor: 'background.level2' }}>
            <ForumTopicsSkeleton />
          </Box>
        )}
      </ListContent>
    </ListContainer>
  );
}
