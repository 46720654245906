import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import { ReplyLogCard, TopicLogCard } from 'components/Cards';
import * as S from './styles';

export function AdminLogDetailsModal({ log }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const notVisibleTypes = [
    'groups', 'users',
  ];
  return (
    <>
      {!notVisibleTypes.includes(log.targetType) && (
      <Button
        onClick={handleOpen}
      >
        Ver
      </Button>
      )}
      <S.Modal
        open={open}
        handleClose={handleClose}
        modalName="adminLogDetailsModal"
      >
        <Typography
          variant="h6"
          color="text.secondary"
        >
          {log.message}
        </Typography>
        {log.targetType === 'topicReplies'
          ? (<ReplyLogCard reply={log.target} />)
          : (<TopicLogCard topic={log.target} />)}
      </S.Modal>
    </>
  );
}
