import {
  Box, Button, Divider, Skeleton, Typography,
} from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export function ForumBanCardContainer({ children }) {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          flexWrap: 'wrap',
          gap: { xs: 1, sm: 2 },
          p: { xs: 1, sm: 2 },
        }}
      >
        {children}
      </Box>
      <Divider variant="middle" />
    </Box>
  );
}

export function ForumBanCardLabel({ children }) {
  return (
    <Typography
      variant="caption"
      color="text.secondary"
      component="span"
    >
      {children}
    </Typography>
  );
}

export function ForumBanCardValue({ children }) {
  return (
    <Typography
      variant="body1"
      color="text.primary"
      component="span"
      sx={{
        fontWeight: 'semibold',
        maxWidth: '100%',
      }}
    >
      {children}
    </Typography>
  );
}

export function ForumBanCardReason({ children }) {
  const [show, setShow] = useState(false);

  return (
    <Typography
      variant="body1"
      color="text.primary"
      component="span"
      sx={{
        cursor: 'pointer',
      }}
      onClick={() => setShow(!show)}
    >
      {show || children?.length < 60 ? children : `${children?.slice(0, 50)}...`}
    </Typography>
  );
}

export function ForumBanCardContent({ label, value, ...props }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      {...props}
    >
      <ForumBanCardLabel>
        {label}
        :
      </ForumBanCardLabel>
      {label === 'Razão' ? (
        <ForumBanCardReason>
          {value}
        </ForumBanCardReason>
      ) : (
        <ForumBanCardValue>
          {value}
        </ForumBanCardValue>
      )}
    </Box>
  );
}

export function ForumBanCardLink({ to, children }) {
  return (
    <Link
      to={to}
      style={{
        textDecoration: 'none',
        cursor: 'pointer',
        color: 'inherit',
      }}
    >
      {children}
    </Link>
  );
}

export function UnbanButton({ onClick }) {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      sx={{
        width: '90px',
        height: '35px',
        ml: { xs: 0, sm: 'auto' },
      }}
    >
      Desbanir
    </Button>
  );
}

export function ForumBanCardSkeleton() {
  return (
    <ForumBanCardContainer>
      <Skeleton variant="text" width="15%" height="40px" />
      <Skeleton variant="text" width="10%" height="40px" />
      <Skeleton variant="text" width="20%" height="40px" />
      <Skeleton variant="text" width="30%" height="40px" />
    </ForumBanCardContainer>
  );
}
