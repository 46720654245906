import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState({
  chats: {},
  isChatMenuOpen: false,
})('chat');

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChats: (state, { payload }) => {
      state.chats = payload;
    },
    newChat: (state, { payload }) => {
      state.chats = { [payload.chatId]: payload, ...state.chats };
      return state;
    },
    removeChat: (state, { payload }) => {
      delete state.chats[payload.chatId];
      return state;
    },
    updateChat: (state, { payload }) => {
      state.chats[payload.chatId] = payload;
      return state;
    },
    updateChatParticipants: (state, { payload }) => {
      state.chats[payload.chatId].participants = payload.participants;
      return state;
    },
    openChat: (state, { payload }) => {
      if (!state.chats[payload.chatId]) {
        state.chats[payload.chatId] = {
          ...payload,
          isOpen: true,
          isMinimized: false,
          unread: 0,
          lastReadMessagePositionId: 0,
        };

        return state;
      }

      state.chats[payload.chatId].isOpen = true;
      state.chats[payload.chatId].isMinimized = false;
      state.chats[payload.chatId].unread = 0;
      state.chats[payload.chatId]
        .lastReadMessagePositionId = state.chats[payload.chatId].lastMessage?.positionId || 0;
      return state;
    },
    closeChat: (state, { payload }) => {
      state.chats[payload.chatId].isOpen = false;
      state.chats[payload.chatId].isMinimized = false;
      return state;
    },
    minimizeChat: (state, { payload }) => {
      const { chat, reason } = payload;
      state.chats[chat.chatId].isMinimized = true;
      state.chats[chat.chatId].minimizationReason = reason;
      state.chats[chat.chatId].isOpen = false;
      return state;
    },
    openChatMenu: (state) => {
      state.isChatMenuOpen = true;
      return state;
    },
    closeChatMenu: (state) => {
      state.isChatMenuOpen = false;
      return state;
    },
    setIsUpdatingChat: (state, { payload }) => {
      const { chat, isUpdating } = payload;
      state.chats[chat.chatId].isUpdating = isUpdating;
      return state;
    },
    setIsAddingParticipants: (state, { payload }) => {
      const { chat, isAdding } = payload;
      state.chats[chat.chatId].isAddingParticipants = isAdding;
      return state;
    },
    setIsRemovingParticipants: (state, { payload }) => {
      const { chat, isRemoving } = payload;
      state.chats[chat.chatId].isRemovingParticipants = isRemoving;
      return state;
    },
    newMessageReceived: (state, { payload }) => {
      if (!state.chats[payload.chatId]) {
        state.chats[payload.chatId] = payload.chat;
      }
      if (!state.chats[payload.chatId].isOpen || state.chats[payload.chatId].isMinimized) {
        state.chats[payload.chatId].unread += 1;
      } else {
        state.chats[payload.chatId].lastReadMessagePositionId = payload.message.positionId;
      }
      state.chats[payload.chatId].lastMessage = payload.message;
      state.chats[payload.chatId].lastMessageId = payload.message.id;
      return state;
    },
  },
});

export const {
  setChats,
  newChat,
  removeChat,
  updateChat,
  openChat,
  closeChat,
  minimizeChat,
  openChatMenu,
  closeChatMenu,
  setIsUpdatingChat,
  setIsAddingParticipants,
  setIsRemovingParticipants,
  newMessageReceived,
  updateChatParticipants,
} = chatSlice.actions;

export const getAllChats = (state) => Object.values(state.chat.chats);

export const selectChats = (state) => getAllChats(state)
  .sort((a, b) => b.lastMessageId - a.lastMessageId);

export const selectOpenChats = (state) => getAllChats(state).filter((c) => c.isOpen);

export const selectMinimizedChats = (state) => getAllChats(state).filter((c) => c.isMinimized);
