import React from 'react';
import { Avatar } from '@mui/material';
import { updateUserInfo, uploadUserAvatar } from 'services/providers/pandlr.provider';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, setPendingUser, setSnackbar } from 'redux/slicers';
import { useNavigate } from 'react-router-dom';
import { validateActivePendingUserForm } from 'utils/yupValidations';
import { useFormik } from 'formik';
import { useUploadImages } from 'hooks/useUploadImages';
import * as S from './styles';
import { selectPendingUser } from '../../redux/slicers/user/userSlice';
import { ControlledTextField } from './ControlledFormComponents';

export function RegistrationForm() {
  const pendingUser = useSelector(selectPendingUser) || { id: 3 };
  const { uploadImages } = useUploadImages();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const INITIAL_STATE = {
    name: '',
    username: '',
    avatar: '',
  };

  const onSubmit = async (data, formik) => {
    if (!pendingUser) return;

    const images = [
      {
        name: 'avatar',
        file: data.avatar,
        cb: uploadUserAvatar,
        id: pendingUser.id,
      },
    ];

    const { uploadedImages, uploadErrors } = await uploadImages(images);

    if (uploadErrors.avatar) {
      formik.setErrors({
        avatar: uploadErrors.avatar || '',
      });
      return;
    }

    if (!uploadedImages.avatar) {
      formik.setErrors({
        avatar: 'Por favor, selecione uma imagem.',
      });
      return;
    }

    const result = await updateUserInfo({
      ...pendingUser,
      ...data,
      avatar: uploadedImages.avatar.path300,
      avatarThumb: uploadedImages.avatar.path,
      status: 'active',
    });

    if (!result.success) {
      const error = result.data.message;
      if (error.includes('username')) {
        formik.setErrors({ username: result.data.message });
        return;
      }

      dispatch(setSnackbar({ message: result.data.message, status: 'error' }));
      return;
    }

    dispatch(setUser(result.data));
    dispatch(setPendingUser(null));

    navigate('/forum/pan');
  };

  const formik = useFormik({
    initialValues: INITIAL_STATE,
    validationSchema: validateActivePendingUserForm,
    onSubmit: async (data) => onSubmit(data, formik),
  });

  const AVATAR_URL = formik.values.avatar ? URL.createObjectURL(formik.values.avatar) : '';

  return (
    <S.Form onSubmit={formik.handleSubmit}>
      <Avatar
        sx={{
          width: '100px',
          height: '100px',
          margin: '0 auto',
          marginBottom: '20px',
        }}
        src={AVATAR_URL}
      />
      <S.FormContainer>

        <S.Input>
          <S.LabelText>
            Digite seu nome
          </S.LabelText>
          <ControlledTextField
            variant="outlined"
            fullWidth
            label="Nome *"
            name="name"
            formik={formik}
          />
        </S.Input>

        <S.Input>
          <S.LabelText>
            Digite seu @ nome de usuário
          </S.LabelText>
          <ControlledTextField
            variant="outlined"
            fullWidth
            label="Username *"
            name="username"
            formik={formik}
          />
        </S.Input>

        <S.Input>
          <S.UploadImageButton
            label="Selecione uma imagem"
            name="avatar"
            formik={formik}
          />

        </S.Input>

        <S.FormActions>
          <S.LoadingButton
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
            type="submit"
            loading={formik.isSubmitting}
          >
            Continue
          </S.LoadingButton>
        </S.FormActions>

      </S.FormContainer>
    </S.Form>
  );
}
