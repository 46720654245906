import {
  useContext, useEffect, useRef, useLayoutEffect, useState,
} from 'react';
import { ReplyCard, TopicNewReply, TopicPagination } from 'components';
import { getTopicReplies } from 'services/providers/pandlr.provider';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { resetTopicPagination, setTopicPagination } from 'redux/slicers';
import { useAutoScroll } from 'hooks';
import { TopicContext } from 'contexts';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import ReplySkeleton from '../Reply/ReplySkeleton';
import * as S from './styles';

export function TopicReplies() {
  const { id, pageNumber } = useParams();
  const { replies, setReplies } = useContext(TopicContext);
  const { pathname } = useLocation();
  const isLoading = useRef(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);

  const topicPagination = useSelector((state) => state.topicPagination);
  const infiniteScroll = useSelector((state) => state.infiniteScroll);
  const dispatch = useDispatch();
  const { autoScroll } = useAutoScroll();
  const navigate = useNavigate();

  const listRef = useRef(null);
  const parentOffsetRef = useRef(0);

  const virtualizer = useWindowVirtualizer({
    count: replies.length,
    estimateSize: () => 5000,
    scrollMargin: parentOffsetRef.current,
    overscan: 15,
  });

  const virtualItems = virtualizer.getVirtualItems();

  useLayoutEffect(() => {
    parentOffsetRef.current = listRef.current?.offsetTop ?? 0;
  }, [virtualItems]);

  const handlePageChange = (page) => {
    if (!pathname.includes('/page/')) {
      return navigate(`${pathname}/page/${page}`);
    }
    return navigate(
      `${pathname.replace(`page/${pageNumber}`, `page/${page}`)}`,
    );
  };

  const fetchLastPageReplies = async () => {
    const fetchTopic = await getTopicReplies(id, 1);
    const { totalPages } = fetchTopic.data;
    const result = await getTopicReplies(id, totalPages);
    if (result.success) {
      const { items, ...paginationOptions } = result.data;

      setReplies((prevReplies) => [...prevReplies, ...items]);
      dispatch(setTopicPagination({
        ...paginationOptions,
        hasMore: items.length === 15,
      }));
    }
    setIsLoadingPage(false);
  };

  const fetchTopicReplies = async (page) => {
    if (page === 'last' && page !== 1) return fetchLastPageReplies();

    const result = await getTopicReplies(id, page);
    if (result.success) {
      const { items: newItems, ...paginationOptions } = result.data;

      setReplies((prevReplies) => [...prevReplies, ...newItems]);
      dispatch(setTopicPagination({
        ...paginationOptions,
        hasMore: newItems.length === 15,
      }));
    }
    setIsLoadingPage(false);
    return null;
  };

  const loadMoreReplies = async () => {
    const [lastItem] = [...virtualizer.getVirtualItems()].reverse();
    if (!lastItem || isLoading.current) return;
    if (lastItem.index >= replies.length - 1
      && infiniteScroll && topicPagination.hasMore) {
      isLoading.current = true;
      const fetchNextPage = topicPagination.currentPage === 'last' ? Number(topicPagination.totalPages) : Number(topicPagination.currentPage) + 1;
      await fetchTopicReplies(fetchNextPage);
      isLoading.current = false;
    }
  };

  useEffect(() => {
    dispatch(setTopicPagination({
      ...topicPagination,
      currentPage: pageNumber || 1,
      hasMore: true,
    }));
    fetchTopicReplies(pageNumber || 1);
    return () => {
      setIsLoadingPage(true);
      dispatch(resetTopicPagination());
      setReplies([]);
    };
  }, [pathname]);

  useEffect(() => {
    loadMoreReplies();
  }, [
    isLoading.current,
    topicPagination,
    infiniteScroll,
    virtualItems,
    replies,
  ]);

  return (
    <div>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        mt="1rem"
        bgcolor="background.level2"
        borderRadius={1}
        overflow="hidden"
        boxShadow={(theme) => theme.shadows[1]}
      >
        {
          isLoadingPage
            ? Array(7).fill(0).map(() => (
              <ReplySkeleton key={Math.random()} />
            ))
            : (
              <S.ListContainer
                ref={listRef}
                virtualizer={virtualizer}
              >
                <S.ListContent items={virtualItems} virtualizer={virtualizer}>
                  {virtualItems?.map((item) => (
                    <Box
                      key={item.key}
                      data-index={item.index}
                      ref={virtualizer.measureElement}
                    >
                      <ReplyCard reply={replies[item.index]} />
                    </Box>
                  ))}
                </S.ListContent>
              </S.ListContainer>
            )
        }
        {isLoading.current && topicPagination.hasMore && Array(5).fill(0).map(() => (
          <ReplySkeleton key={Math.random()} />
        ))}
      </Box>
      <Box display="flex" sx={{ mb: 1 }}>
        <TopicPagination onSelectPage={handlePageChange} />
      </Box>
      <TopicNewReply setReplies={setReplies} />
      {autoScroll}
    </div>
  );
}
