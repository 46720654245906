import { Box, Typography } from '@mui/material';
import { Container } from 'common/Container';

export function DeletedTopicsContainer({ children }) {
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginTop={10}
    >
      <Container maxWidth={980}>
        {children}
      </Container>
    </Box>
  );
}

export function Title({ children }) {
  return (
    <Typography
      variant="h4"
      component="h1"
      sx={{
        fontWeight: 700,
        marginBottom: 2,
      }}
    >
      {children}
    </Typography>
  );
}
