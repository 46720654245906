/* eslint-disable consistent-return */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import {
  closeChatMenu,
  minimizeChat,
  newChat,
  newMessageReceived,
  openChat,
  openChatMenu,
  removeChat,
  selectChats,
  selectOpenChats,
  setChats,
  updateChat,
  updateChatParticipants,
} from 'redux/slicers/chat/chatSlice';
import {
  addFriend, removeFriend, selectFriends, setFriends,
} from 'redux/slicers';
import { serializeChat } from 'utils/chat';
import {
  getUserChatList,
  getUserFriends,
} from '../../services/providers/pandlr.provider';

const ChatContext = createContext();

export function ChatProvider({ children }) {
  const { user } = useSelector((state) => state.user);
  const isChatMenuOpen = useSelector((state) => state.chat.isChatMenuOpen);
  const chats = useSelector(selectChats);
  const friends = useSelector(selectFriends);
  const openChats = useSelector(selectOpenChats);

  const [showCreateGroupChatModal, setShowCreateGroupChatModal] = useState(false);
  const [socket, setSocket] = useState(null);

  const dispatch = useDispatch();

  const chatListPaginationRef = useRef({
    currentPage: 0,
    hasMore: true,
  });

  const friendsListPaginationRef = useRef({
    currentPage: 0,
    hasMore: true,
  });

  const chatWidth = 400;
  const isMobile = window.innerWidth < 600;

  const handleOpen = () => dispatch(openChatMenu());
  const handleClose = () => dispatch(closeChatMenu());

  const handleMinimizeChat = (chat, reason) => {
    dispatch(minimizeChat({ chat, reason }));
  };

  const handleOpenChat = (chat) => {
    const chatsFitScreenCount = Math.floor(
      (window.innerWidth - 100) / chatWidth,
    );
    const chatsFitScreen = isChatMenuOpen ? chatsFitScreenCount - 1 : chatsFitScreenCount;
    const canOpenMoreChats = openChats.length + 1 <= chatsFitScreen;

    if (isMobile) {
      return dispatch(openChat(chat));
    }

    if (!canOpenMoreChats) {
      if (isChatMenuOpen) {
        dispatch(openChat(chat));
        return handleClose();
      }

      dispatch(minimizeChat({ chat: openChats[0], reason: 'screen' }));
    }

    return dispatch(openChat(chat));
  };

  const fetchChatList = async (page = 1) => {
    const res = await getUserChatList(page);
    if (res.success) {
      const { items, ...pagination } = res.data;
      chatListPaginationRef.current = {
        ...chatListPaginationRef.current,
        ...pagination,
      };

      const reduce = [...chats, ...items].reduce((acc, item) => {
        acc[item.chatId] = serializeChat(acc[item.chatId]
          ? { ...acc[item.chatId], ...item }
          : item, user);
        return acc;
      }, {});

      return dispatch(setChats(reduce));
    }
  };

  const fetchUserFriends = async (page = 1) => {
    const res = await getUserFriends(page);

    if (res.success) {
      const { items, ...pagination } = res.data;

      const friendsWithSerializedChat = [...friends, ...items].map((friend) => ({
        ...friend,
        chat: serializeChat(friend.chat, user),
      }));

      dispatch(
        setFriends(friendsWithSerializedChat),
      );

      friendsListPaginationRef.current = {
        ...friendsListPaginationRef.current,
        ...pagination,
      };
      return;
    }
  };

  useEffect(() => {
    fetchChatList();
    fetchUserFriends();
  }, [user]);

  useEffect(() => {
    let newSocket = null;
    if (user) {
      newSocket = io(process.env.REACT_APP_API_URL, {
        transports: ['websocket'],
      });

      newSocket.on('connect', () => {
        newSocket.emit('join-chat-list', { room: `${user.id}` });
      });

      newSocket.on('messageReceived', (data) => {
        dispatch(newMessageReceived({
          ...data,
          chat: serializeChat({
            ...data.chat,
            chatName: data.chat.originalChatName,
            chatImage: data.chat.originalChatImage,
          }, user),
        }));
      });

      newSocket.on('new-chat', (groupChat) => {
        dispatch(newChat(serializeChat(groupChat, user)));
      });

      newSocket.on('update-chat', (groupChat) => {
        dispatch(updateChat(serializeChat(groupChat, user)));
      });

      newSocket.on('user-leave-chat', (groupChat) => {
        dispatch(updateChatParticipants(serializeChat(groupChat, user)));
      });

      newSocket.on('add-participant', (groupChat) => {
        dispatch(updateChatParticipants(serializeChat(groupChat, user)));
      });

      newSocket.on('remove-participant', (groupChat) => {
        dispatch(updateChatParticipants(serializeChat(groupChat, user)));
      });

      newSocket.on('removed-from-chat', (groupChat) => {
        dispatch(removeChat(serializeChat(groupChat, user)));
      });

      newSocket.on('new-friend', (friend) => {
        dispatch(addFriend({
          ...friend,
          id: friend.friendId,
          chat: serializeChat(friend.chat, user),
        }));
      });

      newSocket.on('removed-friend', (friendId) => {
        dispatch(removeFriend(friendId));
      });

      setSocket(newSocket);
    }

    return () => {
      if (newSocket) {
        newSocket.emit('leave', { room: `chat-list-${user.id}` });
        newSocket.disconnect();
      }
    };
  }, [user]);

  const valueMemo = useMemo(
    () => ({
      handleOpen,
      handleClose,
      handleOpenChat,
      minimizeChat: handleMinimizeChat,
      showCreateGroupChatModal,
      setShowCreateGroupChatModal,
      chatListPaginationRef,
      fetchChatList,
      fetchUserFriends,
      friendsListPaginationRef,
      socket,
    }),
    [
      showCreateGroupChatModal,
      friends,
      openChats,
      chats,
      chatListPaginationRef.current,
      friendsListPaginationRef.current,
    ],
  );

  return (
    <ChatContext.Provider value={valueMemo}>{children}</ChatContext.Provider>
  );
}

export const useChat = () => useContext(ChatContext);
