import { useCallback, useState } from 'react';
import { paperProps } from 'utils/paperProps';
import {
  Menu, MenuItem, ListItemIcon, IconButton,
} from '@mui/material';
import {
  forumTopicDelete, closeTopic, openTopic, pinTopicByTopicId, unpinTopicByTopicId,
  forumReplyDeleteFlood, recountTopicRepliesById,
} from 'services/providers/pandlr.provider';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { Reply, MoreVert } from '@mui/icons-material';
import { setSnackbar } from 'redux/slicers';
import FlagIcon from '@mui/icons-material/Flag';
import WarningIcon from '@mui/icons-material/Warning';
import LaunchIcon from '@mui/icons-material/Launch';
import PushPinIcon from '@mui/icons-material/PushPin';
import CachedIcon from '@mui/icons-material/Cached';
import { ConfirmationModal, EditTopicModal } from 'components/Modals';
import { EditTopicMenuItem } from 'components/Modals/EditTopicModal';

export function TopicMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const forumDetails = useSelector((state) => state.forumDetails);
  const topicDetails = useSelector((state) => state.topicDetails);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isEditTopicModalOpen, setIsEditTopicModalOpen] = useState(false);

  const handleOpenEditTopicModal = () => {
    setIsEditTopicModalOpen(true);
    handleClose();
  };
  const handleCloseEditTopicModal = () => {
    setIsEditTopicModalOpen(false);
  };

  const open = Boolean(anchorEl);

  const handleForumReplyDelete = async () => {
    if (!topicDetails?.id) return null;
    handleClose();
    const result = await forumTopicDelete(
      { topicId: topicDetails.id, groupId: topicDetails.groupId },
    );
    if (result.success) return dispatch(setSnackbar({ message: 'Topic deleted', status: 'success' }));
    return dispatch(setSnackbar({ message: 'Error deleting topic', status: 'error' }));
  };

  const handleForumReplyDeleteFlood = async () => {
    const result = await forumReplyDeleteFlood(
      { groupId: topicDetails.groupId, userId: topicDetails.userId },
    );
    if (result.success) {
      return dispatch(setSnackbar({ message: result.data.message, status: 'success' }));
    } return dispatch(setSnackbar({ message: result.data.message, status: 'error' }));
  };

  const handleOpenTopic = async () => {
    if (!topicDetails?.id) return null;
    handleClose();
    const result = await openTopic(
      { topicId: topicDetails.id, groupId: topicDetails.groupId },
    );
    if (result.success) return dispatch(setSnackbar({ message: 'Tópico liberado para comentários', status: 'success' }));
    return dispatch(setSnackbar({ message: 'Erro ao liberar tópico', status: 'error' }));
  };

  const handleCloseTopic = async () => {
    if (!topicDetails?.id) return null;
    handleClose();
    const result = await closeTopic(
      { topicId: topicDetails.id, groupId: topicDetails.groupId },
    );
    if (result.success) return dispatch(setSnackbar({ message: 'Topic fechado para comentários', status: 'success' }));
    return dispatch(setSnackbar({ message: 'Error ao fechar tópico', status: 'error' }));
  };

  const handlePinTopic = async () => {
    if (!topicDetails?.id) return null;
    handleClose();
    const result = await pinTopicByTopicId(
      { topicId: topicDetails.id, groupId: topicDetails.groupId },
    );
    if (result.success) return dispatch(setSnackbar({ message: 'Tópico destacado', status: 'success' }));
    return dispatch(setSnackbar({ message: 'Erro ao destacar tópico', status: 'error' }));
  };

  const handleUnpinTopic = async () => {
    if (!topicDetails?.id) return null;
    handleClose();
    const result = await unpinTopicByTopicId(
      { topicId: topicDetails.id, groupId: topicDetails.groupId },
    );
    if (result.success) return dispatch(setSnackbar({ message: 'Destaque removido', status: 'success' }));
    return dispatch(setSnackbar({ message: 'Erro ao remover destaque do tópico', status: 'error' }));
  };

  const handleRecountTopicRepliesById = async () => {
    if (!topicDetails?.id) return null;
    handleClose();
    const result = await recountTopicRepliesById(
      { topicId: topicDetails.id, groupId: topicDetails.groupId },
    );

    if (result.success) return dispatch(setSnackbar({ message: 'Recontado com sucesso', status: 'success' }));
    return dispatch(setSnackbar({ message: 'Erro ao recontar respostas', status: 'error' }));
  };

  const ConfirmationModalDelete = useCallback(() => (
    <ConfirmationModal
      onConfirm={handleForumReplyDelete}
      title="Excluir Tópico"
      message="Tem certeza que deseja excluir este tópico?"
      confirmText="Excluir"
      ModalTrigger={({ onClick }) => (
        <MenuItem onClick={onClick}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Excluir
        </MenuItem>
      )}
    />
  ), [topicDetails]);

  const ConfirmationModalCloseTopic = useCallback(() => (
    <ConfirmationModal
      message="Tem certeza que deseja fechar este tópico?"
      onConfirm={handleCloseTopic}
      title="Fechar Tópico"
      confirmText="Fechar"
      ModalTrigger={({ onClick }) => (
        <MenuItem onClick={onClick}>
          <ListItemIcon>
            <WarningIcon />
          </ListItemIcon>
          Fechar
        </MenuItem>
      )}
    />
  ), [topicDetails]);

  const ConfirmationModalOpenTopic = useCallback(() => (
    <ConfirmationModal
      message="Tem certeza que deseja abrir este tópico?"
      onConfirm={handleOpenTopic}
      title="Abrir Tópico"
      confirmText="Abrir"
      ModalTrigger={({ onClick }) => (
        <MenuItem onClick={onClick}>
          <ListItemIcon>
            <LaunchIcon />
          </ListItemIcon>
          Abrir
        </MenuItem>
      )}
    />
  ), [topicDetails]);

  const ConfirmationModalPinTopic = useCallback(() => (
    <ConfirmationModal
      message="Tem certeza que deseja destacar este tópico?"
      onConfirm={handlePinTopic}
      title="Destacar Tópico"
      confirmText="Destacar"
      ModalTrigger={({ onClick }) => (
        <MenuItem onClick={onClick}>
          <ListItemIcon>
            <PushPinIcon />
          </ListItemIcon>
          Destacar
        </MenuItem>
      )}
    />
  ), [topicDetails]);

  const ConfirmationModalDeleteFlood = useCallback(() => (
    <ConfirmationModal
      message="Tem certeza que deseja excluir o flood deste tópico?"
      onConfirm={handleForumReplyDeleteFlood}
      title="Excluir Flood"
      confirmText="Excluir"
      ModalTrigger={({ onClick }) => (
        <MenuItem onClick={onClick}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Excluir Flood
        </MenuItem>
      )}
    />
  ), [topicDetails]);

  const ConfirmationModalUnpinTopic = useCallback(() => (
    <ConfirmationModal
      message="Tem certeza que deseja remover o destaque deste tópico?"
      onConfirm={handleUnpinTopic}
      title="Remover Destaque"
      confirmText="Remover"
      ModalTrigger={({ onClick }) => (
        <MenuItem onClick={onClick}>
          <ListItemIcon>
            <PushPinIcon />
          </ListItemIcon>
          Remover destaque
        </MenuItem>
      )}
    />
  ), [topicDetails]);

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={paperProps}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          sx={{ display: { xs: 'flex', sm: 'none' } }}
        >
          <ListItemIcon>
            <Reply sx={{
              width: 20,
            }}
            />
          </ListItemIcon>
          @
          {topicDetails.topicAuthor?.username}
        </MenuItem>
        <MenuItem
          onClick={() => {
            alert('Option not available yet');
            handleClose();
          }}
        >
          <ListItemIcon>
            <FlagIcon sx={{
              width: 20,
            }}
            />
          </ListItemIcon>
          Denunciar
        </MenuItem>
        {forumDetails.userCredentials?.isModerator
          && (
            <div>
              <EditTopicMenuItem handleOpen={handleOpenEditTopicModal} />
              <ConfirmationModalDelete />
              <ConfirmationModalCloseTopic />
              <ConfirmationModalOpenTopic />
              <ConfirmationModalPinTopic />
              <ConfirmationModalUnpinTopic />
              <ConfirmationModalDeleteFlood />
              <MenuItem onClick={handleRecountTopicRepliesById}>
                <ListItemIcon>
                  <CachedIcon />
                </ListItemIcon>
                Recontar Postagens
              </MenuItem>
            </div>
          )}
      </Menu>
      <EditTopicModal
        open={isEditTopicModalOpen}
        handleClose={handleCloseEditTopicModal}
      />
    </>
  );
}
