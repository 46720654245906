import { Box } from '@mui/material';
import {
  FirstPageButton, PrevButton, NextButton, LastPageButton,
} from 'components/Buttons/PaginationButtons';
import { useParams } from 'react-router-dom';

export function ForumTopicPagination({
  onSelectPage, style, totalPages,
}) {
  const { pageNumber = '1' } = useParams();

  const currentPage = +pageNumber;

  return (
    <Box
      sx={{ ...style }}
    >
      <FirstPageButton
        disabled={currentPage === 1}
        onClick={() => {
          onSelectPage(1);
        }}
      />
      <PrevButton
        disabled={currentPage === 1}
        onClick={() => {
          onSelectPage(currentPage - 1);
        }}
      />
      <NextButton
        disabled={currentPage === totalPages}
        onClick={() => {
          onSelectPage(currentPage + 1);
        }}
      />
      <LastPageButton
        disabled={currentPage === totalPages}
        onClick={() => {
          onSelectPage(totalPages);
        }}
      />
    </Box>
  );
}
