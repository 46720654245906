import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AOS from 'aos';
import { setDarkMode } from 'redux/slicers';
import { Router } from 'routes';
import { checkCurrentTheme } from 'utils/checkCurrentTheme';
import { getTheme } from 'themes/getTheme';
import { Container } from 'common/Container';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Snackbars, GifPocketFastAddModal } from 'components';
import 'themes/theme.css';
import useMediaQuery from '@mui/material/useMediaQuery';
// import { ChatProvider } from 'contexts';
// import { ChatMenuModal } from 'components/Chat/ChatMenuModal';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { OpenChats } from './components/Chat/OpenChats';

export function App() {
  const isDarkModeActive = useMediaQuery('(prefers-color-scheme: dark)');
  const darkMode = useSelector((state) => state.darkMode);
  // const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDarkModeActive && !darkMode.active && !darkMode.forced) {
      dispatch(setDarkMode({ active: true, forced: false }));
    }
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    AOS.init({
      once: true,
      delay: 50,
      duration: 500,
      easing: 'ease-in-out',
    });
  }, []);

  useEffect(() => {
    AOS.refresh();
  }, [darkMode.active]);

  const currentTheme = checkCurrentTheme(darkMode);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <ThemeProvider theme={getTheme(currentTheme)}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Container>
            <CssBaseline />
            <Paper elevation={0}>
              <Router />
              {/* user.isLogged && (
              <ChatProvider>
                <ChatMenuModal />
                <OpenChats />
              </ChatProvider>
              ) */}
            </Paper>
            <Snackbars />
            <GifPocketFastAddModal />
          </Container>
        </LocalizationProvider>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}
