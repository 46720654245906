import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState({ username: '', content: '' })('quoteReply');

export const quoteReplySlice = createSlice({
  name: 'quoteReply',
  initialState,
  reducers: {
    setQuoteReply: (state, { payload }) => {
      state = payload;
      return state;
    },
  },
});

export const { setQuoteReply } = quoteReplySlice.actions;
