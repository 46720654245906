import { useContext } from 'react';
import { TopicContext } from 'contexts';
import { Box, Typography } from '@mui/material';
import { Container } from 'common/Container';
import { LoginModal } from 'components';

export function TopicContainer({ children }) {
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginBottom={-100}
      marginTop={10}
    >
      <Container maxWidth={980}>
        {children}
      </Container>
    </Box>
  );
}

export function RegisterToReply() {
  const { topic } = useContext(TopicContext);
  return (
    <Box marginTop={3} marginBottom={5}>
      <Typography variant="body" color="textSecondary">
        Este tópico possui
        {' '}
        {topic.replies}
        {' '}
        respostas
      </Typography>
      <Box marginTop={1} />
      <LoginModal text="Faça login para visualizar as respostas deste tópico" />
    </Box>
  );
}
