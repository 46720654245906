import { PushPin } from '@mui/icons-material';
import {
  Avatar, Box, ListItem, ListItemAvatar, Typography,
  Link,
} from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

export function ForumTopicCardContainer({ children }) {
  return (
    <ListItem sx={{ overflow: 'hidden' }}>
      {children}
    </ListItem>
  );
}

export function TopicCardAvatar({ topic, navigate }) {
  const AVATAR_URL = `${process.env.REACT_APP_IMAGE_URL}${topic.topicAuthor.avatarThumb}`;

  return (
    <ListItemAvatar
      sx={{
        alignSelf: 'flex-start',
        mt: '3px',
        ml: { xs: -1, sm: 0 },
      }}
    >
      <Avatar
        src={AVATAR_URL}
        alt={topic.topicAuthor.username}
        variant="rounded"
        sx={{
          width: 48, height: 48, mt: 0.5, cursor: 'pointer',
        }}
        onClick={() => navigate(`/user/${topic.topicAuthor.username}`)}
      />
    </ListItemAvatar>
  );
}

export function TopicCardTitle({ title }) {
  return (
    <Typography
      sx={{ display: 'inline', fontWeight: 'bold' }}
      component="span"
      variant="body1"
      color="text.primary"
    >
      {title}
    </Typography>
  );
}

export function TopicCardHeader({ to, topic, isFeatured }) {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    if (e.ctrlKey || e.button === 2) {
      return window.open(to, '_blank');
    }
    return navigate(to, { state: { topic } });
  };

  return (
    <Link
      component={RouterLink}
      to={to}
      sx={{ textDecoration: 'none' }}
      onClick={handleClick}
      onContextMenu={handleClick}
    >
      <TopicCardTitle title={topic.title} />
      {isFeatured && (
        <PushPin
          sx={{
            width: 16,
            height: 16,
            ml: 0.5,
            color: 'secondary.main',
          }}
        />
      )}
    </Link>
  );
}

export function TopicInfoContainer({ children }) {
  return (
    <Box
      component="span"
      display="block"
      mt="8px"
    >
      {children}
    </Box>
  );
}

export function TopicPostQty({ topic }) {
  return (
    <Typography
      sx={{ display: 'inline' }}
      component="span"
      variant="body2"
      color="text.primary"
    >
      {topic.replies.toLocaleString()}
      {' posts - '}
    </Typography>
  );
}

export function TopicCardLastReplyLink({ topic, navigate, to }) {
  return (
    <>
      <Typography
        sx={{ display: 'inline', cursor: 'pointer' }}
        component="span"
        variant="body3"
        color="text.secondary"
        onClick={() => navigate(`/user/${topic.lastPostAuthor.username}`)}
      >
        Último post @
        {topic.lastPostAuthor.username}
      </Typography>
      <Typography
        sx={{ display: { xs: 'none', sm: 'inline' } }}
        component="span"
        variant="body3"
        color="text.secondary"
      >
        {' - '}
      </Typography>
      <Typography
        sx={{ display: { xs: 'block', sm: 'inline' }, cursor: 'pointer' }}
        component="span"
        variant="body3"
        color="text.secondary"
        onClick={() => navigate(to)}
      >
        {topic.lastUpdateTimeStamp}
      </Typography>
    </>
  );
}
