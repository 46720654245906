const { useState } = require('react');

export const useUploadImages = () => {
  const [uploadedImages, setUploadedImages] = useState({});

  const uploadImage = async (image) => {
    const formData = new FormData();
    formData.append('image', image.file);

    return image.cb(formData, image.id, image.slug);
  };

  const uploadImages = async (images) => {
    const imagesToUpload = images.filter(
      (image) => (
        !uploadedImages[image.name]
        || uploadedImages[image.name].file !== image.file)
        && image.file?.type,
    );

    const responses = await Promise.all(imagesToUpload.map(uploadImage));

    let errors = {};
    let successes = {};

    responses.forEach((response, index) => {
      if (response.success) {
        successes = {
          ...successes,
          [imagesToUpload[index].name]: response.data,
        };
        setUploadedImages((prev) => ({
          ...prev,
          [imagesToUpload[index].name]: response.data,
        }));
      } else {
        errors = {
          ...errors,
          [imagesToUpload[index].name]: response.data?.message,
        };
      }
    });

    return {
      uploadErrors: errors,
      uploadedImages: imagesToUpload.length ? successes : uploadedImages,
    };
  };

  return {
    uploadImages,
  };
};
