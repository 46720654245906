export function calculateTimeDifference(lastReplyDate) {
  const currentDate = new Date();
  const lastReply = new Date(lastReplyDate);
  const difference = currentDate.getTime() - lastReply.getTime();
  const seconds = Math.floor(difference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (seconds < 60) {
    return 'segundos atrás';
  }
  if (minutes < 60) {
    return `${minutes} ${(minutes) > 1 ? 'minutos' : 'minuto'} atrás`;
  }
  if (hours < 24) {
    return `${hours} ${hours > 1 ? 'horas' : 'hora'} atrás`;
  }
  if (days < 30) {
    return `${days} ${days > 1 ? 'dias' : 'dia'} atrás`;
  }
  if (months < 12) {
    return `${months} ${months > 1 ? 'meses' : 'mês'} atrás`;
  }
  return `${years} ${years > 1 ? 'anos' : 'ano'} atrás`;
}
