import { useCallback, useState } from 'react';
import { paperProps } from 'utils/paperProps';
import {
  Menu, MenuItem, ListItemIcon, IconButton,
} from '@mui/material';
import { forumReplyDelete, forumReplyDeleteFlood } from 'services/providers/pandlr.provider';
import { useSelector, useDispatch } from 'react-redux';
import { Delete, Reply, MoreVert } from '@mui/icons-material';
import { setSnackbar } from 'redux/slicers';
import FlagIcon from '@mui/icons-material/Flag';
import { handleQuote } from 'utils/topic';
import { ConfirmationModal } from 'components/Modals';

export function ReplyMenu({ reply }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const forumDetails = useSelector((state) => state.forumDetails);
  const topicDetails = useSelector((state) => state.topicDetails);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleForumReplyDelete = async () => {
    const result = await forumReplyDelete(
      { replyId: reply.id, groupId: topicDetails.groupId, topicId: topicDetails.id },
    );
    if (result.success) {
      return dispatch(setSnackbar({ message: result.data.message, status: 'success' }));
    } return dispatch(setSnackbar({ message: result.data.message, status: 'error' }));
  };

  const handleForumReplyDeleteFlood = async () => {
    const result = await forumReplyDeleteFlood(
      { groupId: topicDetails.groupId, userId: reply.replyAuthor.id },
    );
    if (result.success) {
      return dispatch(setSnackbar({ message: result.data.message, status: 'success' }));
    } return dispatch(setSnackbar({ message: result.data.message, status: 'error' }));
  };

  const ConfirmationModalDelete = useCallback(() => (
    <ConfirmationModal
      onConfirm={handleForumReplyDelete}
      title="Excluir Resposta"
      message={`Tem certeza que deseja excluir a resposta de @${reply.replyAuthor.username}?`}
      confirmText="Excluir"
      ModalTrigger={({ onClick }) => (
        <MenuItem onClick={onClick}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          Excluir
        </MenuItem>
      )}
    />
  ), []);

  const ConfirmationModalDeleteFlood = useCallback(() => (
    <ConfirmationModal
      onConfirm={handleForumReplyDeleteFlood}
      title="Excluir Flood"
      message={`Tem certeza que deseja excluir as respostas de @${reply.replyAuthor.username}?`}
      confirmText="Excluir"
      ModalTrigger={({ onClick }) => (
        <MenuItem onClick={onClick}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          Excluir Flood
        </MenuItem>
      )}
    />
  ), []);

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="reply-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={paperProps}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          sx={{ display: { xs: 'block', sm: 'none' } }}
          onClick={() => {
            handleQuote(reply.replyAuthor.username, reply.content);
            handleClose();
          }}
        >
          <ListItemIcon>
            <Reply sx={{ width: 20 }} />
          </ListItemIcon>
          @
          {reply.replyAuthor?.username}
        </MenuItem>
        <MenuItem
          onClick={() => {
            alert('Option not available yet');
            handleClose();
          }}
        >
          <ListItemIcon>
            <FlagIcon sx={{
              width: 20,
            }}
            />
          </ListItemIcon>
          Denunciar
        </MenuItem>
        {forumDetails.userCredentials?.isModerator
          && (
            <div>
              <ConfirmationModalDelete />
              <ConfirmationModalDeleteFlood />
            </div>
          )}
      </Menu>
    </>
  );
}
