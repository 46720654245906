/* eslint-disable react/no-unstable-nested-components */
import { Typography, Button } from '@mui/material';
import { ConfirmationModal } from 'components/Modals';
import { useDispatch, useSelector } from 'react-redux';
import { setForumDetails, setSnackbar } from 'redux/slicers';
import { joinGroup, leaveGroup } from 'services/providers/pandlr.provider';

export function JoinForumButton({ forumDetails }) {
  const dispatch = useDispatch();
  const isLogged = useSelector((state) => state.user.isLogged);

  const isMember = forumDetails.userCredentials?.isAccepted;

  const openLoginModalIfNotLogged = () => {
    document.getElementById('login-pandlr').children[0].click();
    dispatch(setSnackbar({ open: true, message: 'Você precisa estar logado para participar do fórum!', status: 'error' }));
  };

  const handleJoinGroup = async () => {
    if (!isLogged) return openLoginModalIfNotLogged();
    dispatch(setForumDetails({ ...forumDetails, userCredentials: { isAccepted: true } }));
    const result = await joinGroup(forumDetails.id, forumDetails.slug);
    if (result.success) {
      return dispatch(setSnackbar({ open: true, message: 'Você agora faz parte deste fórum!' }));
    }
    dispatch(setForumDetails({ ...forumDetails, userCredentials: { isAccepted: false } }));
    return dispatch(setSnackbar({ open: true, message: result.data.message, status: 'error' }));
  };

  const handleLeaveGroup = async () => {
    if (!isLogged) return openLoginModalIfNotLogged();
    dispatch(setForumDetails({ ...forumDetails, userCredentials: { isAccepted: false } }));
    const result = await leaveGroup(forumDetails.id, forumDetails.slug);
    if (result.success) {
      return dispatch(setSnackbar({ open: true, message: 'Você saiu deste fórum.' }));
    }
    dispatch(setForumDetails({ ...forumDetails, userCredentials: { isAccepted: true } }));
    return dispatch(setSnackbar({ open: true, message: result.data.message, status: 'error' }));
  };

  return (
    <Typography
      variant="body1"
      component="span"
      display="block"
      sx={{
        mt: -5,
        float: 'right',
        mr: { xs: -2.7, sm: -0.8 },
      }}
    >
      <ConfirmationModal
        message="Você tem certeza que deseja sair deste fórum?"
        onConfirm={isMember ? handleLeaveGroup : handleJoinGroup}
        title="Sair do fórum"
        confirmText="Sair"
        ModalTrigger={({ onClick }) => (
          <Button
            variant="outlined"
            color="background"
            size="small"
            fontSize={{ xs: '1rem', sm: '1.2rem', md: '1.4rem' }}
            sx={{
              fontWeight: 'bold',
              border: '1.7px solid',
              mt: 0,
            }}
            onClick={isMember ? onClick : handleJoinGroup}
          >
            {isMember ? 'Sair' : 'Participar'}
          </Button>
        )}
      />
    </Typography>
  );
}
