import { useSelector, useDispatch } from 'react-redux';
import { setFastReply, setSnackbar } from 'redux/slicers';
import IconButton from '@mui/material/IconButton';
import SubdirectoryArrowLeftSharpIcon from '@mui/icons-material/SubdirectoryArrowLeftSharp';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { ListItemIcon, MenuItem, Tooltip } from '@mui/material';

export function FastReplyBtn({
  isMenuItem = false, onClick,
}) {
  const fastReply = useSelector((state) => state.fastReply);
  const dispatch = useDispatch();

  const handleClick = () => {
    onClick?.();
    dispatch(setFastReply(!fastReply));
    dispatch(setSnackbar({
      status: 'info',
      message: fastReply ? 'Resposta manual ativada.' : 'Resposta rápida ativada.',
    }));
  };

  return isMenuItem ? (
    <MenuItem
      sx={{ display: 'flex' }}
      onClick={handleClick}
    >
      <ListItemIcon>
        {fastReply ? <TouchAppIcon /> : <SubdirectoryArrowLeftSharpIcon />}
      </ListItemIcon>
      {fastReply ? 'Resposta manual' : 'Resposta rápida'}
    </MenuItem>
  ) : (
    <Tooltip arrow title={fastReply ? 'Resposta manual' : 'Resposta rápida'}>
      <IconButton
        color="primary"
        aria-label="fast reply"
        component="label"
        onClick={handleClick}
      >
        {fastReply ? <TouchAppIcon /> : <SubdirectoryArrowLeftSharpIcon />}
      </IconButton>
    </Tooltip>
  );
}
