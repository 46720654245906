import {
  createContext, useState, useEffect, useMemo,
} from 'react';
import io from 'socket.io-client';
import { useSelector } from 'react-redux';

export const TopicContext = createContext();

export function TopicProvider({ children }) {
  const [socket, setSocket] = useState(null);
  const [topic, setTopic] = useState('');
  const [replies, setReplies] = useState([]);
  const { user } = useSelector((state) => state.user);
  const topicPagination = useSelector((state) => state.topicPagination);

  useEffect(() => {
    const isLastPage = topicPagination.currentPage === topicPagination.totalPages;
    let newSocket = null;
    if (user && topic.id && isLastPage) {
      newSocket = io(process.env.REACT_APP_API_URL, { transports: ['websocket'] });
      newSocket.on('connect', () => {
        newSocket.emit('join', { room: topic.id });
      });
      newSocket.on('newReply', (reply) => {
        setReplies((prevReplies) => {
          const isFromTheUser = reply.replyAuthor.id === user.id;
          if (isFromTheUser) return prevReplies;
          return [...prevReplies, reply];
        });
        return null;
      });
      setSocket(newSocket);
    }

    return () => {
      if (newSocket) {
        newSocket.emit('leave', { room: topic.id });
        newSocket.disconnect();
      }
    };
  }, [topic.id, topicPagination]);

  const values = useMemo(() => ({
    socket, topic, setTopic, replies, setReplies,
  }), [socket, topic, replies]);

  return (
    <TopicContext.Provider value={values}>
      {children}
    </TopicContext.Provider>
  );
}
