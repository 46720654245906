import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState({
  friends: [],
})('friends');

export const friendsSlice = createSlice({
  name: 'friends',
  initialState,
  reducers: {
    setFriends: (state, { payload }) => {
      state.friends = payload;
    },
    addFriend: (state, { payload }) => {
      state.friends = [payload, ...state.friends];
    },
    removeFriend: (state, { payload }) => {
      state.friends = state.friends.filter((friend) => friend.id !== payload);
    },
  },
});

export const {
  setFriends,
  removeFriend,
  addFriend,
} = friendsSlice.actions;

export const selectFriends = (state) => state.friends.friends;
