import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState(false)('infiniteScroll');

export const infiniteScrollSlice = createSlice({
  name: 'infiniteScroll',
  initialState,
  reducers: {
    setInfiniteScroll: (state, { payload }) => {
      state = payload;
      return state;
    },
  },
});

export const { setInfiniteScroll } = infiniteScrollSlice.actions;
