import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState({ editorId: null, isEditorOpen: false })('textEditor');

export const textEditorSlice = createSlice({
  name: 'textEditor',
  initialState,
  reducers: {
    setEditorId: (state, action) => {
      state.editorId = action.payload;
    },
    setIsEditorOpen: (state, action) => {
      state.isEditorOpen = action.payload;
    },
  },
});

export const { setEditorId, setIsEditorOpen } = textEditorSlice.actions;
