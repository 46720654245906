import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState({
  user: {},
})('userProfile');

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    setUserProfile: (state, { payload }) => {
      state.isLogged = true;
      state.user = payload;
    },
    setIsLoadingProfile: (state, { payload }) => {
      state.isLoading = payload;
    },
  },
});

export const {
  setUserProfile,
  setIsLoadingProfile,
} = userProfileSlice.actions;
