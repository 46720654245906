import {
  IconButton, ListItemIcon, MenuItem, Tooltip,
} from '@mui/material';
import React from 'react';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

export function ScrollToFooter({ isMenuItem = false, onClick }) {
  const handleClick = () => {
    onClick?.();
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  return isMenuItem ? (
    <MenuItem
      sx={{ display: 'flex' }}
      onClick={handleClick}
    >
      <ListItemIcon>
        <KeyboardDoubleArrowDownIcon />
      </ListItemIcon>
      Ir para o rodapé
    </MenuItem>
  ) : (
    <Tooltip arrow title="Ir para o rodapé">
      <IconButton
        color="primary"
        aria-label="go to footer"
        component="label"
        onClick={handleClick}
      >
        <KeyboardDoubleArrowDownIcon />
      </IconButton>
    </Tooltip>
  );
}
