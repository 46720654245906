import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

export function Container({ children, ...rest }) {
  return (
    <Box
      width="100%"
      margin="0 auto"
      paddingX={0.8}
      {...rest}
    >
      {children}
    </Box>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
};
