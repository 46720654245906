import React from 'react';

import { useAuth } from 'hooks';
import { PhoneNumberForm, VerificationCodeForm } from 'components/Forms';
import * as S from './styles';

export function PhoneVerificationModal() {
  const { codeWasSent, openConfirmationModal } = useAuth();

  return (
    <S.Modal
      title="Criar conta"
      open={openConfirmationModal}
    >
      {codeWasSent ? (<VerificationCodeForm />) : (<PhoneNumberForm />)}
    </S.Modal>
  );
}
