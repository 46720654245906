import React from 'react';
import { formatDate } from 'utils/date';
import * as S from './styles';

export function ForumBanCard({ ban, handleUnban }) {
  return (
    <S.ForumBanCardContainer>
      <S.ForumBanCardContent
        sx={{ width: { xs: '100%', sm: '14%' } }}
        label="Usuário"
        value={(
          <S.ForumBanCardLink to={`/user/${ban.user.username}`}>
            @
            {ban.user.username}
          </S.ForumBanCardLink>
        )}
      />
      <S.ForumBanCardContent
        width="30%"
        sx={{ width: { xs: '100%', sm: '18%' } }}
        label="Razão"
        value={ban.reason}
      />
      <S.ForumBanCardContent
        sx={{ width: { xs: '100%', sm: '17%' } }}
        label="Data do ban"
        value={formatDate(ban.banDate)}
      />
      <S.ForumBanCardContent
        sx={{ width: { xs: '100%', sm: '17%' } }}
        label="Expiração"
        value={!ban.bannedIndefinitely ? formatDate(ban.banExpiration) : 'Indeterminado'}
      />
      <S.ForumBanCardContent
        sx={{ width: { xs: '100%', sm: '14%' } }}
        label="Banido por"
        value={(
          <S.ForumBanCardLink to={`/user/${ban.bannedByUser.username}`}>
            @
            {ban.bannedByUser.username}
          </S.ForumBanCardLink>
        )}
      />
      <S.UnbanButton onClick={() => handleUnban(ban)} />
    </S.ForumBanCardContainer>
  );
}
