import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState({
  counter: 0,
})('mentionsCounter');

export const mentionsCounterSlice = createSlice({
  name: 'mentionsCounter',
  initialState,
  reducers: {
    incrementMentionsCounter: (state) => {
      state.counter += 1;
    },
    resetMentionsCounter: (state) => {
      state.counter = 0;
    },
    setMentionsCounterAction: (state, { payload }) => {
      state.counter = payload;
    },
  },
});

export const { incrementMentionsCounter, resetMentionsCounter, setMentionsCounterAction } = mentionsCounterSlice.actions;
