import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { createNewReply } from 'services/providers/pandlr.provider';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar, setIsEditorOpen } from 'redux/slicers';
import { GifPocketModal } from 'components';
import { useMediaQuery } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { TopicContext } from 'contexts';
import { BackToForumBtn } from 'components/Buttons';
import { TextEditor } from 'components/TextEditor';
import * as S from './styles';

export function TopicNewReply({ setReplies }) {
  const { id: topicId } = useParams();
  const { topic, topic: { groupId } } = useContext(TopicContext);
  const darkMode = useSelector((state) => state.darkMode);
  const user = useSelector((state) => state.user);
  const textEditor = useSelector((state) => state.textEditor);
  const forumDetails = useSelector((state) => state.forumDetails);
  const topicPagination = useSelector((state) => state.topicPagination);
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const { isLogged } = user;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { isClosed } = topic;

  const isModerator = forumDetails?.userCredentials?.isModerator;

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setIsEditorOpen(false));
    const isLastPage = topicPagination.currentPage === topicPagination.totalPages;

    if (!isLogged) return null;

    const newReply = {
      content: value,
      topicId,
      forumId: groupId,
      userInfo: user.user,
    };

    const mockReply = {
      id: uuidv4(),
      replyAuthor: {
        avatarThumb: user.user.avatarThumb,
        avatar: user.user.avatar,
        username: user.user.username,
        name: user.user.name,
        id: user.user.id,
      },
      content: value,
      updatedAt: new Date().toISOString(),
    };
    setValue('');

    if (isLastPage) {
      setReplies((prev) => [...prev, mockReply]);
    }

    const response = await createNewReply(newReply);

    if (response.success) {
      return setReplies((prev) => prev
        .map((reply) => (reply.id === mockReply.id ? { ...reply, id: response.data.id } : reply)));
    }

    setReplies((prev) => prev.filter((reply) => reply.id !== mockReply.id));
    setValue(mockReply.content);
    return dispatch(setSnackbar({ status: 'error', message: response.data.message }));
  };

  const handleCloseReplyBox = () => {
    dispatch(setIsEditorOpen(true));
    setTimeout(() => {
      const tinyMCE = window.tinymce.get(textEditor.editorId);
      tinyMCE.focus();
    }, isMobile ? 1000 : 100);
  };

  return (
    isClosed && !isModerator
      ? (
        <S.ClosedTopicMessage>
          Este tópico foi fechado pela moderação
        </S.ClosedTopicMessage>
      )
      : (
        <>
          {isClosed && isModerator && (
            <S.ClosedTopicMessage>
              Apenas moderadores podem comentar neste tópico
            </S.ClosedTopicMessage>
          )}
          <S.TopicNewReplyContainer>
            {!textEditor.isEditorOpen && (
              <S.DisabledTextEditorContainer>
                <S.DisabledTextEditor onClick={handleCloseReplyBox} darkMode={darkMode} />
                <BackToForumBtn />
              </S.DisabledTextEditorContainer>
            )}

            <S.Form onSubmit={handleSubmit} textEditor={textEditor}>
              <TextEditor value={value} setValue={setValue} darkMode={darkMode} />

              <S.SendButton
                type={isLogged ? 'submit' : 'button'}
                onClick={() => {
                  if (!isLogged) {
                    document.getElementById('login-pandlr').children[0].click();
                  }
                }}
              />

              <GifPocketModal />

              <S.CloseEditorButton
                onClick={() => dispatch(setIsEditorOpen(false))}
                darkMode={darkMode}
              >
                Fechar editor
              </S.CloseEditorButton>
            </S.Form>
          </S.TopicNewReplyContainer>
        </>
      )
  );
}
