import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState({})('topicDetails');

export const topicDetailsSlice = createSlice({
  name: 'topicDetails',
  initialState,
  reducers: {
    setTopicDetails: (state, { payload }) => {
      state = payload;
      return state;
    },
  },
});

export const { setTopicDetails } = topicDetailsSlice.actions;
