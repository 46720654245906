import { useLocation } from 'react-router-dom';
import { TopicPostPreFetched } from './TopicPostPreFetched';
import { TopicPostFetch } from './TopicPostFetch';

export function TopicPost() {
  const location = useLocation();
  const isPreFetched = location?.state;
  const topic = location?.state?.topic;
  const pathname = location?.pathname;
  return (
    isPreFetched ? (
      <TopicPostPreFetched
        topicDetails={topic}
        pathname={pathname}
      />
    ) : <TopicPostFetch />
  );
}
