import { useAuth } from 'hooks';
import React, { useState } from 'react';
import { MuiTelInput } from 'mui-tel-input';
import { Box } from '@mui/material';
import * as S from './styles';

export function PhoneNumberForm() {
  const [phoneNumber, setPhoneNumber] = useState('');

  const { isLoading, sentVerificationCode } = useAuth();

  const handleSubmitPhone = (e) => {
    e.preventDefault();
    sentVerificationCode(phoneNumber);
  };

  return (
    <S.Form onSubmit={handleSubmitPhone}>
      <S.FormSubtitle>
        Cadastre o seu número de telefone para continuar
      </S.FormSubtitle>
      <S.PhoneNumberFormContainer>
        <MuiTelInput
          value={phoneNumber}
          onChange={setPhoneNumber}
          defaultCountry="BR"
          required
        />
        <S.LoadingButton
          id="sign-in-button"
          type="submit"
          loading={isLoading}
          endIcon={<Box />}
          sx={{ width: '100%', minWidth: 160 }}
        >
          Enviar código
        </S.LoadingButton>
      </S.PhoneNumberFormContainer>
    </S.Form>
  );
}
