import { Box } from '@mui/material';
import { LoginModal } from 'components';

export function NotFoundMsgNotLogged() {
  return (
    <div style={{ marginTop: '100px', height: '75vh', textAlign: 'center' }}>
      <p>Você precisa estar logado para acessar esta página</p>
      <Box marginTop={1} />
      <LoginModal text="Faça login para visualizar este post" />
    </div>
  );
}
