import React from 'react';
import { UserProfile } from 'components/UserProfile';
import { Footer, Header } from 'components';
import { UserContainer } from './styles';

export function User() {
  return (
    <>
      <Header />
      <UserContainer>
        <UserProfile />
      </UserContainer>
      <Footer />
    </>
  );
}
