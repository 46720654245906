import { Fragment } from 'react';
import {
  Skeleton, ListItem, ListItemAvatar, ListItemText, Divider,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

export function ForumTopicsSkeleton() {
  return (
    <>
      {Array.from({ length: 10 }, () => (
        <Fragment key={uuidv4()}>
          <ListItem
            sx={{
              gap: 0.5,
            }}
          >
            <ListItemAvatar>
              <Skeleton variant="rounded" width={50} height={50} />
            </ListItemAvatar>
            <ListItemText
              primary={(
                <Skeleton variant="text" width="40%" />
              )}
              secondary={(
                <Skeleton variant="text" width="40%" />
              )}
            />
          </ListItem>
          <Divider sx={{ listStyle: 'none' }} component="li" variant="middle" />
        </Fragment>
      ))}
    </>
  );
}
