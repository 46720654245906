import {
  Close, Google, Twitter,
} from '@mui/icons-material';
import {
  Modal as MuiModal,
  Backdrop,
  Fade,
  Box,
  Button as MuiButton,
  Typography,
  Fab,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import GifIcon from '@mui/icons-material/Gif';
import styled from '@emotion/styled';

const baseModalContentStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '95%', sm: 'auto',
  },
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  overflow: 'auto',
};

const modalStyles = {
  loginModal: {
    ...baseModalContentStyles,
    minWidth: {
      sm: '400px',
    },
  },
  registerModal: {
    ...baseModalContentStyles,
  },
  banUserModal: {
    ...baseModalContentStyles,
    minWidth: {
      sm: '400px',
    },
  },
  newTopicModal: {
    ...baseModalContentStyles,
    width: '95%',
    maxWidth: 800,
  },
  editTopicModal: {
    ...baseModalContentStyles,
    width: '95%',
    maxWidth: 800,
  },
  gifPocketModal: {
    ...baseModalContentStyles,
    width: '95%',
    maxHeight: '90vh',
    maxWidth: 800,
  },
  fastAddGifPocketModal: {
    ...baseModalContentStyles,
    width: '95%',
    maxWidth: 400,
  },
  UpdateGifShortcutModal: {
    ...baseModalContentStyles,
  },
  adminLogDetailsModal: {
    ...baseModalContentStyles,
    '*::-webkit-scrollbar': {
      width: '8px',
      backgroundColor: 'transparent',
    },
    '*::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '*::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '4px',
    },
    '*::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
};

export const MODAL_STYLES = {
  LOGIN_MODAL: 'loginModal',
  REGISTER_MODAL: 'registerModal',
  BAN_USER_MODAL: 'banUserModal',
  NEW_TOPIC_MODAL: 'newTopicModal',
  EDIT_TOPIC_MODAL: 'editTopicModal',
  GIF_POCKET_MODAL: 'gifPocketModal',
  FAST_ADD_GIF_POCKET_MODAL: 'fastAddGifPocketModal',
  UPDATE_GIF_SHORTCUT_MODAL: 'UpdateGifShortcutModal',
};

export function Title({ children }) {
  return (
    <Typography variant="h4" component="h1" textAlign="center" gutterBottom>
      {children}
    </Typography>
  );
}

const StyledMContainer = styled(Box)`
  ::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background: transparent;
    margin: 10px 0  ;
  } 

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-right: 3px transparent solid;
    background-clip: padding-box;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export function Modal({
  open, handleClose, children, modalName, title, ...props
}) {
  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose || null}
      disableEnforceFocus
      disableAutoFocus
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      {...props}
    >
      <Fade in={open}>
        <StyledMContainer
          sx={modalStyles[modalName] || baseModalContentStyles}
        >
          {
            handleClose && (
              <IconButton
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
              >
                <Close />
              </IconButton>
            )
          }
          <Title>{title}</Title>
          {children}
        </StyledMContainer>
      </Fade>
    </MuiModal>
  );
}

export function Button({ handleOpen, children }) {
  return (
    <MuiButton
      onClick={handleOpen}
      variant="contained"
      color="primary"
      component="button"
      size="large"
    >
      {children}
    </MuiButton>
  );
}

export function GifPocketBtn({ handleOpen, isCreatingTopic }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return (
    <Fab
      component="div"
      size="small"
      onClick={handleOpen}
      sx={{
        position: 'absolute',
        right: isCreatingTopic ? 20 : 140,
        bottom: isCreatingTopic ? isMobile && 0 : 5,
        top: isCreatingTopic && !isMobile && 85,
        color: 'text.primary',
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        },
      }}
    >
      <GifIcon fontSize="large" />
    </Fab>
  );
}

export function GoogleButton({ children, ...props }) {
  return (
    <MuiButton
      {...props}
      variant="contained"
      color="primary"
      component="button"
      size="large"
      startIcon={<Google />}
      sx={{
        justifyContent: 'flex-start',
        px: 8,
      }}
    >
      {children}
    </MuiButton>
  );
}

export function TwitterButton({ ...props }) {
  return (
    <MuiButton
      {...props}
      variant="contained"
      color="primary"
      component="button"
      size="large"
      startIcon={<Twitter />}
      sx={{
        justifyContent: 'flex-start',
        px: 8,
      }}
    >
      {props.children}
    </MuiButton>
  );
}
