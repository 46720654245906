import {
  Tooltip, Box, AvatarGroup, Avatar,
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function ForumModerators() {
  const forumDetails = useSelector((state) => state.forumDetails);

  const MODERATORS = forumDetails?.moderators;

  const MT = -5.5 - Math.ceil(Math.min(21, MODERATORS?.length) / 7) * 2.5;

  return (
    <Box
      sx={{
        maxWidth: { xs: 120, sm: 130, md: 150 },
        width: { xs: 120, sm: 130, md: 150 },
        mt: MT,
        mr: { xs: -3, sm: -1 },
        height: { xs: 20, sm: 24 },
      }}
    >
      <AvatarGroup
        sx={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
        }}
        max={21}
      >
        {MODERATORS?.slice(0, 21).map((moderator) => (
          <Tooltip title={`@${moderator.user.username}`} arrow key={`moderator-${moderator.user.id}`}>
            <Link to={`/user/${moderator.user.username}`} style={{ marginLeft: -7, marginBottom: 1 }}>
              <Avatar
                src={`${process.env.REACT_APP_IMAGE_URL}${moderator.user.avatarThumb}`}
                sx={{
                  width: { xs: 20, sm: 24 },
                  height: { xs: 20, sm: 24 },
                }}
              />
            </Link>
          </Tooltip>
        ))}
      </AvatarGroup>
    </Box>
  );
}
